/* Charger */
.charger-management-simple-view-list-container.open{
  margin-top: 780px;
}
.charger-management-simple-view-list-container{
  width: 100%;
  height: fit-content;
  padding: 0 5px;
  margin-top: 50px;
  transition: .3s;
}
.charger-management-simple-view-charger-list-header{
  display: flex;
  align-items: center;
  justify-content: space-between;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  height: 60px;
  margin-bottom: 10px;
  column-gap: 100px;
}

.charger-management-simple-view-selector-container{
  height: 45px;
}

.charger-management-simple-view-selector-container .react-select__menu{
  border-radius: 8px;
}
.charger-management-simple-view-selector-container .react-select__control{
  cursor: pointer;
}
.charger-management-simple-view-selector-container .react-select__menu .react-select__option{
  margin: 0;
  cursor: pointer;
}
.charger-management-simple-view-selector-container .react-select__menu .react-select__option:hover{
  background: var(--emoovLightBlue2);
}
.charger-management-simple-view-selector-container .react-select__menu .react-select__option--is-focused{
  background: none;
}
.charger-management-simple-view-selector-container .react-select__menu .react-select__option--is-selected:hover{
  background: var(--emoovBlue);
}
.charger-management-simple-view-selector-container .react-select__menu .react-select__option--is-selected{
  background: var(--emoovBlue);
  color: #000;
}



.charger-management-simple-view-charger-list-button-container.open{
  opacity: 0;
}
.charger-management-simple-view-charger-list-button-container{
  height: 50px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: end;
  transition: .3s;
}
.charger-management-simple-view-charger-list-button-container>div:active{
  opacity: 0.3;
}
.charger-management-simple-view-charger-list-button-container>div{
  height: 45px;
  padding: 0 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;  justify-content: center;
  background: var(--emoovBlue);
  cursor: pointer;
  transition: .3s;
  margin-left: 10px;
}
.charger-management-simple-view-charger-list-button-container>div svg{
  transform: translate(-5px, 1px);
  color: #fff;
}
.charger-management-simple-view-charger-list-button-container>div p{
  color: #fff;
  margin: 0;
  font-size: .9rem;
  font-weight: bold;
}
.charger-management-simple-view-charger-list-button-container>div.delete{
  /* background: #feeded; */
  background: #fff;
  border: 1px solid var(--buttonBorderGray2);
}
.charger-management-simple-view-charger-list-button-container>div.disabled{
  pointer-events: none;
  opacity: 0.3;
}
.charger-management-simple-view-charger-list-button-container>div.delete p{
  /* color: #ff8d8d; */
  color: var(--textGray2);

}
.charger-management-simple-view-charger-list-button-container>div.excel{
  background: #ddf8de;
}
.charger-management-simple-view-charger-list-button-container>div.excel svg{
  color: #42ab48;
}
.charger-management-simple-view-charger-list-button-container>div.excel p{
  color: #42ab48;
  margin: 0;
  font-size: .85rem;
  font-weight: bold;
}




.charger-management-simple-view-charger-list{
  width: 100%;
  min-width: 850px;
}
.charger-management-simple-view-charger-list-column{
  border-top: 2px solid var(--inputBorder);
  border-bottom: 1px solid var(--inputBorder3);
  height: 50px;
  position: relative;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 1.5fr 0.5fr 2fr 2fr 0.7fr 0.5fr;

}
.charger-management-simple-view-charger-list-column-item{
  height: 100%;
  width: max-content;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.charger-management-simple-view-charger-list-column p{
  margin: 0;
  font-size: .8rem;
  color: var(--inputBorder2);
  font-weight: 400;
}
.charger-management-simple-view-list-content{
  /* padding: 0 15px; */
  width: 100%;
  height: fit-content;
  min-height: 270px;
  max-height: 500px;
  background: #fff;
  overflow: scroll;
  scrollbar-width: none;
}
.charger-management-simple-view-list-content-item.clicked{
  background: var(--itemActiveBlue);
}
.charger-management-simple-view-list-content-item{
  width: 100%;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 1.5fr 0.5fr 2fr 2fr 0.7fr 0.5fr;
  cursor: pointer;
  transition: .13s;

}
.charger-management-simple-view-list-content-item>div{
  /* border-right: 1.5px solid var(--emoovBlue30); */
  height: 100%;
  padding: 0 10px;
  display: flex;align-items: center;
}
.charger-management-simple-view-list-content-item>div p{
  user-select:text;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow:ellipsis;
  word-break: break-all;
  margin: 0;
  font-size: .9rem;
  font-weight: 400;
  color: #555;
}
.charger-management-simple-view-status-item.loading{
  border-color: #eee;
  background: #eee;
  height: 30px;
}
.charger-management-simple-view-status-item{
  border: 2px solid var(--emoovBlue);
  border-radius: 100vh;
  width: 55px;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.charger-management-simple-view-list-content-item .charger-management-simple-view-status-item p{
  color: var(--emoovBlue);
  font-weight: bold;
}
.charger-management-simple-view-list-content-item .charger-management-simple-view-status-item.none{
  border-color: var(--textRed1);
}
.charger-management-simple-view-list-content-item .charger-management-simple-view-status-item.none p{
  color: var(--textRed1);
}

.charger-management-simple-view-connector-badge{
  display: flex;align-items: center;justify-content: center;
  width: fit-content;
  height: 30px;
  border: 2px solid var(--emoovBlue);
  border-radius: 100vh;
  font-weight: 500;
  padding: 0 10px;
  margin-right: 5px;
  background: #fff;
}
.charger-management-simple-view-list-content-item .charger-management-simple-view-connector-badge>p{
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}


