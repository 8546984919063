/************************************************************/
/** CpoFirmwareManagement  */
/************************************************************/
.cpo-firmware-management-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpo-firmware-management-inner{
  width: 100%;
  max-width: 1150px;
  min-width: 1150px;
  height: 100%;
  position: relative;

}

.cpo-firmware-management-header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 10px;
}
.cpo-firmware-management-info-msg{
  margin: 0;
}
.cpo-firmware-management-info-msg.edit{
  color: var(--emoovBlue);
}
.cpo-firmware-management-info-msg{
  color: var(--textRed1);
}
.cpo-firmware-management-header-button{
  cursor: pointer;
  width:fit-content;
  height: 45px;
  padding: 0 20px;
  color: #fff;
  background: var(--emoovBlue);
  display: flex;align-items: center;justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
  transition: .13s;
  font-weight: 600;
}
.cpo-firmware-management-header-button:active{
  opacity: 0.3;
}
.cpo-firmware-management-header-button.update{
  background: #fff;
  border: 1px solid var(--emoovBlue);
  color: var(--emoovBlue);
}
.cpo-firmware-management-header-button.delete{
  background: #fff;
  border: 1px solid var(--textRed1);
  color: var(--textRed1);
}
.cpo-firmware-management-header-button.cancel{
  background: #fff;
  border: 1px solid var(--inputBorder2);
  color: var(--inputBorder2);
}
.cpo-firmware-management-header-button.disabled{
  pointer-events: none;
  opacity: 0.3;
} 
.cpo-firmware-management-content-container{
  width: 100%;

}

.cpo-firmware-management-content-item-list-container{
  width: 100%;
  min-height: 300px;
  border-top: 2px solid var(--inputBorder2);
  margin-top: 10px;
}
.cpo-firmware-management-content-item-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 60px;
  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;

  font-size: .9rem;
  color: var(--inputBorder2);
}
.cpo-firmware-management-content-item-column.edit{
  grid-template-columns: 50px 1.5fr 1fr 1fr 1fr 60px;
}
.cpo-firmware-management-content-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 80px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
}

.cpo-firmware-management-detail-button{
  cursor: pointer;
  color: var(--inputBorder);
  width:fit-content;
  border-bottom: 1px solid var(--inputBorder);
}



/************************************************************/
/** CpoFirmwareListItem  */
/************************************************************/


.cpo-firmware-list-item-container{
  width: 100%;
  /* min-height: 300px; */
}
.cpo-firmware-list-item-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 80px;

  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  font-size: .9rem;
  color: var(--inputBorder2);
}


.cpo-firmware-list-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 60px;

  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
  cursor: pointer;
}
.cpo-firmware-list-item.edit{
  grid-template-columns: 50px 1.5fr 1fr 1fr 1fr 60px;
}
.cpo-firmware-list-item.selected{
  background: var(--emoovLightBlue2);
}
.cpo-firmware-list-item>div:last-child{
  display: flex;
  align-items: center;
  justify-content: end;
}
.cpo-firmware-list-item>div:last-child>svg{
  margin-left: 5px;
}




.cpo-firmware-list-item-content{
  background: #F7F7F7;
  visibility: hidden;
  max-height: 0;
}


.cpo-firmware-list-item-container.open .cpo-firmware-list-item-content{
  visibility: visible;
  max-height: none;
  border-bottom: 1px solid var(--inputBorder3);
}
.cpo-firmware-list-item-container.open .cpo-firmware-list-item>div>svg{
  transform: rotate(-180deg);
}

.cpo-firmware-version-list-container{
  width: 100%;
}
.cpo-firmware-version-list-item-column{
  width: calc(100% - 30px);
  height: 50px;
  display: grid;
  grid-template-columns: 30px .5fr 1.7fr 4fr auto auto;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  padding-right: 0;
  margin: 0 15px;
  column-gap: 10px;
  font-size: .9rem;
  
}
.cpo-firmware-version-list-item-column>div.version-count{
  text-align: right;
}
.cpo-firmware-version-append-button{
  cursor: pointer;
  width:fit-content;
  height: 35px;
  padding: 0 10px;
  color: #fff;
  background: var(--buttonBlack);
  display: flex;align-items: center;justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
}
.cpo-firmware-version-append-button:active{
  transition: .13s;
  opacity: 0.3;
}
.cpo-firmware-version-list-item-container{
  width: 100%;
  height: 200px;
  overflow-y: overlay;
  scrollbar-gutter: stable;
  padding-left: 15px;
}

.cpo-firmware-version-list-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 30px 0.45fr 1.55fr 4fr auto auto;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;

}
.cpo-firmware-version-list-item>div{
  display: flex;
  align-items: center;
}
.cpo-firmware-version-list-function{
  cursor: pointer;
  font-size: 1.2rem;
  transition: .13s;
  margin-left: 5px;
}
.cpo-firmware-version-list-function:active{
  opacity: 0.3;
}
.cpo-firmware-version-list-function.edit{
  color: var(--emoovBlue);
}
.cpo-firmware-version-list-function.delete{
  color: var(--textRed1);
}




/************************************************************/
/** CpoFirmwareEnrollModal  */
/************************************************************/

.cpo-firmware-enroll-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-firmware-enroll-modal-container{
  position: relative;
  /* width: 950px; */
  /* height: 330px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
}
.cpo-firmware-enroll-modal-content-container{
  min-width: 600px;
  margin-bottom: 20px;
}
.cpo-firmware-enroll-modal-content-container>p{
  font-weight: bold;
  margin-bottom: 15px;
}

.cpo-firmware-enroll-modal-input-container{
  width: 100%;
  height: 45px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder);
}
.cpo-firmware-enroll-modal-input-container textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1.5rem;
  font-size: 1rem;
}
.cpo-firmware-enroll-modal-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-firmware-enroll-modal-input-container.textarea{
  height: 90px;
  padding: 10px;
}

.cpo-firmware-enroll-modal-uploadbox-container .file-uploaded-wrapper{
  padding: 20px;
  display: block;
}
.cpo-firmware-enroll-modal-uploadbox-container .preview-container{
  padding: 20px 0;
}


.cpo-firmware-enroll-modal-button-container{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
}
.cpo-firmware-enroll-modal-button{
  cursor: pointer;
  width:fit-content;
  height: 45px;
  padding: 0 20px;
  font-weight: 600;
  color: #fff;
  background: var(--emoovBlue);
  display: flex;align-items: center;justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
  transition: .13s;
}
.cpo-firmware-enroll-modal-button:active{
  opacity: 0.3;
}
.cpo-firmware-enroll-modal-button.disable{
  pointer-events: none;
  opacity: 0.3;
}