:root {
  /* Images */
  /* --logo : url('./img/EMOOV_logo_1.png'); */
  --logo : url('./img/logo.svg');
  --logo_tran4 : url('./img/EMOOV_logo_tran_4.png');
  --check : url('./img/check-solid.svg');
  --stationMarker : url('./img/evstation.svg');
  --fastEnroll : url('./img/fast-enroll.svg');
  --unionpay : url('./img/unionpay.svg');
  --mastercard : url('./img/mastercard.svg');
  --visa : url('./img/visa.svg');
  --amex : url('./img/amex.svg');
  --jcb : url('./img/jcb.svg');
  --dinersclub : url('./img/dinersclub.svg');
  --local : url('./img/local.svg');

  --checkBlue: url('./img/check.svg');

  --coinsBlueTrans: url('./img/dashboard-coin-trans.svg');
  --coinsBlue: url('./img/dashboard-coin.svg');
  --coinsGreen: url('./img/dashboard-coin-green.svg');
  --sandClockBlue : url('./img/dashboard-sand-clock.svg');
  --sandClockGreen : url('./img/dashboard-sand-clock-green.svg');
  --rectCoin: url('./img/rect-coin.svg');
  --rectBattery: url('./img/rect-battery.svg');
  --rectOutlet: url('./img/rect-outlet.svg');


  --playStore: url('./img/playstore.svg');
  --appStore: url('./img/appstore.svg');

  /* Colors */
  --emoovBlue :#2FB4D9;
  --emoovBrightBlue :#1ac0ee;
  --emoovBlue70 :rgb(47, 180, 217, 0.7);
  --emoovBlue10 :rgb(47, 180, 217, 0.5);
  --emoovBlue1 :rgba(47, 180, 217, 0.1);
  --emoovBlue30 :rgba(47, 180, 217, 0.3);
  --emoovBlue60 :#9dd9eb;
  --emoovGray :#3D3D3D;
  --yellow : #ffd903;
  --yellow1 : #ffd903;
  --yellow60 : rgba(255, 217, 3, 0.6);
  --yellow80 : rgba(255, 217, 3, 0.8);
  --textBlue: #2FB4D9;
  --textBlue1:rgb(42, 187, 227);
  --textBlue2:rgba(1, 138, 190, 1);
  --textGray1 : #afafaf;
  /* --textGray2 : #777; */
  --navBgGray : rgb(247, 247, 247);
  --hoverGray : rgba(159, 163, 170, 0.1);
  --hoverGray2 : rgba(182, 186, 194, 0.1);
  --hoverGray3 : rgb(244, 248, 255);
  --hoverGray4 : rgb(223, 234, 252);
  --hoverBlue : rgb(34 188 211 / 0.18);
  --gray50 : rgba(113, 125, 143, 0.5);
  --buttonBlueInner : #8fe1fa;
  --buttonBlueOuter : #d7f2fa;
  --buttonBlueOuter1 :rgb(232, 250, 253);
  --buttonRed : #ffb8b8;
  --textRed : #8f4242;
  --oxfordBlue : #658188;
  --oxfordBlue1 : #a6b8bd;
  --oxfordBlue2 : #1d9abc;
  --oxfordBlue3 : #1d9abc;
  --toggleOff: #a6b8bd; 
  --toggleOn: #99ddf0;
  --fast : #2FB4D9;
  --middle : #64C8E3;
  --low : #B5B5B5;
  
  --readyBg: #fff3d1;
  --readyText: #d5b485;
  --connectedBg: #c5e2ca;
  --connectedText: #7b9d81;
  --availableBg: #b2f2bb;
  --availableText: #26b541;
  --preparingBg: #bdfff2;
  --preparingText: #4ad9c1;
  --chargingBg: #ccf5ff;
  --chargingText: #39c4ea;
  --pauseBg: #f9d0a4;
  --pauseText: #de7e17;
  --finishingBg: #96cefb;
  --finishingText: #1f97f2;
  --reservedBg: #e5dbff;
  --reservedText: #c571ec;
  --unavailableBg: #ddc9c0;
  --unavailableText: #7f726c;
  --faultedBg: #fdcece;
  --faultedText: #ff667d;



  --buttonBlack:#3d3d3d;
  --bgWhite: #f7f7f7;

  --inputBorder: #B6B6B6;
  --inputBorder2: #5f5959;
  --inputBorder3: #D5D5D5;
  
  --tabBorder: #EDEDED;



  --textGray: #909090;
  --textGray2: #5A5A5A;
  --textGray3: #959595;
  --textGray4: #111111;

  --placeholderGray: #ACACAC;
  --stepBorder:#C8C8C8;
  --emoovLightBlue: #B4E5F2;
  --emoovLightBlue2: #EFFBFF;

  --buttonBorderGray: #ACACAC;
  --buttonBorderGray2: #C9C9C9;

  --itemActiveBlue: #F2F8F8;
  --textRed: #FF295D;
  --textRed1: #FF507B;

  --textYellow: #FFB628;

  --textGreen: #61D167;

  --cardBtnBlue: #DFF7FC;
  --cardBtnRed : #FCDFDF;

  --rt-transition-show-delay: 0.15s;
  --rt-transition-closing-delay: 0.1s;
  --rt-opacity: 0.5;
}
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-dynamic-subset.min.css");
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-layouts/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";    
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import '../node_modules/@syncfusion/ej2-react-pivotview/styles/material.css';

*{
  box-sizing: border-box;
  font-family:'Pretendard';
  /* scrollbar-width: none; */

}
html{
  overflow-y: overlay;
}
body{
  scrollbar-width: thin;
  scrollbar-color: #2fb4d922 rgba(255, 255, 255, 0);
  height: 100%;
  overflow-y: overlay;
}

section.Toastify{
  padding: 0;
}


/* Tooltip */
.cell .tooltip{
  background: #ffe7e7;
  padding: 5px;
  /* border-radius: 8px; */
  cursor: pointer;
}
.cell .tooltip .tooltip-content{
  display: flex;align-items: center;
  cursor: pointer;
  font-weight: 600;
  color: var(--textRed1);
}
.cell .tooltip .tooltip-content>div{
  display: flex;align-items: center;justify-content: center;
  border-radius: 100vh;
  background: var(--textRed1);
  color: #fff;
  font-size: 1rem;
  padding: 5px;
  margin-right: 5px;
}
.cell .tooltip .tooltip-content:active{
  opacity: 0.3;
}
/* Loading */
.loading-container{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader{
  min-width: 300px;
  min-height: 300px;
  width: 20%;
  max-width: 500px;
  pointer-events: none;
}
.goToMain:active{
  opacity: 0.5;
}
.goToMain{
  /* position: absolute;
  left: 50%;
  top: 65%; */
  margin-top: 50px;
  background: #1364b0;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: bold;
  color: #fff;
  width: 100px;
  height: 50px;
  /* transform: translate(-50%, -50%); */
  display: flex;align-items: center;justify-content: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: .1s;
}
.goToMain p{
  margin: 0;
}

/* CheckBox */
/* input 초기화 */
input[type='checkbox']{
  display: none;
}
.checkbox_label{
  display: flex;
  align-items: center;
  background-size: 60% auto;
}
/* 체크박스 디자인 */
.checkbox_icon{
  display: block;
  width: 20px;
  height: 20px;
}
.checkbox_icon::before{
  content:'';
  display: block;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid var(--inputBorder);
  box-sizing:border-box;
  position: relative;
  cursor: pointer;
  /* transition: .3s; */
  background-size: 60% auto;
  text-align: center;
}

/* checked */
.checkbox_label input:checked + .checkbox_icon::before{
  background: var(--check) var(--emoovBlue) no-repeat center;
  background-size: 60% auto;
  border:none;
}




/* Toggle */
.toggle-wrapper.disabled{
  pointer-events: none;
}
.toggle-wrapper{
  width: fit-content;
  position: relative;
}
.toggle-wrapper .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 150px;
  height: 45px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.toggle-wrapper .tgl + .tgl-btn:after,
.toggle-wrapper .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.toggle-wrapper .tgl + .tgl-btn:after {
  left: 0;
}
.toggle-wrapper .tgl + .tgl-btn:before {
  display: none;
}
.toggle-wrapper .tgl.checked + .tgl-btn:after {
  left: 50%;
}

.toggle-wrapper .tgl + .tgl-btn {
  background: var(--toggleOff);
  border-radius: 100vh;
  padding: 5px;
  transition: all 0.4s ease;
  /* border: 1px solid #e8eae9; */
  box-shadow: 0px 0px 15px 0px #0000001A inset;
}
.toggle-wrapper .tgl + .tgl-btn:after {
  border-radius: 100vh;
  background: #fbfbfb;
  transition: left 0.3s cubic-bezier(0.175, 0.5, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  /* box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08); */
}
.toggle-wrapper .tgl + .tgl-btn:hover:after {
  will-change: padding;
}
.toggle-wrapper .tgl + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em var(--toggleOff);
}
.toggle-wrapper .tgl + .tgl-btn:active:after {
  padding-right: 0.8rem;
}
.toggle-wrapper .tgl.checked + .tgl-btn {
  background: var(--toggleOn);
}
.toggle-wrapper .tgl.checked + .tgl-btn:active {
  box-shadow: none;
}
.toggle-wrapper .tgl.checked + .tgl-btn:active:after {
  margin-left: -0.8rem;
}
.toggle-wrapper .toggle-content{
  position: absolute;
  display: inline-block;
  text-align: center;
  margin: 0;
  top: 50%;
  /* left: 22%; */
  width: calc(50% - 5px);
  transform: translateY(-50%) translateX(5px);
  transition: left 0.3s cubic-bezier(0.175, 0.5, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  color: var(--oxfordBlue1);
  cursor: pointer;
}
.toggle-wrapper .toggle-content.on{
  /* left: none; */
  /* right: 0; */
  transform: translateY(-50%) translateX(calc(100% + 5px));
  color: #000;
}

/* Selector */
.selector-wrapper{
  position: relative;
  width: 100%;
  height: 45px;
}
.selector-down-icon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  cursor: pointer;
  color: var(--emoovGray);
  font-size: 1.2rem;
}
.selector-down-icon.disabled{
  pointer-events: none;
  opacity: .5;
}
.selector{
  width: 100%;
  height: 100%;
  border-radius: 8px;
  font-weight: bold;
  font-size: .9rem;
  transition: .3s;
  padding: 0 10px;
  border: 1.5px solid var(--inputBorder);
  cursor: pointer;
  display: flex;
  align-items: center;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */

}
.selector:hover{
  border: 1.5px solid var(--inputBorder2);
}
.selector.disabled{
  pointer-events:none;
  background: #efefef;
  opacity: .5;
}
.selector.active{
  border: 1.5px solid var(--emoovBlue);
}
.selector p{
  margin: 0;
}
.selector p.none{
  color: #aaa;
}
.selector-item-list{
  position: absolute;
  top: 60px;
  width: 100%;
  /* border: 1.5px solid var(--inputBorder2); */
  box-shadow: 0px 4px 16px 0px #00000024;

  background: #fff;
  border-radius: 8px;
  transition: height .3s;
  overflow: hidden;
  z-index: 10;
}
.selector-item-list.max-5{
  max-height: 220px;
  overflow: scroll;
  scrollbar-width: none;
}
.selector-item-list.grid{
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
}
.selector-item-list.none{
  height: 0;
  border: none;
  transition: height .3s;
}
.selector-item{
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  width: 100%;
  height: 40px;
  transition: .3s;
  cursor: pointer;
  background: #fffdfd;
  font-weight: bold;
  position: relative;
}
.selector-item.none{
  height: 0;
  overflow: hidden;
  transition: 0s;
}
.selector-item.append{
  /* background: var(--emoovBlue30); */
  /* color: var(--emoovBlue); */
  border-top: 3px solid var(--inputBorder3);
}
.selector-item.append:hover{
  background: var(--emoovBlue10);
}
.selector-item.append svg{
  margin-right: 5px;
}
.selector-item:hover{
  background: var(--emoovBlue30);
}

.selector-item p{
  margin: 0;
  font-size: .85rem;
}
.selector-check-icon{
  position: absolute;
  right: 10px;
  color: var(--emoovBlue);
}




/* Blocker */
.cpo-blocker-alert-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0000004c;
  z-index: 100;
}
.cpo-blocker-alert-container{
  position: relative;
  width: 450px;
  height: 300px;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
}
.cpo-blocker-alert-logo-container{
  position: absolute;
  top: 0;
  left: -10px;
  width: 200px;
  height: 80px;
}
.cpo-blocker-alert-content-container{
  margin-top: 80px;
  width: 100%;
  height: calc(100% - 140px);
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  display: grid;
  grid-template-rows: 60px 1fr;
}
.cpo-blocker-alert-content-container p{
  margin: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 60px;
  padding-left: 20px;
}
.cpo-blocker-alert-content-container pre{
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  padding: 0 20px;
  text-wrap: wrap;
}
.cpo-blocker-alert-button-container{
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2px;
  background: #eaeaea;
  border-top: 2px solid #eaeaea;
}
.cpo-blocker-alert-button-container>div{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background: #fff;
  cursor: pointer;
  transition: .1s;
  font-weight: 500;
}
.cpo-blocker-alert-button-container>div:active{
  opacity: 0.3;
}
.cpo-blocker-alert-button-container>div:first-child{
  color: rgb(255, 99, 99);
}



/* Alert */
.cpo-alert-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0000004c;
  z-index: 100;
}
.cpo-alert-container{
  position: relative;
  width: 450px;
  height: 300px;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
}
.cpo-alert-logo-container{
  position: absolute;
  top: 0;
  left: -10px;
  width: 200px;
  height: 80px;
}
.cpo-alert-content-container{
  margin-top: 80px;
  width: 100%;
  height: calc(100% - 140px);
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  display: grid;
  grid-template-rows: 60px 1fr;
}
.cpo-alert-content-container p{
  margin: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 60px;
  padding-left: 20px;
}
.cpo-alert-content-container pre{
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  padding: 0 20px;
  text-wrap: wrap;
}
.cpo-alert-button-container{
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2px;
  background: #eaeaea;
  border-top: 2px solid #eaeaea;
}
.cpo-alert-button-container>div{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background: #fff;
  cursor: pointer;
  transition: .1s;
  font-weight: 500;
}
.cpo-alert-button-container>div:active{
  opacity: 0.3;
}
.cpo-alert-button-container>div:last-child{
  color: rgb(99, 195, 255);
}



/* React-Search */
.react-select-container{
  /* border: 1px solid #000; */
  width: 100%;
  height: 100%;
}
.react-select-container .react-select__control{
  height: 100%;
  border-radius: 8px;
}
.react-select-container .react-select__control:hover{
  border-color: var(--emoovBlue);
}
.react-select-container .react-select__control--is-focused{
  border-color: var(--emoovBlue);
  box-shadow: 0 0 0 1px var(--emoovBlue);
}

.react-select-container .react-select__indicator-separator{
  background-color:#fff;
}



/* input Date */

input[type='date']{
  position: relative;
  background: var(--mastercard) no-repeat;       
  padding-right: 10px;
  color: #858585;                        
}


input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button { 
  display: none;
} 
  
  

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
}








.icon {
  pointer-events: none;
  margin-right: 5px;
}

.file {
  display: none;
}

.file-upload-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.file::file-selector-button {
  font-size: 14px;
  background: #fff;
  border: 1px solid var(--oxfordBlue);
  border-radius: 12px;
  padding: 4px 32px;
  cursor: pointer;
}

.preview {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  /* background-color: #fff; */
  border-radius: 8px;
  overflow: hidden;
  border: 2px dashed var(--inputBorder2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .3s;
}
.preview.uploaded{
  border: 2px solid #fff;
}
.preview.uploaded:hover {
  border: 2px solid #fff;
}
.preview:hover {
  border-color: var(--emoovBlue1);
  border: 2px dashed var(--inputBorder2);
}

.preview.active {
  background-color: var(--emoovBlue1);
  border: 2px solid var(--emoovBlue);
}

.preview-container{
  display: flex;
  flex-direction: column;
  align-items: center;justify-content: center;
  color: var(--inputBorder2);
}
.preview-container .icon{
  font-size: 1.7rem;
}
.preview-container .preview_msg {
  font-weight: 500;
  font-size: .9rem;
}

.preview_desc {
  margin: 0;
  font-size: .8rem;
}

.preview .preview_info {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  background: var(--oxfordBlue1);
}

.file-uploaded-wrapper{
  width: 100%;
  height: 100%;
  z-index: 100;
  /* display: flex;
  align-items: center; */
  /* justify-content: center; */
  padding-left: 30px;
  padding-right: 20px;
  border: 1.5px solid var(--inputBorder);
  border-radius: 8px;
  display: grid;
  align-items: center;
  grid-template-columns: 55px 1fr;
}
.fileBtn{
  width: 55px;
  height: 55px;
  border-radius: 8px;
  background: var(--emoovBlue);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .13s;
}
.fileBtn svg{
  font-size: 1.5rem;
  color: #fff;
}
.fileBtn:active{
  opacity: 0.3;
}
.preview_info{
  height: 100%;
}
.file-info-container{
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}
.file-info-container>div:first-child{
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 10px;
}
.file-info-container>div:first-child>div:first-child{
  display: flex;
  align-items: end;
}
.file-info-container>div:last-child{
  display: flex;justify-content: center;
  align-items: center;
}

.file-info-container .info_value{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow:ellipsis;
  /* word-break: break-all; */
  font-size: 1rem;
  margin: 0;
}
.file-delete-icon{
  font-size: 1.5rem;
  color: var(--inputBorder);
  cursor: pointer;
}


.json-container{
  background: #23241E;
  width: 100%;
  height: 100%;
}
.json-container>div{
  background: #23241E;
}






/* Alert Msg modal */

.cpo-alert-msg-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
}
.cpo-alert-msg-modal-container{
  position: relative;
  width: 600px;
  height: fit-content;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px 30px;
  z-index: 1000;
}
.cpo-alert-msg-modal-header{
  width: 100%;
  margin-bottom: 30px;
  display: flex; align-items: center;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
}
.cpo-alert-msg-modal-header title{
  display: inline;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-alert-msg-modal-content{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.cpo-alert-msg-modal-content.delete{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cpo-alert-msg-modal-delete-alert{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100vh;
  width: 120px;
  height: 120px;
  /* background: #f4f4f4; */
  color: var(--inputBorder3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8rem;
  opacity: 0.3;
  z-index: 0;
}
.cpo-alert-msg-modal-delete-msg{
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  z-index: 10;
}
.cpo-alert-msg-modal-delete-msg>p{
  margin: 0;
  margin: 50px 0;
}
.cpo-alert-msg-modal-delete-button{
  width: 500px;
  height: 45px;
  background: var(--cardBtnRed);
  border-radius: 8px;
  display: flex;align-items: center;justify-content: center;
  transition: .13s;
  cursor: pointer;
}
.cpo-alert-msg-modal-delete-button p{
  color: var(--textRed);
  font-weight: 600;
}

.cpo-alert-msg-modal-delete-button:active{
  opacity: 0.3;
}



/* QrNavigator */
.qr-navigator-bg{
  width: 100vw;
  height: 100vh;
  /* min-width: 1000px; */
  /* min-height: 600px; */
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.qr-navigator-bg a{
  all: unset;
}
.qr-navigator-bg>img{
  position: absolute;
  width: 100%;
  height: 150%;
  bottom: -60%;
}
.qr-navigator-title{
  font-size: min(2rem, 5vw);
  font-weight: bold;
  margin-bottom: 50px;
}
.store-button-container{
  display: flex;
  align-items: center;
}
.store-button{
  width: min(200px, 30vw);
  height: min(200px, 30vw);
  border-radius: 8px;
  /* border: 1px solid var(--inputBorder); */
  background: #f9f9f9;
  margin: 0 min(50px, 5vw);
  position: relative;
  display: flex;align-items: center;justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: .13s;
}
.store-button:active{
  opacity: 0.3;
}
.store-button>img{
  width: 50%;
  object-fit: contain;
  object-position: 50% 50%;
}
.store-button>img.playStore{
  width: 40%;
  transform: translateX(5px) translateY(-10px);
  content: var(--playStore);
}
.store-button>img.appStore{
  transform: translateY(-10px);
  content: var(--appStore);
}
.store-button p{
  position: absolute;
  bottom: 0;
  font-weight: 600;
  font-size: 1.1rem;
}



/* Cloud Alert */
.cloud-alert-right-container{
  
  position: absolute;
  background: #fff !important;
  z-index: 20;
  border-radius: 8px;
  box-shadow: 0px 0px 14px 0px #00000014;
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
  font-size: .9rem;
  color: #3d3d3d !important;
  /* color: var(--inputBorder2); */
  padding: 5px 15px;
}





.checkbox_label:has(.cpo-charger-model-check.none){
  pointer-events: none;
  opacity: 0.3;
}
.checkbox_label:has(.cpo-charger-model-check.none)+p{
  opacity: 0.3;
}

.radio-button-container{
  display: flex;
  align-items: center;
}
.radio-button{
  box-shadow: var(--inputBorder2) 0 0 0 1px;
  /* border: 1px solid #000; */
  border-radius: 100vh;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.radio-button.clicked{
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px var(--emoovBlue);
  background: var(--emoovBlue);
}
.radio-button-container>p{
  margin: 0;  margin-left: 10px;
}

/* Page Selector */

.page-selector-container{
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 45px;
  /* margin-top: 30px; */
  border-top: 2px solid var(--inputBorder2);
}
.page-selector-container>svg{
  cursor: pointer;
  transition: .13s;
  font-size: 1.5rem;
}
.page-selector-container>svg:active{
  opacity: 0.3;
}
.page-selector-container>svg.disabled{
  pointer-events: none;
  opacity: 0.3;
}
.page-selector{
  width: 32px;
  height: 32px;
  border-radius: 100vh;
  /* border: 2px solid #000; */
  font-size: 1em;
  font-weight: 500;
  display: flex;align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
  margin: 0 10px;
}
.page-selector:active{
  opacity: 0.3;
}
.page-selector.clicked{
  color: #fff;
  background: var(--emoovBlue);
}
.page-total-info{
  position: absolute;
  right: 0;
  color: var(--inputBorder2);
}



/* Modal Header */
.modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.modal-close-button{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: .13s;
}
.modal-close-button:active{
  opacity: 0.3;
}