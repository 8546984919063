/* Station */
.cpo-station-management-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: calc(100vh - 190px);
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
}
.cpo-station-management-inner{
  width: 100%;
  /* margin: 0 50px; */
  min-width: 900px;
  max-width: 1200px;
  height: 100%;
  position: relative;
}


/* Header(search items) */
.cpo-station-management-search-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  position: relative;
  width: 100%;
  margin-top: 30px;
}
/* keyword search */
.cpo-station-management-search-input-container{
  display: grid;
  grid-template-columns: 1fr 20px;
  column-gap: 5px;
  align-items: center;
  height: 45px;
  width: 500px;
  border-bottom: 2px solid var(--inputBorder2);
  position: relative;
  /* margin-left: 30px; */
  padding: 0 5px 5px 5px;
  transition: .3s;
}
.cpo-station-management-search-input-container:has(input:focus){
  border-bottom: 2px solid var(--emoovBlue);
  
}
.cpo-station-management-search-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-station-management-search-input-container>svg{
  color: var(--inputBorder2);
  cursor: pointer;
  transition: .13s;
}
.cpo-station-management-search-input-container>svg:active{
  opacity: 0.3;
}



/* detail search */
.cpo-station-management-detail-search-button.open{
  transform: translateY(10px);
}
.cpo-station-management-detail-search-button.open:active{
  transform: translateY(10px);
}
.cpo-station-management-detail-search-button{
  height: 40px;
  padding: 0 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;  justify-content: center;
  background: #fff;
  color: var(--textGray2);
  cursor: pointer;
  transition: .3s;
  font-size: .9rem;
  border: 1px solid var(--buttonBorderGray2);
}
.cpo-station-management-detail-search-button svg{
  transition: .3s;
}
.cpo-station-management-detail-search-button svg.open{
  transform: rotate(180deg);
}
.cpo-station-management-detail-search-button p{
  margin: 0;
  font-weight: 600;
  margin-right: 5px;
}
.cpo-station-management-detail-search-button:active{
  transform: scale(95%);
}
.cpo-station-management-detail-search-modal-container{
  position: absolute;
  width: calc(100% - 10px);
  height: 245px;
  background: #fff;
  border: 1px solid var(--buttonBorderGray2);
  bottom: -250px;
  border-radius: 5px 0 5px 5px;
  z-index: 10;
  opacity: 0;
  transition: .3s;
  visibility: hidden;
}
.cpo-station-management-detail-search-modal-container.open{
  opacity: 1;
  visibility: visible;
}


/* Category Items */
.cpo-station-management-ten-category-outer{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}
.cpo-station-management-ten-category-container{
  margin: 10px 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  min-height: 140px;
  flex-wrap : wrap;
  overflow: visible;
  min-width: 550px;
  max-width: 1100px;
}
.cpo-station-management-ten-category-container>div{
  width: 120px;
  height: 80px;
  border-radius: 8px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: end;
  justify-content: start;
  padding-left: 10px;
}
.cpo-station-management-ten-category-container>div:last-child{
  margin-right: 0px;
}
.cpo-station-management-ten-category-container>div .cpo-station-management-category{
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 0;
  font-size: .9rem;
  font-weight: 600;
  color: var(--textGray2);
}
.cpo-station-management-ten-category-container>div p:last-child{
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-weight: 700;
  color: var(--emoovBlue);
}
.cpo-station-management-ten-category-container>div p:last-child::after{
  content: "개";
  font-weight: 500;
  margin-left: 5px;
  font-size: .7rem;
  color: var(--textGray2);
}



/* Station List */
.cpo-station-management-station-list-container.open{
  margin-top: 760px;
}
.cpo-station-management-station-list-container{
  width: 100%;
  height: fit-content;
  padding: 0 5px;
  margin-top: 30px;
  transition: .3s;
}
.cpo-station-management-station-list-button-container.open{
  opacity: 0;
}
.cpo-station-management-station-list-button-container{
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 10px;
  transition: .3s;
}
.cpo-station-management-station-list-button-container>div:active{
  opacity: 0.3;
}
.cpo-station-management-station-list-button-container>div{
  height: 45px;
  padding: 0 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;  justify-content: center;
  background: var(--emoovBlue);
  cursor: pointer;
  transition: .3s;
  margin-left: 10px;
}
.cpo-station-management-station-list-button-container>div svg{
  transform: translate(-5px, 1px);
  color: #fff;
}
.cpo-station-management-station-list-button-container>div p{
  color: #fff;
  margin: 0;
  font-size: .9rem;
  font-weight: bold;
}
.cpo-station-management-station-list-button-container>div.delete{
  /* background: #feeded; */
  background: #fff;
  border: 1px solid var(--buttonBorderGray2);
}
.cpo-station-management-station-list-button-container>div.delete.none{
  pointer-events: none;
  opacity: 0.3;
}
.cpo-station-management-station-list-button-container>div.delete p{
  /* color: #ff8d8d; */
  color: var(--textGray2);
}
.cpo-station-management-station-list-button-container>div.excel{
  background: #ddf8de;
}
.cpo-station-management-station-list-button-container>div.excel svg{
  color: #42ab48;
}
.cpo-station-management-station-list-button-container>div.excel p{
  color: #42ab48;
  margin: 0;
  font-size: .85rem;
  font-weight: bold;
}
.cpo-station-management-station-list{
  width: 100%;
  min-width: 850px;
  /* height: 200px; */
  /* border: 1px solid #000; */
  
}
.cpo-station-management-station-list-column{
  border-top: 2px solid var(--inputBorder);
  border-bottom: 1px solid var(--inputBorder3);
  height: 50px;
  position: relative;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 2fr 2fr 0.8fr 0.8fr;
}
.cpo-station-management-station-list-column-item{
  height: 100%;
  width: 100%;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: start; 
}
/* .cpo-station-management-station-list-column-item:nth-child(1){
  width: 45px;
}
.cpo-station-management-station-list-column-item:nth-child(2){
  width: 120px;
}
.cpo-station-management-station-list-column-item:nth-child(3){
  width: 120px;
}
.cpo-station-management-station-list-column-item:nth-child(4){
  width: 270px;
}
*/
.cpo-station-management-station-list-column-item:nth-child(5){
  justify-content: center;
}
.cpo-station-management-station-list-column-item:nth-child(6){
  justify-content: center;
} 
.cpo-station-management-station-list-column-item.checkbox{
  position: absolute;
  top: -50px;
  left: 20px;
  width: fit-content;
}
.cpo-station-management-station-list-column-item.checkbox p{
  color: var(--inputBorder2);
  font-weight: 500;
  font-size: .9rem;
  margin-left: 10px;
}

.cpo-station-management-station-list-column p{
  margin: 0;
  font-size: .8rem;
  color: var(--inputBorder2);
  font-weight: 400;
}
.cpo-station-management-list-content{
  /* padding: 0 35px; */
  width: 100%;
  height: fit-content;
  min-height: 270px;
  max-height: 500px;
  /* background: #f2f9fa; */
  /* box-shadow: 1px 2px 5px rgba(4, 41, 55, 0.2); */
  overflow: scroll;
  scrollbar-width: none;
}


.cpo-station-management-list-content-item.clicked{
  /* border: 3px solid var(--emoovBlue); */
  background: var(--itemActiveBlue);
}
.cpo-station-management-list-content-item{
  width: 100%;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid var(--inputBorder3);
  cursor: pointer;
  transition: .13s;
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 2fr 2fr 0.8fr 0.8fr;
  position: relative;
}
.cpo-station-management-list-content-item>div.handle{
  position: absolute;
  right: 0;
  top: 0;
}
.cpo-station-management-list-content-item>div{
  height: 100%;
  padding: 0 10px;
  display: flex;align-items: center;
}
.cpo-station-management-list-content-item>div p{
  user-select:text;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow:ellipsis;
  word-break: break-all;
  margin: 0;
  font-size: .9rem;
  font-weight: 400;
  color: #555;
}

/* .cpo-station-management-list-content-item>div:nth-child(1){
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
} */
.cpo-station-management-list-content-item>div:nth-child(1) label{
  display: flex;
  align-items: center;
  justify-content: center;
}



/* .cpo-station-management-list-content-item>div:nth-child(2){
  width: 120px;
}
.cpo-station-management-list-content-item>div:nth-child(3){
  width: 120px;
}
.cpo-station-management-list-content-item>div:nth-child(4){
  width: 270px;
}
.cpo-station-management-list-content-item>div:nth-child(4) p{
  font-size: .75rem;
  word-break: keep-all;
} */
.cpo-station-management-list-content-item>div:nth-child(5){
  justify-content: center;
}
.cpo-station-management-list-content-item>div:nth-child(6){
  justify-content: center;
}


.cpo-station-management-list-content-item-function-button-container{
  height: 40px;
  width: fit-content;
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: .3s;
}
.cpo-station-management-list-content-item-function-button-container>svg{
  cursor: pointer;
}
.cpo-station-management-list-content-item-function-button-container{
  font-size: 1.5rem;
}
.cpo-station-management-list-content-item-function-button-container:hover{
  /* background: #e4e8e9; */
}
.cpo-station-management-list-content-item-function-button-container:has(.cpo-station-management-list-content-item-function-button-list.open){
  box-shadow: 0px 0px 4px 0px #00000040;
  
}
.cpo-station-management-list-content-item-function-button-list{
  display: flex;
  align-items: center;
  justify-content: center;
  visibility:hidden;
  max-width: 0;
  background: #fff;
}
.cpo-station-management-list-content-item-function-button-list.open{
  max-width: 150px;
  visibility: visible;
}
.cpo-station-management-list-content-item-function-button-list>div:first-child::after, .cpo-station-management-list-content-item-function-button-list>div:nth-child(2)::after{
  content: "";
  display: block;
  position: absolute;
  right: 0px;
  width: 1.2px;
  height: 50%;
  background: #E2E2E2;
}
.cpo-station-management-list-content-item-function-button-list>div{
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cpo-station-management-list-content-item-function-button{
  width: max-content;
  height: 85%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* transition: .1s; */
}
.cpo-station-management-list-content-item-function-button:active{
  opacity: 0.5;
}
.cpo-station-management-list-content-item-function-button>div{
  width: max-content;
  
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6E6E6E;
  transition: background .3s;
  font-size: 1.1rem;
  border: 1.5px solid var(--emoovBlue);
  padding: 10px;
  border-radius: 8px;
  margin: 0 10px;
  color: var(--emoovBlue);
  font-weight: 600;
}





/* StationDetailModal */
.station-detail-modal-container{
  position: absolute;
  width: 100%;
  height: 800px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: 100;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  
  border: 1px solid var(--inputBorder3);
  /* box-shadow: 0.5px 1px 3px var(--oxfordBlue1); */
  display: grid;
  grid-template-rows: 1.5fr 0.8fr 10fr;
}
.station-detail-modal-container.open{
  opacity: 1;
  visibility: visible;
}
.station-detail-modal-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 10px; */
  padding: 20px;
  position: relative;
  /* border-bottom: 2px solid var(--emoovBlue10); */
}
.station-detail-modal-title{
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}
.station-detail-modal-button-container{
  display: flex;
  align-items: center;
}
.station-detail-modal-button-container>div{
  height: 40px;
  padding: 0 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;  justify-content: center;
  background: var(--emoovBlue);
  cursor: pointer;
  transition: .3s;
  margin-left: 10px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: .13s;
}
.station-detail-modal-button-container>div:active{
  opacity: 0.3;
}
.station-detail-modal-button-container>div.save.off{
  opacity: 0.3;
  pointer-events: none;
}
.station-detail-modal-button-container>div.close{
  background: #fff;
  border: 1px solid var(--buttonBorderGray2);
}
.station-detail-modal-button-container>div.close p{
  color: var(--textGray2);
}

.station-detail-modal-desc{
  position: absolute;
  margin: 0;
  right: 5px;
  top: 2px;
  font-size: .8rem;
  font-weight: bold;
  color: var(--oxfordBlue1);
}

.station-detail-modal-left-button-container, .station-detail-modal-right-button-container{
  position: absolute;
  width: 30px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--oxfordBlue1);
  transition: .3s;
  cursor: pointer;
  user-select: none;
}
.station-detail-modal-left-button-container:active{
  transform: translateY(-50%) translateX(-12px);
}
.station-detail-modal-right-button-container:active{
  transform: translateY(-50%) translateX(12px);
}

.station-detail-modal-left-button-container path,.station-detail-modal-right-button-container path{
  display: block;
  scale: 250%;
  transform: translate(-30%, -30%);
}
.station-detail-modal-left-button-container svg,
.station-detail-modal-right-button-container svg
{
  display: block;
  height: 80px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.station-detail-modal-left-button-container{
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}
.station-detail-modal-right-button-container{
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
}





.tab-button-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  position: relative;
  border-bottom: 2px solid var(--oxfordBlue1);

}
/* .tab-button:hover{
  background: var(--emoovBlue1);
} */
.tab-button{
  border-radius: 7px 7px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}
.tab-bar{
  position: absolute;
  width: 25%;
  height: 3px;
  background: var(--emoovBlue);
  bottom: -2px;
  transition: .3s;
}
.tab-bar.tab0{
  left: 0;
}
.tab-bar.tab1{
  left: 25%;
}
.tab-bar.tab2{
  left: 50%;
}
.tab-bar.tab3{
  left: 75%;
}
.tab-button.selected p{
  color: var(--oxfordBlue);
  color: var(--emoovBlue);
}
.tab-button p{
  margin: 0;
  color: var(--oxfordBlue1);
  font-weight: bold;
  font-size: 1.1rem;
}

.tab-content-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* height: 465px; */
  background: #FBFBFB;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}
.tab-content{
  width: 100%;
  height: 100%;

}
.tab-content>div{
  width: 100%;
  height: 100%;
}



/* Tab1 */
.station-detail-tab.tab1{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding: 20px;
  column-gap: 15px;
}
.station-detail-tab.tab1>div:nth-child(1){
  display: grid;
  grid-template-rows: 0.4fr 1fr 0.7fr;
  row-gap: 15px;
  height: 100%;
}
.station-detail-tab.tab1>div:nth-child(2){
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 15px;
  height: 100%;
}
.station-detail-tab.tab1>div:nth-child(1) .marginBottom{
  margin-bottom: 15px;
}

.station-detail-tab.tab1 .station-detail-input-title{
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  margin: 0;
  margin-bottom: 20px;
}
.station-detail-tab.tab1>div>div{
  padding: 25px;
  background: #fff;
  box-shadow: 0px 3px 20px 0px #0000000F;
  border-radius: 8px;
  width: 100%;
  height: 100%;

  display: grid;
  /* grid-template-rows: 1fr 1fr; */
}
.station-detail-tab.tab1>div>div:has(.station-detail-child-grid.time){
  padding: 0px 25px;
}
.station-detail-tab.tab1>div>div{
  padding: 25px;
  background: #fff;
  box-shadow: 0px 3px 20px 0px #0000000F;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: grid;
  /* grid-template-rows: 1fr 1fr; */
}
.station-detail-tab.tab1>div>div.row3{
  padding: 25px;
  background: #fff;
  box-shadow: 0px 3px 20px 0px #0000000F;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
.station-detail-tab.tab1>div>div.row3 .station-detail-input-title{
  margin-bottom: 10px;
}

.station-detail-tab-selector-container .selector-item p{
  color: var(--textGray4);
  font-weight: 400;
  font-size: 1rem;
}
.station-detail-tab-selector-container .selector p{
  color: var(--textGray4);
  font-weight: 400;
  font-size: 1rem;
}
.station-detail-charger-status-container{
  width: 100%;
  /* height: 45px; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.station-detail-charger-status-container p{
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
}

.station-detail-charger-status-container p:first-child{
  color: var(--emoovBlue);
}
.station-detail-charger-status-container p:nth-child(2){
  margin: 0 5px;
  color: var(--inputBorder2);
}
.station-detail-charger-status-container>div:last-child p{

  font-size: 1rem;
}
.station-detail-child-grid-column{
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  width: 50%;
  grid-template-rows: 1fr;
}
.station-detail-child-grid.time{
  display: grid;
  grid-template-rows: 1fr;
}
.station-detail-child-grid{
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.station-detail-info-input-wrapper{
  display: flex;
  align-items: center;
}




.station-detail-info-input-wrapper.toggle{
  display: flex;
  align-items: center;
}
.station-detail-info-input-wrapper.toggle>p.station-detail-input-title{
  margin: 0;
  margin-right: 20px;
}

.station-detail-child-grid.time .station-detail-info-input-wrapper p{
  font-size: 1rem;
  font-weight: 700;
  color: #000;
}

.station-detail-info-input-container.time{
  width: 80px;
  margin-left: 5px;
  margin-right: 10px;
}
.station-detail-authorization-tab-toggle-container{
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
}
.station-detail-authorization-tab-toggle-container p{
  margin: 0;
  font-weight: bold;
  font-size: .9rem;
  color: var(--oxfordBlue2);
}



.station-detail-child-grid .station-detail-info-input-wrapper p{
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  margin-right: 30px;
}
.station-detail-child-grid .station-detail-info-input-wrapper p:last-child{
  margin-left: 10px;
  color: var(--inputBorder2);
}
.station-detail-child-grid .station-detail-info-input-wrapper.time{
  position: relative;
}
.station-detail-child-grid .station-detail-info-input-wrapper.time p:last-child{
  font-size: .8rem;
  margin: 0;
  color: var(--inputBorder3);
  position: absolute;
  top: 100%;
  /* right: -10px; */
  left: 0;
  transform: translateY(-50%);
}
.station-detail-info-input-container{
  height: 45px;
  /* padding: 0 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid var(--inputBorder);
  border-radius: 8px;
  transition: .3s;
}

.station-detail-info-input-container:has(input:focus){
  border: 1.5px solid var(--emoovBlue);
}

.station-detail-info-input-container.parkingFee{
  margin-left: 33px;
  width: calc(100% - 110px);
}
.station-detail-info-input-container.parkingSpace{
  width: 80px;
}
.station-detail-info-input-container input{
  all: unset;
  height: 100%;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  padding-left: 10px;
}
.station-detail-info-input-container.time input,
.station-detail-info-input-container.parkingSpace input{
  padding: 0;
  text-align: center;
}









/* Tab 2 */
.station-detail-tab.tab2{
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
}
.station-detail-info-input-container.mapTab{
  width: 100%;
}
.station-detail-location-left-container{
  padding: 10px;  
  padding-right: 30px;
  display: grid;
  row-gap: 20px;
  grid-template-rows: 4fr 3fr;
}
.station-detail-location-left-container>div{
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px #0000000F;
  padding: 30px;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.station-detail-address-container{
  position: relative;
}
.station-detail-address-container .station-detail-input-title.address{
  margin-top: 10px;
  margin-bottom: 20px;
}
.station-detail-location-left-container>div .station-detail-input-title{
  margin: 0;
  font-weight: bold;
  margin-bottom: 10px;
}
.station-detail-location-convert-button-container{
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: end;
}
.station-detail-location-convert-button:active{
  opacity: .5;
}
.station-detail-info-input-wrapper.mapTab2 p{
  font-weight: bold;
  margin: 0;
}
.station-detail-info-input-container.mapTab2{
  width: calc(100% - 53px);
  margin-left: 20px;
}


.station-detail-location-convert-button{
  width: 20%;
  height: 100%;
  background: var(--oxfordBlue);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;  justify-content: center;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
  transition: .17s;
}
.station-detail-location-convert-button p{
  margin: 0;
  font-size: .9rem;
  font-weight: 600;
}



.station-detail-location-right-container{
  position: relative;
}
.station-detail-guide-container{
  position: absolute;
  top: 10px;
  right: 0;
  width: calc(100% - 60px);
  margin: 0 30px;
  height: 100px;
  background: #fff;
  border-radius: 8px;
  z-index: 14;
  box-shadow: 0 0 0 3px var(--emoovBlue);
  display: flex;
  align-items: center; justify-content: center;
  font-size: 1rem;
  padding: 0 20px;
}
.station-detail-guide-container>pre{
  display: inline-block;
  width: 100%;
  text-wrap:wrap;
  line-height: 2rem;
  font-weight: 500;
}
.station-detail-guide-container>pre>color{
  display: inline;
  color: var(--emoovBlue);
  font-weight: 600;
}
.map{
  width: calc(100% + 20px);
  height: calc(100% + 40px);
  transform: translate(0px, -20px);
  overflow: hidden;
  transition: .3s;
}




/* Tab 4 */
.station-detail-tab.fourth{
  width: 100%;
  height: 100%;
  padding: 20px;
}
.station-detail-authorization-container{
  background: #fff;
  box-shadow: 0px 3px 20px 0px #0000000F;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.station-detail-authorization-container>div{
  width: 100%;
  height: 100%;
  padding: 10px;
}
.station-detail-tab.fourth .station-detail-input-title{
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  margin: 0;
  margin-bottom: 20px;
}






/* .station-detail-tab.fourth .preview_info .file-info-container .size {
  margin: 0;
  font-size: .7rem;
} */
.station-detail-authorization-input-container{
  height: 40px;
  width: 100%;
  /* padding: 0 10px; */
  display: flex;
  align-items: center;
  border: 1.5px solid var(--oxfordBlue1);
  border-radius: 10px;
  transition: .3s;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.station-detail-authorization-input-container:hover{
  border: 1.5px solid var(--oxfordBlue);
}
.station-detail-authorization-input-container form{
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.station-detail-authorization-input-container input.hidden{
  width: 0;margin: 0;
}
.station-detail-authorization-input-container input{
  all: unset;
  height: 40px;
  margin-left: 32%;
  width: 66%;
  font-weight: 500;
  font-size: .9rem;
  cursor: pointer;

}
.station-detail-authorization-input-container .inputMeasure{
  position: absolute;
  width: 30%;
  height: 60%;
  left: 0;
  border-right: 1px solid #ddd ;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--oxfordBlue2);
  font-size: .9rem;
  z-index: 100;
}












.station-detail-tab.tab3{
  padding: 20px;
}
.station-detail-tab.tab3>div{
  width: 100%;
  height: 100%;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px #0000000F;
  column-gap: 30px;
}
.station-detail-tab.tab3>div>div{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}
.station-detail-tab.tab3 .station-detail-input-title{
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  margin: 0;
  margin-bottom: 20px;
}








/* Station Enroll Modal */
.cpo-station-enroll-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-station-enroll-modal-container{
  position: relative;
  width: 550px;
  /* height: 330px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
}
.cpo-station-enroll-modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.cpo-station-enroll-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-station-enroll-modal-input-container{
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}
.cpo-station-enroll-modal-input-container input{
  all: unset;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  font-size: .9rem;
  transition: .3s;
  padding: 0 10px;
  border: 1.5px solid var(--textGray1);
  transition: .3s;
}
.cpo-station-enroll-modal-input-container input:focus, .cpo-station-enroll-modal-input-container input:hover{
  border: 1.5px solid var(--emoovBlue);
}

.cpo-station-enroll-modal-container>div:last-child{
  display: flex;align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}
.cpo-station-modal-enroll-button{
  width: 100%;
  height: 50px;
  background: var(--emoovBlue);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  cursor: pointer;
  transition: .17s;
}
.cpo-station-modal-enroll-button:active{
  opacity: 0.3;
}
.cpo-station-modal-enroll-button p{
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
}
.cpo-station-modal-enroll-button.none{
  opacity: .3;
  pointer-events: none;
}





.cpo-address-input-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-address-input-modal-container{
  position: absolute;
  width: 950px;
  /* height: 330px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
  z-index: 15;
}
.cpo-address-input-modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.cpo-address-input-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}