.cpo-system-setting-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: calc(100vh - 190px);
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cpo-system-setting-container p{
  margin: 0;
}
.cpo-system-setting-inner{
  width: 100%;
  max-width: 1100px;
  height: 100%;
  position: relative;
}
.cpo-chas-setting-header{
  display: flex;
  position: relative;
  margin-bottom: 30px;
}

.cpo-chas-selector-container{
  width: 280px;
  margin-right: 20px;
}
.cpo-chas-button-container{
  position: absolute;
  right: 0;
  display: flex;
}

.cpo-chas-button-container>div{
  padding: 0 15px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--emoovBlue);
  display: flex;
  align-items: center;  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  transition: .13s;
  margin-left: 10px;
}
.cpo-chas-button-container>div:active{
  opacity: 0.3;
}
.cpo-chas-button-container>div:first-child{
  background: #fff;
  color: var(--emoovBlue);
}
.cpo-chas-button-container>div:nth-child(2){
  color: #fff;
  background: var(--emoovBlue);
}

.cpo-chas-content-container{
  display: grid;
  grid-template-rows:35px 50px 1fr;
  row-gap: 20px;
}
.cpo-chas-navigator{
  display: flex; align-items: center;cursor: pointer;
}
.cpo-chas-content-category-info{
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--emoovBlue);
}
.cpo-chas-content-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.cpo-chas-title-container{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: end;
}
.cpo-chas-title-container>p{
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}
.cpo-chas-title-container>p.cpo-chas-edit-info{
  margin-left: 10px;
  color: var(--emoovBlue);
}
.cpo-chas-title-container>p.cpo-chas-title-select-msg{
  color: var(--textGray1);
}

.cpo-chas-data-container{
  position: absolute;
  top: 0;
  left: 0;
  font-size: .85rem;
  display: flex;
  align-items: center;
  color: var(--textGray2);
  font-weight: 500;
}

.cpo-chas-data-container>p{
  margin-right: 5px;
}
.cpo-chas-data-container>svg{
  cursor: pointer;
}
.cpo-chas-data-modal{
  position: absolute;
  width: fit-content;
  background: #fff;
  border-radius: 8px;
  top: -140%;
  left: 70px;
  padding: 10px;
  box-shadow: 0px 0px 4px 0px #00000040;
  font-size: .85rem;
}

.cpo-chas-title-function-container{
  display: flex;
  margin-left: 30px;
}

.cpo-chas-title-function-container>div:first-child::after{
  content: "";
  display: block;
  position: absolute;
  right: 0px;
  width: 1.2px;
  height: 50%;
  background: #E2E2E2;
}
.cpo-chas-title-function-container>div{
  height: 25px;
  width: 40px;
  /* margin-left: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cpo-chas-title-function-button{
  width: 85%;
  height: 85%;
  /* border-radius: 100vh; */
  /* background: #f6fbfc; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* transition: .1s; */
}
.cpo-chas-title-function-button:active{
  opacity: 0.5;
}
.cpo-chas-title-function-button>div{
  width: 85%;
  height: 85%;
  /* border-radius: 100vh; */
  /* background: var(--buttonBlueOuter); */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6E6E6E;
  transition: background .3s;
  font-size: 1.2rem;
}
.cpo-chas-title-function-button>div:hover{
  /* background: #b4eafa; */
}
.cpo-chas-title-function-button.delete>div{
  /* background: var(--buttonRed); */
  color: #FF295D;
  transition:background .3s;
  font-size: 1.1rem;
}
.cpo-chas-title-function-button.delete>div:hover{
  /* background: #ffa4a4; */
}
.cpo-chas-title-function-button>div p{
  margin: 0;
  font-weight: bold;
}

.cpo-chas-transfer-button{
  width: max-content;
  height: 35px;
  padding: 0 15px;
  background: #3d3d3d;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 0.9em;
  cursor: pointer;
  transition: .13s;
  margin-left: 10px;
}
.cpo-chas-transfer-button:active{
  opacity: 0.3;
}
.cpo-chas-transfer-button p{
  white-space: nowrap;
  margin-left: 5px;
}

.cpo-chas-item-list{
  
}
.cpo-chas-item-list>div{
  width: 100%;
  height: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 10px;
  background: #fff;
  border: 1.5px solid var(--inputBorder3);
  transition: .13s;
  cursor: pointer;
}
.cpo-chas-item-list>div:active{
  border: 1.5px solid var(--emoovBlue);
  background: var(--emoovLightBlue2);
}





.cpo-chas-message-detail-container{

}
.cpo-chas-message-header{
  width: 100%;
  height: 50px;
  background: var(--emoovLightBlue2);
  border-top: 1px solid var(--inputBorder3);
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 10px;
  cursor: pointer;
  position: relative;
}
.cpo-chas-message-header svg{
  margin-right: 10px;
}
.cpo-chas-message-delete-button{
  position: absolute;
  right: 0;
  color: var(--textRed);
  transition: .13s;
  cursor: pointer;
}
.cpo-chas-message-delete-button:active{
  opacity: 0.3;
}
.cpo-chas-message-detail-column{
  display: flex;
  align-items: center;
  font-size: .8rem;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid var(--inputBorder3);
  padding-left: 10px;
}
.cpo-chas-message-detail-column>p{
  display: inline-block;
  color: var(--textGray);
  font-weight: 500;
}
.cpo-chas-message-detail-column>p:first-child{
  width: 80px;
}
.cpo-chas-message-detail-column>p:nth-child(2){
  width: 280px;
}

.cpo-chas-action-item{
  padding-left: 10px;
  width: 100%;
  height: 50px;
  display: grid;
  align-items: center;
  grid-template-columns: 80px 280px 1fr 120px 80px;
  border-bottom: 1px solid var(--inputBorder3);
}
.cpo-chas-action-list-cell.private>div{
  border: 2px solid var(--emoovBlue);
  color: var(--emoovBlue);
  border-radius: 100vh;
  width: fit-content;
  height: 23px;
  padding: 0 10px;
  font-size: .8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.cpo-chas-action-list-cell.more>p{
  font-size: .9rem;
  cursor: pointer;
  transition: .13s;
  color: var(--inputBorder2);
  font-weight: 500;
}
.cpo-chas-action-list-cell.more>p:active{
  opacity: 0.3;
}

.cpo-chas-management-button{
  border: 1px solid var(--inputBorder3);
  border-radius: 8px;
  
  width: max-content;
  height: 35px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
  font-size: 0.9em;

}
.cpo-chas-management-button:active{
  opacity: 0.3;
}
.cpo-chas-management-button p{
  white-space: nowrap;margin: 0;
}


/* CHAS Detail Modal */
.cpo-chas-detail-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-chas-detail-modal-container{
  position: relative;
  max-width: 1100px;
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px 30px;
  z-index: 1000;
}
.cpo-chas-detail-modal-header{
  width: 100%;
  margin-bottom: 30px;
  display: flex; align-items: center;
}
.cpo-chas-detail-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-chas-detail-row{
  display: grid;
  grid-template-columns: auto 1fr;
}

.cpo-chas-detail-column{
  display: flex;
  align-items: center;
  background: #efefef;
  height: 50px;
  align-items: center;
  padding: 0 10px;
  color: var(--textGray2);
  font-size: .9rem;
  border-top: 1.5px solid var(--inputBorder2);
  border-bottom: 1.5px solid var(--inputBorder3);
}
.cpo-chas-detail-content{
  display: flex;
  align-items: center;
  background: #fff;
  height: 50px;
  padding: 0 10px;
  color: #000;
  font-size: .9rem;
  border-top: 1.5px solid var(--inputBorder2);
  border-bottom: 1.5px solid var(--inputBorder3);
}


/* Delete modal */

.cpo-chas-delete-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-chas-delete-modal-container{
  position: relative;
  width: 600px;
  height: fit-content;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px 30px;
  z-index: 1000;
}
.cpo-chas-delete-modal-header{
  width: 100%;
  margin-bottom: 30px;
  display: flex; align-items: center;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
}
.cpo-chas-delete-modal-header title{
  display: inline;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-chas-delete-modal-content{
  display: flex;
  flex-direction: column;
  align-items: center;

}


/* Duplication modal */

.cpo-chas-duplication-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-chas-duplication-modal-container{
  position: relative;
  width: 600px;
  height: fit-content;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px 30px;
  z-index: 1000;
}
.cpo-chas-duplication-modal-header{
  width: 100%;
  margin-bottom: 30px;
  display: flex; align-items: center;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
}
.cpo-chas-duplication-modal-header title{
  display: inline;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 10px;
}

.cpo-chas-duplication-modal-content-container{
  margin-bottom: 20px;
}
.cpo-chas-duplication-modal-content-container>p{
  font-weight: bold;
  margin-bottom: 15px;
}
.cpo-chas-duplication-modal-grid-wrapper{
  display: grid;
  grid-template-columns: 1fr 100px;
  column-gap: 10px;
  margin-bottom: 40px;
  position: relative;
}
.cpo-chas-duplication-modal-input-container{
  width: 100%;
  height: 45px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder);
}
.cpo-chas-duplication-modal-input-container textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1.5rem;
  font-size: 1rem;
}
.cpo-chas-duplication-modal-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-chas-duplication-check-button{
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .13s;
  cursor: pointer;
}
.cpo-chas-duplication-check-button:active{
  opacity: 0.3;
}
.cpo-chas-duplication-check-info{
  position: absolute;
  bottom: -20px;
  left: 10px;
  font-size: 0.9rem;
  color: var(--textRed);
}

.cpo-chas-duplication-modal-input-container.textarea{
  height: 90px;
  padding: 10px;
}

.cpo-chas-duplication-modal-enroll-button{
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;justify-content: center;
  background: #3d3d3d;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: .13s;
}
.cpo-chas-duplication-modal-enroll-button:active{
  opacity: 0.3;
}

.cpo-chas-duplication-modal-enroll-button.append{
  pointer-events: none;
  opacity: 0.3;
}
.cpo-chas-duplication-modal-enroll-button.append.enable{
  pointer-events: all;
  opacity: 1;
}
.cpo-chas-duplication-modal-enroll-button.append.enable:active{
  opacity: 0.3;
}







/* Edit 1 -normal*/

.cpo-chas-data-modal.edit{
  position: absolute;
  width: fit-content;
  background: #fff;
  border-radius: 8px;
  top: -10%;
  left: 120px;
  padding: 10px;
  box-shadow: 0px 0px 4px 0px #00000040;
  font-size: .85rem;
}

.cpo-chas-edit-message-selector-block-container{
  box-shadow: 0px 0px 10px 0px #00000033;
  padding: 20px;
  border-radius: 8px;
  width: 550px;
  margin-bottom: 60px;
}
.cpo-chas-edit-message-selector-block-container>p:first-child{
  margin-bottom: 15px;
  font-weight: 600;
}
.cpo-chas-edit-message-selector-wrapper{
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 15px;
}
.cpo-chas-edit-message-select-button{
  width: 70px;
  height: 45px;
  background: var(--emoovBlue);
  border-radius: 8px;
  color: #fff;
  display: flex;align-items: center;justify-content: center;
  font-weight: 500;
  cursor: pointer;
  transition: .13s;
}
.cpo-chas-edit-message-select-button:active{
  opacity: 0.3;
}
.cpo-chas-edit-message-selector-block-container>p:last-child{
  margin: 5px 0 10px 0;
  color: var(--textRed);
  font-weight: 500;
  font-size: 0.8rem;
}


.cpo-chas-edit-divider{
  position: absolute;
  left: 0;
  top: 310px;
  height: 15px;
  width: 100%;
  background: #F1F1F1;
}


.cpo-chas-action-edit-block-container{
  /* border: 1px solid #000; */
  padding-top: 30px;
}

.cpo-chas-title-container.edit{
  display: flex;align-items: center;
}
.cpo-chas-title-container.edit svg{
  margin: 0 10px;
}
.cpo-chas-title-container.edit>p:last-child{
  font-size: 1rem;
}

.cpo-chas-action-grid-container{
  display: grid;
  grid-template-columns: 1fr 80px 1fr;
  width: 100%;
  height: 500px;
}
.cpo-chas-action-edit-container{
  display: grid;
  grid-template-rows: 50px 50px 1fr;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  overflow: hidden;
}
.cpo-chas-action-edit-title-container{
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 600;
}
.cpo-chas-action-edit-search-container{
  display: grid;
  align-items: center;
  grid-template-columns: 30px 1fr;
  padding: 15px;
  border-bottom: 2px solid var(--inputBorder3);
  
}
.cpo-chas-action-edit-search-container svg{
  font-size: 1.4rem;
  color: var(--inputBorder2);
  cursor: pointer;
  transition: .1s;
}
.cpo-chas-action-edit-search-container svg:active{
  opacity: 0.3;
}
.cpo-chas-action-edit-search-container input{
  all: unset;

}
.cpo-chas-action-edit-item-list{
  overflow-y: scroll;
}
.cpo-chas-action-edit-item{
  height: 50px;
  display: flex;
  align-items: center;
  padding: 20px;
  transition: .13s;
  cursor: pointer;
}
.cpo-chas-action-edit-item:hover{
  background: #f7f7f7;
}
.cpo-chas-action-edit-item{
  padding-left: 13px;
  border-left: 7px solid #ffffff00;
}
.cpo-chas-action-edit-item.selected{
  background: var(--emoovLightBlue2);
  border-left: 7px solid var(--emoovBlue);
}

.cpo-chas-action-edit-item.right>p:first-child{
  margin-left: 5px;
  margin-right: 15px;
  font-size: .85rem;
  color: #878787;
}




.cpo-chas-action-edit-four-button-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cpo-chas-action-edit-four-button-container>div{
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin: 5px 0;
  cursor: pointer;
  transition: .13s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--inputBorder3);
  color: var(--inputBorder3);
  background: #fff;
  pointer-events: none;
}

.cpo-chas-action-edit-four-button-container>div.activate{
  border-color: var(--emoovBlue);
  color: var(--emoovBlue);
  background: var(--emoovLightBlue2);
  pointer-events: all;
}












/* Edit 2 --dataTransfer */

.cpo-chas-edit-data-transfer-block-container{
  box-shadow: 0px 0px 10px 0px #00000033;
  padding: 20px;
  border-radius: 8px;
  /* width: 550px; */
  margin-bottom: 60px;
  min-width: 500px;
  width: fit-content;
}
.cpo-chas-edit-data-transfer-block-container>p:first-child{
  margin-bottom: 15px;
  font-weight: 600;
}
.cpo-chas-edit-data-transfer-wrapper{
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 15px;
  align-items: end;
}
.cpo-chas-edit-message-select-button{
  width: 70px;
  height: 45px;
  background: var(--emoovBlue);
  border-radius: 8px;
  color: #fff;
  display: flex;align-items: center;justify-content: center;
  font-weight: 500;
  cursor: pointer;
  transition: .13s;
}
.cpo-chas-edit-message-select-button:active{
  opacity: 0.3;
}

.cpo-chas-edit-data-transfer-container>p{
  margin: 10px 0 15px;
  font-size: 0.9em;
  font-weight: 600;
}

.cpo-chas-edit-data-transfer-input-container{
  /* border: 1px solid var(--inputBorder);
  border-radius: 8px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 10px; */
}
/* .cpo-chas-edit-data-transfer-container input{
  all: unset;
  width: 100%;
  height: 100%;
} */











/* 
.cpo-chas-item-function-button-container{
  height: 50px;
  width: fit-content;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  transition: .3s;
}
.cpo-chas-item-function-button-container{
  font-size: 1.5rem;
}
.cpo-chas-item-function-button-container:hover{
  background: #e4e8e9;
}
.cpo-fap-item-function-button-list{
  display: flex;
  align-items: center;
  justify-content: center;
  visibility:hidden;
  max-width: 0;
}
.cpo-fap-item-function-button-list.open{
  max-width: 150px;
  visibility: visible;
}
.cpo-fap-item-function-button-list>div{
  height: 50px;
  width: 50px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-fap-item-function-button{
  width: 90%;
  height: 90%;
  border-radius: 100vh;
  background: #f6fbfc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.cpo-fap-item-function-button:active{
  opacity: 0.5;
}
.cpo-fap-item-function-button>div{
  width: 85%;
  height: 85%;
  border-radius: 100vh;
  background: var(--buttonBlueOuter);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  transition: background .3s;
}
.cpo-fap-item-function-button>div:hover{
  background: #b4eafa;
}
.cpo-fap-item-function-button.delete>div{
  background: var(--buttonRed);
  color: #333;
  transition:background .3s;
}
.cpo-fap-item-function-button.delete>div:hover{
  background: #ffa4a4;
}
.cpo-fap-item-function-button>div p{
  margin: 0;
  font-weight: bold;
} */










/* subscription */

.cpo-subscription-page-container{
  width: 100%;
  min-height: calc(100vh - 190px);

  /* border: 1px solid #000; */
}
.cpo-subscription-tab-container{
  margin-bottom: 40px;
  width: 350px;
  height: 50px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 2px solid var(--tabBorder);
}

.subscription-tab-button{
  border-radius: 7px 7px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}
.subscription-tab-bar{
  position: absolute;
  width: 33.3333%;
  height: 3px;
  background: var(--emoovBlue);
  top: 47.5px;
  transition: .3s;
  transition: left 0.3s cubic-bezier(0.175, 0.35, 0.5, 1.13);
  z-index: 2;
}
.subscription-tab-button.selected p{
  color: var(--emoovBlue);
}
.subscription-tab-button p{
  margin: 0;
  color: var(--oxfordBlue1);
  font-weight: bold;
}
.subscription-tab-bar.tab0{
  left: 0;
}
.subscription-tab-bar.tab1{
  left: 33.3333%;
}
.subscription-tab-bar.tab2{
  left: 66.6666%;
}


.cpo-current-subscription-section{
  height: fit-content;
  min-height: 250px;
  /* background: #000; */
  /* border: 1px solid #000; */
}

.cpo-current-subscription-item-container{
  width:  100%;
  min-height: 150px;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;
  padding-bottom: 10px;
}
.cpo-current-subscription-item-container>div{
  width: 100%; min-height: 150px;
  background: #000;
  border-radius: 8px;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  border-radius: 10px;
  transition: .3s;
  background: #fff;
}




.cpo-subscription-card-container{
  /* display: inline-grid;
  transition: transform 0.3s;
  transform: perspective(800px) rotateY(0deg);
  transform-style: preserve-3d; */
}


.cpo-subscription-card-container>*{
  grid-area: 1 / 1 / 1 / 1;
  backface-visibility: hidden;
}
.cpo-subscription-item-container.front{
  /* border-top: 10px solid var(--emoovBlue); */
  background: #fff;

}
.cpo-subscription-item-container.back{
  border-top: 10px solid var(--emoovBlue);
  border-bottom: 20px solid var(--emoovBlue);
  background: #fff;
  transform: rotateY(180deg);
}


.cpo-subscription-item-container{
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.cpo-subscription-title-container{
  width: 100%;
  display: flex;
  align-items: center;justify-content: start;
}
.cpo-subscription-title{
  font-size: 1.2rem;
  font-weight: bold;
}


.cpo-subscription-price-container{
  margin: 15px 0;
}
.cpo-subscription-price-container>div{
  display: flex;
  margin-top: 10px;
}
.cpo-subscription-price-container>div>p{
  margin-right: 5px;
}

.cpo-subscription-price-container>div>p:nth-child(2){
  color: var(--emoovBlue);
  font-weight: 700;
}

.cpo-subscription-content-container{
  border-top: 1px solid var(--inputBorder3);
  padding-top: 15px;
  margin-bottom: 10px;
  height: 125px;
}

.cpo-subscription-content-container>div{
  display: flex;
  font-size: 0.9em;
  color: var(--textGray2);
}
.cpo-subscription-content-container>div>svg{
  margin-right: 5px;
}

.cpo-subscription-btn-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-subscription-btn{
  width: 100%;
  height: 35px;
  background: var(--emoovBlue);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: .13s;
  cursor: pointer;
}
.cpo-subscription-btn:active{
  opacity: 0.3;
}
.cpo-subscription-divider{
  position: absolute;
  left: 0;
  top: 490px;
  height: 15px;
  width: 100%;
  background: #F1F1F1;
}
.cpo-subscription-btn.subscribed{
  background: #fff;
  border: 1px solid var(--emoovBlue);
  pointer-events: none;
  color: var(--emoovBlue);
}






/* Enrolled Card */

.cpo-subscription-enrolled-card-container{
  width: 100%;
  height: 60px;
  box-shadow: 0px 0px 10px 1px #0000001A;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
  margin-bottom: 30px;
}

.cpo-subscription-enrolled-card-status-container{
  height: 40px;
  width: fit-content;
  padding: 0 15px;
  border: 3px solid var(--emoovBlue);
  color: var(--emoovBlue);
  font-weight: 700;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  font-size: 1.15rem;
}
.cpo-subscription-enrolled-card-status-container.cancel{
  border: 3px solid var(--emoovBlue10);
  color: var(--emoovBlue10);
}
.cpo-subscription-enrolled-card-status-container.expired{
  border: 3px solid var(--inputBorder);
  color: var(--inputBorder);
}


.cpo-subscription-enrolled-card-title-container{
  display: flex;
  align-items: center;
  position: relative;
}
.cpo-subscription-enrolled-card-title-container>p:last-child{
  display: inline-block;
  margin: 0;
  margin-left: 10px;
  color: var(--inputBorder2);
  font-size: .9rem;
  cursor: pointer;
  border-bottom: 1px solid var(--inputBorder2);
}





.cpo-subscription-enroll-date-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.cpo-subscription-enroll-date-container>div{
  display: flex;
  align-items: center;
}
.cpo-subscription-enroll-date{
  font-weight: 600;
  font-size: 1rem;
  color: var(--emoovBlue);
}
.cpo-subscription-enroll-date-container>div .cpo-subscription-enroll-date-title{
  font-size: .9rem;
  font-weight: 500;
  margin-right: 5px;
}
.cpo-subscription-enroll-date-container>div .cpo-subscription-enroll-date-title.cancelStart{
  color: var(--textGray1);
}
.cpo-subscription-enroll-date.cancel{
  color: var(--textRed);
}
.cpo-subscription-enroll-date.cancelStart{
  color: var(--textGray1);
}
.cpo-subscription-enroll-date.expired{
  color: var(--textGray);
}
.cpo-subscription-enroll-date-container>p{
  margin: 0 10px;
}
.cpo-subscription-enrolled-card-period-container{
  display: flex;
}
.cpo-subscription-enrolled-card-divider{
  width: 1px;
  height: 40px;
  background: var(--inputBorder3);
  margin: 0 20px;
}
.cpo-subscription-termination-btn{
  position: absolute;
  right: 15px;
  height: 40px;
  width: fit-content;
  padding: 0 15px;
  border: 1px solid var(--inputBorder3);
  color: var(--textGray3);
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .13s;
  cursor: pointer;
}
.cpo-subscription-termination-btn:active{
  opacity: 0.3;
}
.cpo-subscription-termination-btn.cancel{
  border: 1px solid var(--textRed);
  color: var(--textRed);
}
.cpo-subscription-termination-btn.re-subscription{
  border: 1px solid var(--emoovBlue);
  color: var(--emoovBlue);
}
.cpo-subscription-termination-btn.disabled{
  /* border: 1px solid var(--emoovBlue);
  color: var(--emoovBlue); */
  pointer-events: none;
}

.cpo-subscription-detailed-modal-container{
  position: absolute;
  width: fit-content;
  min-width: 120px;
  padding: 20px;
  min-height: 40px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px #00000040;
  right: calc(-100% + 20px);
  top: 0px;
}
.cpo-subscription-detailed-modal-content-wrapper{
  display: flex;
  align-items: center;
  font-size: .9rem;
  color: var(--textGray2);
}
.cpo-subscription-detailed-modal-content-wrapper svg{
  margin-right: 5px;
}






/* subscription Enroll Modal */
.cpo-subscription-enroll-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-subscription-enroll-modal-container{
  position: relative;
  width: 450px;
  min-height: 330px;
  height: fit-content;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  padding: 30px;
}
.cpo-subscription-enroll-modal-header{
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
}
.cpo-subscription-enroll-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-subscription-enroll-modal-info-container{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.cpo-subscription-enroll-modal-info-container p:last-child{
  margin-left: 10px;
  font-weight: 700;
  color: var(--emoovBlue);
}
.cpo-subscription-enroll-modal-period-container{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* padding: 10px 30px; */
  height: 100px;
  column-gap: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.cpo-subscription-enroll-modal-period-container>div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid var(--inputBorder3);
  cursor: pointer;
  transition: .3s;
}

.cpo-subscription-enroll-modal-period-container>div.selected{
  border: 2px solid var(--emoovBlue);
  background: #fff;
}
.cpo-subscription-enroll-modal-period-price{
  display: flex;
  padding: 10px 0;
  font-weight: 700;
  color: var(--emoovBlue);
  font-size: 1.2rem;
}
.cpo-subscription-enroll-modal-period-container>div>p:last-child{
  font-size: .8rem;
  color: var(--inputBorder2);
  font-weight: 600;
}

.cpo-subscription-enroll-modal-payment-method-container{
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
}
.cpo-subscription-enroll-modal-payment-method-container .selector-wrapper{
  margin-top: 10px;
}
.cpo-subscription-enroll-modal-payment-none-msg{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #ddd;
  cursor: pointer;
  transition: .3s;
  margin-top: 10px;
}
.cpo-subscription-enroll-modal-payment-none-msg:hover{
  background: var(--emoovBlue);
}
.cpo-subscription-enroll-modal-payment-none-msg:hover p{
  color: #fff;
}
.cpo-subscription-enroll-modal-payment-none-msg p{
  font-weight: 500;
  color: var(--textGray);
}







.cpo-subscription-enroll-modal-input-container{
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}
.cpo-subscription-enroll-modal-input-container input{
  all: unset;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  font-size: .9rem;
  transition: .3s;
  padding: 0 10px;
  border: 1.5px solid var(--textGray1);
  transition: .3s;
}
.cpo-subscription-enroll-modal-input-container input:focus, .cpo-subscription-enroll-modal-input-container input:hover{
  border: 1.5px solid var(--emoovBlue);
}

.cpo-subscription-enroll-modal-container>div:last-child{
  display: flex;align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}
.cpo-subscription-modal-enroll-button{
  width: 100%;
  height: 50px;
  background: #3d3d3d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  cursor: pointer;
  transition: .17s;
}
.cpo-subscription-modal-enroll-button:active{
  opacity: 0.3;
}
.cpo-subscription-modal-enroll-button p{
  margin: 0;
  font-weight: 500;
  font-size: 1.1rem;
}
.cpo-subscription-modal-enroll-button.none{
  opacity: .3;
  pointer-events: none;
}






.cpo-chas-edit-data-transfer-input-container{
  /* border: 1px solid var(--inputBorder); */
  /* border-radius: 8px; */
  height: 45px;
  display: flex;
  align-items: center;
  /* padding: 0 10px; */
}



.cpo-chas-edit-data-transfer-block-container .react-select__menu{
  border-radius: 8px;
}
.cpo-chas-edit-data-transfer-block-container .react-select__control{
  cursor: pointer;
}
.cpo-chas-edit-data-transfer-block-container .react-select__menu .react-select__option{
  margin: 0;
  cursor: pointer;
}
.cpo-chas-edit-data-transfer-block-container .react-select__menu .react-select__option:hover{
  background: var(--emoovLightBlue2);
}
.cpo-chas-edit-data-transfer-block-container .react-select__menu .react-select__option--is-focused{
  background: none;
}
.cpo-chas-edit-data-transfer-block-container .react-select__menu .react-select__option--is-selected:hover{
  background: var(--emoovBlue);
}
.cpo-chas-edit-data-transfer-block-container .react-select__menu .react-select__option--is-selected{
  background: var(--emoovBlue);
  color: #000;
}



/* ************************************************************ */
/* *SubscriptionCancelModal */
/* ************************************************************ */

.subscription-cancel-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.subscription-cancel-modal-container{
  position: relative;
  min-width: 500px;
  min-height: 330px;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px;
  z-index: 1000;
  display: grid;
  grid-template-rows: auto 1fr 50px;
}
.subscription-cancel-modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.subscription-cancel-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.subscription-cancel-modal-list-wrapper{
  max-height: 300px;
  overflow-y: scroll;
}
.subscription-cancel-modal-msg{
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}
.subscription-cancel-modal-item{
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 30px 0;
}
.subscription-cancel-modal-alert-msg{
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 15px 0;
  color: var(--inputBorder2);
}

.subscription-cancel-modal-container>div:last-child{
  display: flex;align-items: center;
  justify-content: space-between;
  
}
.subscription-cancel-modal-delete-button{
  width: 	100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  transition: .13s;
  background: var(--cardBtnRed);
  cursor: pointer;
}
.subscription-cancel-modal-delete-button:active{
  opacity: 0.3;
}
.subscription-cancel-modal-delete-button p{
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
  color: var(--textRed1);
}

.subscription-cancel-modal-delete-button.navigate{
  background: var(--buttonBlack);
  color: #fff;
}
.subscription-cancel-modal-delete-button.navigate>p{
  color: #fff;
}
.subscription-cancel-modal-err-msg{
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 15px 0;
  color: var(--textRed1);
}