/* Charger */
.cpo-charger-management-container{
  width: 100%;
  min-width: 1200px;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: calc(100vh - 190px);
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
}
.cpo-charger-management-inner{
  width: 100%;
  max-width: 1200px;
  height: 100%;
  position: relative;
}

/* Header(search items) */
.cpo-charger-management-search-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  position: relative;
  width: 100%;
}

.cpo-charger-management-keyword-search-container:hover{
  border-color: var(--emoovBlue);
}
.cpo-charger-management-keyword-search-container{
  height: 40px;
  width: 280px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--inputBorder);
  transition: .3s;
}
.cpo-charger-management-keyword-search-container input{
  all: unset;
  height: 100%;
  width: 100%;
  font-weight: 500;
  font-size: .9rem;
}
.cpo-charger-management-keyword-search-container input::placeholder{
  color: var(--inputBorder2);
}
.cpo-charger-management-keyword-search-container:has(input:focus){
  border-color: var(--emoovBlue);
}
.cpo-charger-management-keyword-search-container button{
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cpo-charger-management-keyword-search-container .search-icon{
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 5px;
  color: var(--inputBorder);
  cursor: pointer;
  transition: .3s;
}
.cpo-charger-management-keyword-search-container .search-icon:hover{
  color: var(--emoovBlue);
}

.cpo-charger-management-detail-search-button.open{
  transform: translateY(10px);
}
.cpo-charger-management-detail-search-button.open:active{
  transform: translateY(10px);
}
.cpo-charger-management-detail-search-button{
  height: 40px;
  padding: 0 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;  justify-content: center;
  background: #fff;
  color: var(--textGray2);
  cursor: pointer;
  transition: .3s;
  font-size: .9rem;
  border: 1px solid var(--buttonBorderGray2);
}
.cpo-charger-management-detail-search-button svg{
  transition: .3s;
}
.cpo-charger-management-detail-search-button svg.open{
  transform: rotate(180deg);
}
.cpo-charger-management-detail-search-button p{
  margin: 0;
  font-weight: 600;
  margin-right: 5px;
}
.cpo-charger-management-detail-search-button:active{
  transform: scale(95%);
}
.cpo-charger-management-detail-search-modal-container{
  position: absolute;
  width: calc(100% - 10px);
  height: 245px;
  background: #fff;
  border: 1px solid var(--buttonBorderGray2);
  bottom: -250px;
  border-radius: 5px 0 5px 5px;
  z-index: 10;
  opacity: 0;
  /* height: 0px; */
  transition: .3s;
  visibility: hidden;
}
.cpo-charger-management-detail-search-modal-container.open{
  opacity: 1;
  visibility: visible;
}

/* Category Items */
.cpo-charger-management-category-outer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  margin: 10px 0;
  width: 100%;
  position: relative;
}
.cpo-charger-management-category-container{
  width: 100%;
  height: fit-content;
  min-height: 100px;
  flex-wrap : wrap;
  overflow: visible;
  min-width: 550px;
}
.cpo-charger-management-category-container>p{
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 10px;
}
.cpo-charger-management-category-type-container{
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
}

.cpo-charger-management-category-type{
  position: relative;
  min-width: none;
  width: max-content;
  min-width: 140px;
  height: 45px;
  box-shadow: 1px 2px 5px rgba(4, 41, 55, 0.2);
  display: grid;
  align-items: center;
  grid-template-columns: 70px auto;
  border-radius: 8px;
  margin-right: 10px;
  /* border: 2px solid; */
}

.cpo-charger-management-category-type-title{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-charger-management-category-type-title>p{
  margin: 0;
  font-size: 1rem;
  color: var(--oxfordBlue2);
  color: #efefef;
  font-weight: 600;
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 10px 0 0 10px;
  line-height: 45px;
  text-align: center;
}
.cpo-charger-management-category-type:first-child .cpo-charger-management-category-type-title>p{
  background: var(--fast);
}
.cpo-charger-management-category-type:nth-child(2) .cpo-charger-management-category-type-title>p{
  background: var(--middle);
}
.cpo-charger-management-category-type:nth-child(3) .cpo-charger-management-category-type-title>p{
  background: var(--low);
}


.cpo-charger-management-category-type-content{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 100%;
  height: 100%;
}
.cpo-charger-management-category-type-content>p{
  margin: 0;
  color: var(--emoovGray);
  font-weight: 600;
  font-size: 1.2rem;
}






.cpo-charger-management-category-class-container{
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
}
.cpo-charger-management-category-class{
  width: 120px;
  height: 80px;
  border-radius: 8px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: end;
  justify-content: start;
  padding-left: 10px;
}
.cpo-charger-management-category-class:last-child{
  margin-right: 0px;
}
.cpo-charger-management-category-class-title{
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 0;
  font-size: .9rem;
  font-weight: 600;
  color: var(--textGray2);
}
.cpo-charger-management-category-class p:last-child{
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-weight: 700;
  color: var(--emoovBlue);
}
.cpo-charger-management-category-class p:last-child::after{
  content: "개";
  font-weight: 500;
  margin-left: 5px;
  font-size: .7rem;
  color: var(--textGray2);
}



.cpo-charger-management-charger-list-container.open{
  margin-top: 780px;
}
.cpo-charger-management-charger-list-container{
  width: 100%;
  height: fit-content;
  padding: 0 5px;
  margin-top: 50px;
  transition: .3s;
}
.cpo-charger-management-charger-list-header{
  display: flex;
  align-items: center;
  justify-content: space-between;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  height: 60px;
  margin-bottom: 10px;
  column-gap: 100px;
}

.cpo-charger-management-station-selector-container{
  height: 45px;
}

.cpo-charger-management-station-selector-container .react-select__menu{
  border-radius: 8px;
}
.cpo-charger-management-station-selector-container .react-select__control{
  cursor: pointer;
}
.cpo-charger-management-station-selector-container .react-select__menu .react-select__option{
  margin: 0;
  cursor: pointer;
}
.cpo-charger-management-station-selector-container .react-select__menu .react-select__option:hover{
  background: var(--emoovLightBlue2);
}
.cpo-charger-management-station-selector-container .react-select__menu .react-select__option--is-focused{
  background: none;
}
.cpo-charger-management-station-selector-container .react-select__menu .react-select__option--is-selected:hover{
  background: var(--emoovBlue);
}
.cpo-charger-management-station-selector-container .react-select__menu .react-select__option--is-selected{
  background: var(--emoovBlue);
  color: #000;
}










.cpo-charger-management-charger-list-button-container.open{
  opacity: 0;
}
.cpo-charger-management-charger-list-button-container{
  height: 50px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: end;
  transition: .3s;
}
.cpo-charger-management-charger-list-button-container>div:active{
  opacity: 0.3;
}
.cpo-charger-management-charger-list-button-container>div{
  height: 45px;
  padding: 0 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;  justify-content: center;
  background: var(--emoovBlue);
  cursor: pointer;
  transition: .3s;
  margin-left: 10px;
}
.cpo-charger-management-charger-list-button-container>div svg{
  transform: translate(-5px, 1px);
  color: #fff;
}
.cpo-charger-management-charger-list-button-container>div p{
  color: #fff;
  margin: 0;
  font-size: .9rem;
  font-weight: bold;
}
.cpo-charger-management-charger-list-button-container>div.delete{
  /* background: #feeded; */
  background: #fff;
  border: 1px solid var(--buttonBorderGray2);
}
.cpo-charger-management-charger-list-button-container>div.disabled{
  pointer-events: none;
  opacity: 0.3;
}
.cpo-charger-management-charger-list-button-container>div.delete p{
  /* color: #ff8d8d; */
  color: var(--textGray2);

}
.cpo-charger-management-charger-list-button-container>div.excel{
  background: #ddf8de;
}
.cpo-charger-management-charger-list-button-container>div.excel svg{
  color: #42ab48;
}
.cpo-charger-management-charger-list-button-container>div.excel p{
  color: #42ab48;
  margin: 0;
  font-size: .85rem;
  font-weight: bold;
}




.cpo-charger-management-charger-list{
  width: 100%;
  min-width: 850px;
}



/* .cpo-charger-management-charger-list-wrapper{
  width: 100%;
  min-width: 680px;
  height: 100%;
  display: grid;
  grid-template-rows: 40px 1fr;
} */

.cpo-charger-management-charger-list-column{
  border-top: 2px solid var(--inputBorder);
  border-bottom: 1px solid var(--inputBorder3);
  height: 50px;
  position: relative;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 1.5fr 0.5fr 2fr 2fr 0.7fr 0.5fr;

}
.cpo-charger-management-charger-list-column-item{
  height: 100%;
  width: max-content;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .cpo-charger-management-charger-list-column-item:nth-child(1){
  padding-left: 32.5px;  
}
.cpo-charger-management-charger-list-column-item:nth-child(2){
  width: 130px;
}
.cpo-charger-management-charger-list-column-item:nth-child(3){
  width: 130px;
}
.cpo-charger-management-charger-list-column-item:nth-child(4){
  width: 80px;
}
.cpo-charger-management-charger-list-column-item:nth-child(5){
  width: 110px;
}
.cpo-charger-management-charger-list-column-item:nth-child(6){
  width: 80px;
}
.cpo-charger-management-charger-list-column-item:nth-child(7){
  width: 80px;
} */
.cpo-charger-management-charger-list-column p{
  margin: 0;
  font-size: .8rem;
  color: var(--inputBorder2);
  font-weight: 400;
}
.cpo-charger-management-list-content{
  /* padding: 0 15px; */
  width: 100%;
  height: fit-content;
  min-height: 270px;
  max-height: 500px;
  background: #fff;
  overflow: scroll;
  scrollbar-width: none;
}


.cpo-charger-management-list-content-item.clicked{
  background: var(--itemActiveBlue);
}
.cpo-charger-management-list-content-item{
  width: 100%;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 1.5fr 0.5fr 2fr 2fr 0.7fr 0.5fr;
  cursor: pointer;
  transition: .13s;

}
.cpo-charger-management-list-content-item>div{
  /* border-right: 1.5px solid var(--emoovBlue30); */
  height: 100%;
  padding: 0 10px;
  display: flex;align-items: center;
}
.cpo-charger-management-list-content-item>div p{
  user-select:text;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow:ellipsis;
  word-break: break-all;
  margin: 0;
  font-size: .9rem;
  font-weight: 400;
  color: #555;
}
.cpo-charger-management-status-item.loading{
  border-color: #eee;
  background: #eee;
  height: 30px;
}
.cpo-charger-management-status-item{
  border: 2px solid var(--emoovBlue);
  border-radius: 100vh;
  width: 55px;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.cpo-charger-management-list-content-item .cpo-charger-management-status-item p{
  color: var(--emoovBlue);
  font-weight: bold;
}
.cpo-charger-management-list-content-item .cpo-charger-management-status-item.none{
  border-color: var(--textRed1);
}
.cpo-charger-management-list-content-item .cpo-charger-management-status-item.none p{
  color: var(--textRed1);
}

.cpo-charger-management-connector-badge{
  display: flex;align-items: center;justify-content: center;
  width: fit-content;
  height: 30px;
  border: 2px solid var(--emoovBlue);
  border-radius: 100vh;
  font-weight: 500;
  padding: 0 10px;
  margin-right: 5px;
  background: #fff;
}
.cpo-charger-management-list-content-item .cpo-charger-management-connector-badge>p{
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}










/* Modal */
.charger-detail-modal-container{
  position: absolute;
  width: calc(100%);
  height: 750px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: 100;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  
  border: 1px solid var(--inputBorder3);
  /* box-shadow: 0.5px 1px 3px var(--oxfordBlue1); */
  display: grid;
  grid-template-rows: 1.5fr 0.8fr 10fr;
}
.charger-detail-modal-container.open{
  opacity: 1;
  visibility: visible;
}
.charger-detail-modal-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 10px; */
  padding: 20px;
  position: relative;
  /* border-bottom: 2px solid var(--emoovBlue10); */
}
.charger-detail-modal-title-container{
  display: flex;
  align-items: center;
}
.charger-detail-modal-title{
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}
.charger-detail-modal-title-edit-button{
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 10px;
  transition: .13s;
  color: var(--emoovBlue);
}
.charger-detail-modal-title-edit-button:active{
  opacity: 0.3;
}

.charger-detail-modal-button-container{
  display: flex;
  align-items: center;
}
.charger-detail-modal-save-alert{
  color: var(--emoovBlue);
  font-weight: 600;
}
.charger-detail-modal-button-container>svg{
  color: var(--emoovBlue);
  font-weight: 600;
}
.charger-detail-modal-button-container>div{
  height: 40px;
  padding: 0 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;  justify-content: center;
  background: var(--emoovBlue);
  cursor: pointer;
  transition: .3s;
  margin-left: 10px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: .13s;
}
.charger-detail-modal-button-container>div:active{
  opacity: 0.3;
}
.charger-detail-modal-button-container>div.save.off{
  opacity: 0.3;
  pointer-events: none;
}
.charger-detail-modal-button-container>div.close{
  background: #fff;
  border: 1px solid var(--buttonBorderGray2);
}
.charger-detail-modal-button-container>div.close p{
  color: var(--textGray2);
}


.charger-detail-modal-left-button-container, .charger-detail-modal-right-button-container{
  position: absolute;
  width: 30px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--oxfordBlue1);
  transition: .3s;
  cursor: pointer;
  user-select: none;
}
.charger-detail-modal-left-button-container:active{
  transform: translateY(-50%) translateX(-12px);
}
.charger-detail-modal-right-button-container:active{
  transform: translateY(-50%) translateX(12px);
}

.charger-detail-modal-left-button-container path,.charger-detail-modal-right-button-container path{
  display: block;
  scale: 250%;
  transform: translate(-30%, -30%);
}
.charger-detail-modal-left-button-container svg,
.charger-detail-modal-right-button-container svg
{
  display: block;
  height: 80px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.charger-detail-modal-left-button-container{
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}
.charger-detail-modal-right-button-container{
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
}
.charger-detail-modal-save-button-container{
  position: absolute;
  width: 100px;
  height: 40px;
  background: var(--emoovBlue);
  border-radius: 0 0 5px 5px;
  right: 5px;
  top: 102.8%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;justify-content: center;
  box-shadow: 1px 2px 5px rgba(4, 41, 55, 0.2);
  cursor: pointer;
  transition: .3s;
}
.charger-detail-modal-save-button-container p{
  margin: 0;
  font-weight: 500;
  color: #fff;
}
.charger-detail-modal-save-button-container.off{
  opacity: 0.2;
  pointer-events: none;
}
.charger-detail-modal-save-button-container:active{
  opacity: 0.5;
}


.charger-detail-modal-container .tab-button-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 49px;
  position: relative;
  border-bottom: 2px solid var(--oxfordBlue1);
}
/* .tab-button:hover{
  background: var(--emoovBlue1);
} */
.charger-detail-modal-container .tab-button{
  border-radius: 7px 7px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}
.charger-detail-modal-container .tab-bar{
  position: absolute;
  width: 33.3333%;
  height: 3px;
  background: var(--emoovBlue);
  top: 46.5px;
  transition: .3s;
}
.charger-detail-modal-container .tab-bar.tab0{
  left: 0;
}
.charger-detail-modal-container .tab-bar.tab1{
  left: 33.3333%;
}
.charger-detail-modal-container .tab-bar.tab2{
  left: 66.6666%;
}
.charger-detail-modal-container .tab-button.selected p{
  color: var(--oxfordBlue);
  color: var(--emoovBlue);
}
.charger-detail-modal-container .tab-button p{
  margin: 0;
  color: var(--oxfordBlue1);
  font-weight: bold;
}
.charger-detail-modal-container .tab-content-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100%;
}
.charger-detail-modal-container .tab-content{
  width: 100%;
  height: 100%;
}
.charger-detail-modal-container .tab-content>div{
  width: 100%;
  height: 100%;
}



.charger-detail-tab .charger-input-title{
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  margin: 0;
  margin-bottom: 20px;
}
.charger-detail-tab .charger-input-title.no-margin{
  margin-bottom: 5px;
}

/* Tab1 */
.charger-detail-tab.tab1{
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  column-gap: 10px;
  row-gap: 10px;
}
.charger-detail-tab.tab1>div{
  padding: 30px;
  /* border: 1px solid #000; */
  background: #fff;
  box-shadow: 0px 3px 20px 0px #0000000F;
  border-radius: 5px;
}
.charger-detail-tab.tab1>div:first-child{
  display: grid;
  grid-template-rows: 1fr 2.5fr;
}
.charger-detail-tab.tab1>div:last-child{
  display: grid;
  grid-template-rows: 1fr 0.6fr 1fr;
}
.charger-detail-tab.tab1>div:last-child>:nth-child(2){
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.charger-detail-info-input-wrapper{
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  margin-bottom: 15px;
}
.charger-detail-info-input-wrapper>p{
  font-size: 1rem;
  font-weight: 600;
  color: var(--inputBorder2);
  margin: 0;
}
.charger-detail-info-input-wrapper.chargerTab1-2>p{
  color: var(--inputBorder2);
  font-weight: 600;

}

.charger-detail-info-input-container:hover{
  border: 1.5px solid var(--oxfordBlue);
}
.charger-detail-info-input-container.chargerTab1{
  /* margin-left: ;
  width: calc(100% - 150px); */
}
.charger-detail-info-input-container{
  height: 45px;
  width: 100%;
  /* padding: 0 10px; */
  display: flex;
  align-items: center;
  border: 1.5px solid var(--oxfordBlue1);
  border-radius: 10px;
  transition: .3s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.charger-detail-info-input-container input{
  all: unset;
  padding-left: 10px;
  height: 45px;
  font-weight: 400;
  font-size: 1rem;
  width: calc(100% - 20px);
}
.charger-detail-info-input-container.postFix{
  display: grid;
  grid-template-columns: 1fr 35px;
  align-items: center;
}
.charger-detail-info-input-container.postFix>p{
  font-size: .8rem;
  text-align: right;
  padding-right: 5px;
}

.charger-detail-tab-selector-wrapper{
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 20px;
}

.charger-detail-info-input-container.chas{
  padding-left: 10px;
}
.charger-detail-info-badge{
  width: fit-content;
  min-width: 60px;
  height: 35px;
  display: flex;align-items: center;justify-content: center;
  border-radius: 100vh;
  padding: 0 15px;
  border: 2px solid var(--emoovBlue);
  font-weight: 500;
}
.charger-detail-info-badge p{
  margin: 0;
}

.charger-detail-info-badge.disabled{
  border-color: var(--inputBorder3);
  color: var(--inputBorder2);
}




.charger-detail-tab-toggle-container{
  width: fit-content;
  height: 40px;
  margin-bottom: 10px;
  position: relative;
}

.charger-detail-tab.tab1>div:nth-child(3){
  padding-right: 30%;
  /* padding-top: 14%; */
}
.charger-detail-info-time-container{
  height: 40px;
  width: 96%;
  /* padding: 0 10px; */
  display: flex;
  align-items: center;
  border: 1.5px solid var(--oxfordBlue1);
  border-radius: 10px;
  transition: .3s;
  margin-bottom: 10px;
  /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.charger-detail-info-time-container form{
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
}
.charger-detail-info-time-container input.hidden{
  width: 0;margin: 0;
}
.charger-detail-info-time-container .measure-icon{
  position: absolute;
  right: 10px;
  color: var(--oxfordBlue2);
  font-size: 1rem;
}
.charger-detail-info-time-container input{
  all: unset;
  height: 40px;
  margin-left: 36%;
  width: 64%;
  font-weight: 500;
  font-size: .9rem;
  text-align: center;
  padding-right: 10px;
}
.charger-detail-info-time-container .inputMeasure{
  position: absolute;
  width: 36%;
  height: 60%;
  left: 0;
  border-right: 1px solid #ddd ;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--oxfordBlue2);
  font-size: .9rem;
  z-index: 9;
}



/* Tab2 */
.charger-detail-tab.tab2{
  display: grid;
  grid-template-rows: 1fr 1fr 0.5fr;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  column-gap: 20px;
  row-gap: 20px;
}
.charger-detail-tab.tab2>div{
  padding: 20px;
  /* border: 1px solid #000; */
  background: #fff;
  box-shadow: 0px 3px 20px 0px #0000000F;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
.charger-detail-tab-sub-title{
  font-weight: 600;
  color: var(--inputBorder2);
}
.charger-detail-tab-sub-title.no-margin{
  margin: 0;
}
.charger-detail-tab.tab2>div:first-child, .charger-detail-tab.tab2>div:last-child{
  grid-column: -1/1;
}
.charger-detail-tab.tab2>div:first-child{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 60px;
}
.charger-detail-info-flex-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.charger-detail-info-flex-wrapper.oneTwo{
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.charger-detail-info-container.chargerTab2.longer{
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
}
.charger-detail-info-container.chargerTab2>.charger-detail-info-connectorType{
  display: flex;
  flex-wrap: wrap;
}
.cpo-charger-detail-info-connector-badge{
  display: flex;align-items: center;justify-content: center;
  width: fit-content;
  height: 28px;
  /* margin-bottom: 5px; */
  border: 2px solid var(--emoovBlue);
  border-radius: 100vh;
  font-weight: 500;
  padding: 0 5px;
  margin-right: 5px;
  background: #fff;
}
.cpo-charger-detail-info-connector-badge>p{
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}

.charger-detail-info-container.chargerTab2>.charger-detail-info-connectorType>p{
  margin: 0;
  font-weight: 600;
}

.charger-model-info-grid-oneColumn{
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 10px;
}
.charger-model-info-grid-oneColumn3R{
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 10px;
}
.charger-model-info-grid{
  margin-top: 25px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 30px;
}

.charger-detail-info-container.chargerTab2{
  display: grid;
  grid-template-columns: 60px 1fr;
  align-items: center;
}
.charger-detail-info-container.chargerTab2>div{
  display: flex;
  align-items: center;
}
.charger-detail-info-container.chargerTab2>div>p:first-child{
  font-weight: 600;
}
.charger-detail-info-container.chargerTab2>div>p.postFix{
  margin-left: 5px;
}

.charger-detail-tab.tab2>div:last-child .charger-detail-info-input-wrapper>p{
  font-weight: 600;
  color: var(--inputBorder2);
}
.charger-detail-info-textarea-container{
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-rows: auto 1fr;
}
.charger-detail-info-textarea-container>textarea{
  all: unset;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.charger-detail-tab.tab2 .charger-input-title{
  color: var(--emoovBlue);
}



/* Tab3 */
.charger-detail-tab.tab3{
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px;
  column-gap: 10px;
  position: relative;
}
.charger-detail-tab.tab3>p{
  position: absolute;
  top: 10px;
  left: 20px;
}
.charger-detail-tab.tab3>div>p{
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--oxfordBlue2);
}
.charger-detail-tab.tab3>div{
  padding: 30px;
  /* border: 1px solid #000; */
  box-shadow: 0px 3px 20px 0px #0000000F;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.charger-qrcode-container{
  width: 100%;
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.charger-qrcode-wrapper{
  width: 300px;
  height: 330px;
  border-radius: 5px;
  box-shadow: 1px 2px 5px rgba(4, 41, 55, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 10px;
  overflow: hidden;
}
.charger-qrcode-wrapper-info{
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--emoovBlue);
  color: #fff;
}
.charger-qrcode{
  width: 90%;
  height: 90%;
  border-radius: 5px;
  display: flex;
  align-items: center;justify-content: center;
}
.charger-qrcode p{
  color: #000;
}
.qrcode-download-button-container{
  display: flex;align-items: center;justify-content: center;
}
.qrcode-download-button{
  width: fit-content;
  height: 40px;
  padding: 0 20px;
  display: flex; align-items: center; justify-content: center;
  background: #3D3D3D;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  cursor: pointer;
  transition: .3s;
}
.qrcode-download-button:active{
  opacity: 0.5;
}
.qrcode-download-button p{
  margin: 0;
  color: #fff;
}


/* Charger Enroll Modal */
.cpo-charger-enroll-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-charger-enroll-modal-container{
  position: relative;
  width: 500px;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px;
  z-index: 1000;
}
.cpo-charger-enroll-modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.cpo-charger-enroll-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-charger-enroll-modal-alert-msg{
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: var(--textRed1);
}
.cpo-charger-enroll-modal-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}
.cpo-charger-enroll-modal-grid>div{
  width: 100%;
}
.cpo-charger-enroll-modal-grid>div>p:first-child{
  color: var(--inputBorder2);
}
.cpo-charger-enroll-modal-grid>div>p:last-child{
  font-size: 1.5rem;
  font-weight: 600;
}
.cpo-charger-enroll-modal-input-container{
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}
.cpo-charger-enroll-modal-input-container input{
  all: unset;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  font-size: .9rem;
  transition: .3s;
  padding: 0 10px;
  border: 1.5px solid var(--textGray1);
  transition: .3s;
}
.cpo-charger-enroll-modal-input-container input:focus, .cpo-charger-enroll-modal-input-container input:hover{
  border: 1.5px solid var(--emoovBlue);
}

.cpo-charger-enroll-modal-container>div:last-child{
  display: flex;align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}
.cpo-charger-modal-enroll-button{
  width: 	fit-content;
  height: 40px;
  padding: 0 20px;
  background: var(--emoovBlue);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  cursor: pointer;
  transition: .13s;
}
.cpo-charger-modal-enroll-button.subscription{
  background: var(--buttonBlack);
}
.cpo-charger-modal-enroll-button:active{
  opacity: 0.3;
}
.cpo-charger-modal-enroll-button p{
  margin: 0;
  font-weight: 500;
  font-size: .9rem;
}
.cpo-charger-modal-enroll-button.none{
  opacity: .3;
  pointer-events: none;
}




/* Charger Enroll Modal */
.cpo-charger-delete-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-charger-delete-modal-container{
  position: relative;
  min-width: 500px;
  min-height: 330px;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px;
  z-index: 1000;
  display: grid;
  grid-template-rows: auto 1fr 50px;
}
.cpo-charger-delete-modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.cpo-charger-delete-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-charger-delete-modal-list-wrapper{
  max-height: 300px;
  overflow-y: scroll;
}
.cpo-charger-delete-modal-msg{
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}
.cpo-charger-delete-modal-item{
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 15px 0;
  padding-left: 20px;
}

.cpo-charger-delete-modal-container>div:last-child{
  display: flex;align-items: center;
  justify-content: space-between;
  
}
.cpo-charger-modal-delete-button{
  width: 	100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  transition: .13s;
  background: var(--cardBtnRed);
  cursor: pointer;
}
.cpo-charger-modal-delete-button:active{
  opacity: 0.3;
}
.cpo-charger-modal-delete-button p{
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
  color: var(--textRed1);
}