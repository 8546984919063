.cpo-member-info-update-container{
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 190px);
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 70px; */
}

.cpo-member-info-update-grid-container{
  position: relative;
  min-width: 1000px;
  max-width: 1000px;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 1.6fr;
  /* column-gap: 100px; */
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px;
}

.cpo-member-info-update-front{
  display: flex;
  align-items: center;
  justify-content: center;
  background:var(--emoovBlue);
  overflow: hidden;
  /* background: var(--emoovBlue10); */
  /* background-image: linear-gradient(to right, var(--emoovBlue10) 0%, #79CBCA  11%, #77A1D3  100%); */
  /* background-size: 200% auto; */
  position: relative;
  border-radius: 10px 0 0 10px;
}
.member-info-update-background{
  width: 100%;
  
  content: var(--fastEnroll);
}
.member-info-update-logo{
  width: 100%;
  top: 0%;
  position: absolute;
  content: var(--logo_tran4);
}
.member-info-update-back-wrapper{
  position: relative;
}
.cpo-member-info-update-button-container{
  display: flex;align-items: center;
  justify-content: end;
  padding: 0 50px;
  margin-bottom: 30px;
}
.cpo-member-info-update-button{
  height: 45px;
  padding: 0 15px;
  border-radius: 8px;
  transition: .13s;
  cursor: pointer;
  display: flex;
  align-items: center;  justify-content: center;
  background: #3d3d3d;
  color: #fff; 
  position: relative;

}

.cpo-member-info-update-button p{
  font-weight: bold;
  margin: 0;
}

.cpo-member-info-update-button:active{
  opacity: 0.2;
}
.cpo-member-info-update-button.none{
  pointer-events: none;
  opacity: 0.2;
}


.cpo-member-info-update-input-list-container{
  padding: 30px 50px;
}





.cpo-member-info-update-input-outer{
  margin-bottom: 50px;
}
.cpo-member-info-update-input-outer>p:first-child{
  font-weight: 600;
  margin: 0;
  margin-bottom: 15px;
}

.cpo-member-update-input-divide-wrapper{
  display: grid;
  grid-template-columns: 1fr 120px;
  column-gap: 15px;
}

.cpo-member-info-update-input-container{
  width: 100%;
  height: 55px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  padding: 0 10px;
  position: relative;
}
.cpo-member-info-update-input-container input{
  all: unset;
  width: 100%;
  height: 55px;
}
.cpo-member-info-update-input-button{
  width: 100%;
  height: 55px;
  background: var(--emoovBlue);
  border-radius: 8px;
  display: flex;
  align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
  color: #fff;
  font-weight: 500;
}
.cpo-member-info-update-input-button:active{
  opacity: 0.3;
}

.cpo-member-info-update-msg{
  margin: 0;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 0.85rem;
  color: var(--inputBorder);
}
.cpo-member-info-update-msg.alert{
  color: var(--textRed);
}