.main-template-container{
  min-width: 100vw;
  min-height: 100vh;
  background: var(--bgWhite);
  position: relative;
}
.main-template-header{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background: #fff;
  position: relative;
}
.main-template-header .nav-logo-container{
  width: 200px;
}
.main-template-header-inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
}

.nav-function-container{
  width: calc(100vw - 200px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-menu-container{
  width: fit-content;
  display: flex;
  align-items: center;
}
.nav-menu-button{
  width: fit-content;
  padding: 0 15px;
  cursor: pointer;
  transition: .13s;
  position: relative;
  font-weight: 500;
}
.nav-menu-button.selected::after{
  position: absolute;
  content: "";
  bottom: -15px;left: 0;
  width: 100%;
  height: 3px;
  background: var(--emoovBlue);
}
.nav-menu-button:hover{
  color: var(--emoovBlue);
}

.nav-menu-modal-container{
  position: absolute;
  box-shadow: 2px 5px 5px 0px #00000026;
  
  width: 100%;
  height: fit-content;
  min-height: 200px;
  bottom: 1px;
  left: 0;
  transform: translateY(100%);
  background: #fff;
  padding: 15px;
  z-index: 20;
  display: flex;
  justify-content: center;

  padding-left: 200px;
}
.nav-menu-modal-container>div{
  max-width: 1200px;
  width: 100%;
}
.nav-menu-modal-title{
  margin: 0;
  margin-bottom: 20px;
  font-size: 1.1rem;
  font-weight: 600;
}
.nav-products-menu-category{
  margin-bottom: 10px;
  cursor: pointer;
  transition: .13s;
  padding: 20px 15px;
  border-radius: 8px;
}
.nav-products-menu-category.selected{
  background: #E9F8FB;

}
.nav-products-menu-category:hover{
  background: #E9F8FB;
}
.nav-products-menu-category>p{
  margin: 0;
}
.nav-products-menu-category>p:first-child{
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.nav-products-menu-category>p:last-child{
  font-size: .9rem;
  color: var(--inputBorder2);
}


.nav-sign-in-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-sign-in-container>div{
  width: fit-content;
  height: 50px;
  margin-right: 50px;
  display: flex;align-items: center;
  justify-content: center;
  cursor: pointer;
}
.nav-sign-in-container>div p{
  transition: .3s;
}
.nav-sign-in-container>div:hover p{
  color: var(--emoovBlue);
}
.main-template-container section{
  width: 100%;
  min-height: calc(100vh - 150px);
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

footer-image{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  background: #222;
}
footer-image>img{
  width: 100%;
  min-width: 1200px;
  max-width: 1600px;
}
footer-image>p{
  display: inline-block;
  color: #777;
  font-size: .9rem;
  font-weight: 600;
  width: 0;
  transform: translateX(-150px);
  padding-bottom: 10px;
}





/* Login */
.login-page-container{
  min-width: 900px;
  height: fit-content;
  padding: 80px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.login-page-wrapper{
  width: 450px;
  height: fit-content;
}
.login-page-wrapper title{
  display: inline-block;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}
.login-input-title{
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: .9rem;
  font-weight: 600;
}
.login-input-validation{
  bottom: -15px;
  margin: 0;
  margin-top: 5px;
  margin-left: 10px;
  font-size: .9rem;
  color: var(--textRed);
}
.login-input-container{
  width: 100%;
  height: 55px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder);
  /* padding: 0 20px; */
  transition: .13s;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.login-input-container:has(input:focus){
  border: 1.5px solid var(--emoovBlue);
}
.login-input-container input{
  all: unset;
  width: calc(100% - 40px);
  padding: 0 20px;
  height: 100%;
  pointer-events: text;
  /* background: #000; */
}
.login-input-container.password input{
  padding-right: 40px;
}
.login-input-container input::placeholder{
  color: var(--placeholderGray);
}
.login-input-container svg{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: var(--textGray);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.login-input-container path{
  display: block;
  height: 24px;
}
.login-function-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 20px;
}

/* checked */
.checkbox_label input:checked + .checkbox_icon.login::before{
  background: var(--check) var(--emoovBlue) no-repeat center;
  background-size: 60% auto;
  border:none;
}
.login-checkbox-container{
  display: flex;
  align-items: center;
}
.login-checkbox-container p{
  margin: 0;
  margin-left: 5px;
  font-size: .9rem;
}

.login-found-account-container{
  border-bottom: 1px solid var(--inputBorder);
  cursor: pointer;
  transition: .13s;
  position: relative;
}
.login-found-account-container p{
  margin: 0;
  font-size: .9rem;
  color: var(--textGray);
  font-weight: 500;
}
.login-found-account-container:active{
  opacity: 0.3;
}

.login-button {
  display: flex;
  align-items: center;justify-content: center;
  border-radius: 8px;
  background: var(--emoovBlue);
  width: 100%;
  height: 55px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  transition: .13s;
  position: relative;
  
}
.login-button.none{
  opacity: 0.3;
  pointer-events: none;
}
.login-button.simple{
  margin-top: 30px;
}
.login-button:active{
  opacity: 0.3;
}
.sign-up-info-container{
  display: flex;align-items: center;justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;
}
.sign-up-info-container p{
  margin: 0;
  font-size: 0.9em;
  font-weight: 600;
}
.sign-up-info-container p:first-child{
  color: var(--textGray);
  margin-right: 10px;
}
.sign-up-info-container p:last-child{
  color: var(--emoovBlue);
  cursor: pointer;
  transition: .13s;
}
.sign-up-info-container p:last-child:active{
  opacity: 0.3;
}
.login-go-to-simple-button{
  display: flex;
  align-items: center;justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--emoovBlue);
  background: #fff;
  width: 100%;
  height: 55px;
  font-weight: 600;
  color: var(--emoovBlue);
  cursor: pointer;
  transition: .13s;
}
.login-go-to-simple-button:active{
  opacity: 0.3;
}








.sign-up-page-container{
  min-width: 900px;
  min-height: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-top: 30px;
  margin-bottom: 80px;
}
.sign-up-page-wrapper{
  /* width: 450px; */
  height: fit-content;
  margin-top: 80px;
}
.sign-up-page-wrapper title{
  display: inline-block;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}
.sign-up-step-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  margin: 20px 0 30px;
}
.sign-up-step-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  height: 100%;
  position: relative;
}
.sign-up-step{
  width: 45px;
  height: 45px;
  border-radius: 45px;
  border: 1px solid var(--stepBorder);
  color: var(--stepBorder);
  display: flex;align-items: center;justify-content: center;
  background: #fff;
  border: 1px solid var(--stepBorder);
  z-index: 2;
  position: relative;
  transition: .3s;
}
.sign-up-step p{
  margin: 0;
  font-weight: 600;
  font-size: 1.2rem;
}
.sign-up-step .sign-up-step-info{
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: max-content;
  font-size: .9rem;
  font-weight: 400;
  color: #676767;
}
.sign-up-step.done{
  background: var(--emoovLightBlue);
  border: 1px solid var(--emoovLightBlue);
  color: #fff;
}
.sign-up-step.current{
  background: var(--emoovBlue);
  border: 1px solid var(--emoovBlue);
  color: #fff;
}
.sign-up-dash{
  position: absolute;
  width: 100%;
  height: 1px;
  border: 1px solid var(--stepBorder);
  z-index: 1;
}


.sign-up-tab-container.sign-up-tab1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;
  margin-top: 150px;
}
.sign-up-category-button{
  width: 300px;
  height: 150px;
  border-radius: 8px;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.sign-up-category-button:active{
  opacity: 0.3;
}
.sign-up-category-button.normal{
  border-color: var(--buttonBorderGray);
  background: #fff;
}
.sign-up-category-button.normal svg{
  color: #656565;
  font-size: 1.5rem;
}
.sign-up-category-button.normal .sign-up-category-button-title{
  color: #000;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
}
.sign-up-category-button.normal .sign-up-category-button-desc{
  color: #9D9D9D;
  margin: 0;
  font-size: .8rem;
}
.sign-up-category-button.business{
  border-color: var(--emoovBlue);
  background: var(--emoovBlue);
  color: #fff;
}
.sign-up-category-button.business svg{
  font-size: 1.5rem;
}
.sign-up-category-button.business .sign-up-category-button-title{
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
}
.sign-up-category-button.business .sign-up-category-button-desc{
  margin: 0;
  font-size: 0.8rem;
}


.sign-up-tab-container.sign-up-tab2{
  margin-top: 100px;
  min-height: 600px;
  margin-bottom: 30px;
}

.sign-up-tab-title{
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
}
.sign-up-tab-title.admin{
  margin-top: 100px;
}

.sign-up-input-row{
  width: max-content;
  min-width: 700px;
  display: grid;
  grid-template-columns: 130px 1fr auto;
  margin-top: 15px;
  margin-bottom: 40px;
  position: relative;
}
.sign-up-input-title{
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: .9rem;
  font-weight: 600;
}
.sign-up-input-container{
  width: 100%;
  height: 55px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder);
  /* padding: 0 20px; */
  transition: .13s;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.sign-up-input-container:has(input:focus){
  border: 1px solid var(--emoovBlue);
}
.sign-up-input-container.invalid{
  border: 1px solid rgb(255, 82, 82);
}
.sign-up-input-container input{
  all: unset;
  width: calc(100% - 40px);
  padding: 0 20px;
  height: 100%;
  pointer-events: text;
  /* background: #000; */
}
.sign-up-input-container.password input{
  padding-right: 40px;
}
.sign-up-input-container input::placeholder{
  color: var(--placeholderGray);
}
.sign-up-input-container svg{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: var(--textGray);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sign-up-input-container path{
  display: block;
  height: 24px;
}




.sign-up-duplication-check-button{
  width: 100px;
  height: 55px;
  border-radius: 8px;
  background: var(--emoovBlue);
  color: #fff;
  margin-left: 20px;
  display: flex;
  align-items: center;justify-content: center;
  font-weight: 600;
  cursor: pointer;
  transition: .13s;
}
.sign-up-duplication-check-button.none{
  opacity: 0.3;
  pointer-events: none;
}
.sign-up-duplication-check-button:active{
  opacity: 0.3;
}
.sign-up-validation-msg{
  margin: 0;
  position: absolute;
  bottom: -1.2rem;
  left: 150px;
  font-size: .8rem;
  color: var(--textGray);
}
.sign-up-validation-msg.alert{
  color: rgb(255, 82, 82)
}
.sign-up-validation-msg.able{
  color: var(--emoovBlue);
}

.sign-up-go-to-container{
  width: 100%;
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
  position: relative;
}
.sign-up-go-to-container>p{
  position: absolute;
  right: 100px;
  top: -30px;
  margin: 0;
  color: var(--textRed1);
}
.sign-up-go-to-button{
  width: 200px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.sign-up-go-to-button:active{
  opacity: 0.3;
}
.sign-up-go-to-button p{
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
}
.sign-up-go-to-button.back{
  background: #B5B5B5;
}
.sign-up-go-to-button.request{
  background: var(--emoovBlue);
}




.email-verification-button-container{
  display: flex;align-items: center;justify-content: end;
  margin-top: 50px;
}
.email-verification-button-container>p{
  margin: 0;
  margin-right: 20px;
  color: var(--textGreen);
}
.email-verification-button-container>p.error{
  color: var(--textRed);
}
.email-verification-resend-button{
  border-radius: 8px;
  width: 80px;
  height: 50px;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 600;
  display: flex;align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.email-verification-resend-button:active{
  opacity: 0.3;
}
.email-verification-resend-button p{
  margin: 0;
}