.cpo-fast-enroll-container{
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 190px);
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 70px; */
  /* overflow: hidden; */
}
.cpo-fast-enroll-grid-container.tab3{
  background-image: linear-gradient(to right, var(--emoovBlue10) 0%, #79CBCA  11%, #77A1D3  100%);
  background-size: 200% auto;
}

.cpo-fast-enroll-grid-container.tab3 p{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 2.3rem;
  color: #fff;
  font-weight: 600;
}
.cpo-fast-enroll-grid-container{
  position: relative;
  min-width: 1000px;
  max-width: 1000px;
  height: 850px;
  display: grid;
  grid-template-columns: 1fr 1.6fr;
  /* column-gap: 100px; */
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px;
}

.cpo-fast-enroll-front{
  display: flex;
  align-items: center;
  justify-content: center;
  background:var(--emoovBlue);
  overflow: hidden;
  /* background: var(--emoovBlue10); */
  /* background-image: linear-gradient(to right, var(--emoovBlue10) 0%, #79CBCA  11%, #77A1D3  100%); */
  /* background-size: 200% auto; */
  position: relative;
  border-radius: 8px 0 0 8px;
}
.fast-enroll-background{
  width: 100%;
  content: var(--fastEnroll);
  position: absolute;
  bottom: 0;
}
.fast-enroll-logo{
  width: 100%;
  top: 0%;
  position: absolute;
  content: var(--logo_tran4);
}
.fast-enroll-back-wrapper{
  position: relative;
  overflow: hidden;
}

.cpo-fast-enroll-button{
  width: max-content;
  height: max-content;
  height: 38px;
  padding: 10px 10px 10px 15px;
  border-radius: 5px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  transition: .13s;
  cursor: pointer;
  display: flex;
  align-items: center;  justify-content: center;
  background: #3d3d3d;
  color: #fff; 
}
.cpo-fast-enroll-button.left{
  right: 110px;
  padding: 10px 15px 10px 10px;
}
.cpo-fast-enroll-msg{
  right: 300px;
  position: absolute;
  bottom: 35px;
  color: var(--textRed);
}
.cpo-fast-enroll-button p{
  font-weight: bold;
  margin: 0;
}
.cpo-fast-enroll-button:hover{
}
.cpo-fast-enroll-button:active{
  opacity: 0.2;
}
.cpo-fast-enroll-button.none{
  pointer-events: none;
  opacity: 0.2;
}

.cpo-fast-enroll-back.initial{
  padding: 30px;
}

.cpo-fast-enroll-back-initial-title{
  display: flex;
  align-items: center;
}
.cpo-fast-enroll-back-initial-title>p{
  margin: 0;
}
.cpo-fast-enroll-back-initial-title>p:nth-child(2){
  color: var(--emoovBlue);
  margin-left: 5px;
  font-weight: 600;
}

.cpo-fast-enroll-category-button{
  width: 100%;
  height: 100px;
  border: 1.5px solid var(--inputBorder3);
  margin: 20px 0;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 0 20px 15px;
  transition: .13s;
}

.cpo-fast-enroll-category-select-outer{
  width: 30px;
  height: 30px;
  border: 2px solid #ddd;
  border-radius: 100%;
  display: flex;align-items: center;justify-content: center;
  transition: .13s;
}
.cpo-fast-enroll-category-select-inner{
  width: 18px;
  height: 18px;
  background: #ddd;
  border-radius: 100%;
  transition: .13s;
}
.cpo-fast-enroll-category-button.selected{
  border-color: var(--emoovBlue);
}
.cpo-fast-enroll-category-button.selected .cpo-fast-enroll-category-select-outer{
  border-color: var(--emoovBlue);
}
.cpo-fast-enroll-category-button.selected .cpo-fast-enroll-category-select-inner{
  background: var(--emoovBlue);
}

.cpo-fast-enroll-category-content-container{
  margin-left: 20px;
}
.cpo-fast-enroll-category-content-container p{
  margin: 0;
}
.cpo-fast-enroll-category-content-container p:first-child{
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.cpo-fast-enroll-category-content-container p:last-child{
  color: var(--textGray);
  font-weight: 500;
}


.cpo-fast-enroll-step-container{
  display: flex;
  align-items: center;
  margin: 30px 0 0 30px;
}
.cpo-fast-enroll-step{
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: var(--inputBorder3);
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;justify-content: center;
  transition: .3s;
}
.cpo-fast-enroll-step.current{
  background: var(--emoovBlue);
}
.cpo-fast-enroll-step-container>p{
  margin: 0;
  opacity: 0;
  width: 0;
  height: 0;
}
.cpo-fast-enroll-step-container>p.current{
  opacity: 1;
  width: fit-content;
  height: fit-content;
  margin-left: 10px;
  transition: .3s;
}
.cpo-fast-enroll-step-container>svg{
  margin: 0 10px;
  color: var(--inputBorder3);
  transition: .3s;
}
.cpo-fast-enroll-step-container>svg.current{
  color: #000;
}





.cpo-fast-enroll-input-list-container{
  padding: 30px;
}
.cpo-fast-enroll-input-list-container>p{
  font-weight: 600;
}

.fast-enroll-input-container{
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
}
.fast-enroll-input-container.name{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.fast-enroll-station-name-validation{
  width: 130px;
  height: 100%;
  margin-left: 10px;
  border-radius: 8px;
  background: var(--emoovBlue);
  color: #fff;
  display: flex;
  align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.fast-enroll-station-name-validation.disable{
  opacity: 0.3;
  pointer-events: none;
}
.fast-enroll-station-name-validation:active{
  opacity: 0.3;
}
.fast-enroll-station-name-validation p{
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

.fast-enroll-input-validation-msg{
  position: absolute;
  margin: 0;
  left: 15px;
  bottom: -1.5rem;
  font-size: .9rem;
  font-weight: 500;
  color: #5d5d5d;
}
.fast-enroll-input-validation-msg.alert{
  color: var(--textRed);
}
.fast-enroll-input-validation-msg.able{
  color: var(--emoovBlue);
}

.fast-enroll-input-container input{
  all: unset;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  font-size: .9rem;
  transition: .3s;
  padding: 0 10px;
  border: 1.5px solid var(--inputBorder);

}
.fast-enroll-input-container input:focus, .fast-enroll-input-container input:hover{
  border: 1.5px solid var(--emoovBlue);
}


.fast-enroll-input-container.occp{
  position: relative;
}
.cpo-occp-down-icon{
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: var(--emoovGray);
}
.fast-enroll-selector{
  width: 100%;
  height: 45px;
  border-radius: 10px;
  font-weight: bold;
  font-size: .9rem;
  transition: .3s;
  padding: 0 10px;
  border: 1.5px solid var(--inputBorder);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.fast-enroll-selector:hover{
  border: 1.5px solid var(--emoovBlue);
}
.fast-enroll-selector.active{
  border: 1.5px solid var(--emoovBlue);
}
.fast-enroll-selector p{
  margin: 0;
}
.fast-enroll-selector p.none{
  color: #aaa;
}
.fast-enroll-selector-item-list{
  position: absolute;
  top: 50px;
  width: 100%;
  border: 2px solid var(--emoovBlue10);
  border-radius: 10px;
  transition: height .3s;
  overflow: hidden;
  box-shadow: 2px 2px 5px rgba(72, 71, 71, 0.2);
  z-index: 10;
}
.fast-enroll-selector-item-list.none{
  height: 0;
  border: none;
  transition: height .3s;
}
.fast-enroll-selector-item{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  transition: .3s;
  cursor: pointer;
  background: #fffdfd;
  font-weight: bold;
  position: relative;
}
.fast-enroll-selector-item.none{
  height: 0;
  overflow: hidden;
  transition: 0s;
}
.fast-enroll-selector-item:hover{
  background: var(--hoverGray3);
}

.fast-enroll-selector-item p{
  margin: 0;
  font-size: .85rem;
}
.cpo-occp-check-icon{
  position: absolute;
  right: 10px;
  color: var(--emoovBlue);
}




.cpo-fast-enroll-done-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
}
.cpo-fast-enroll-done-container>p{
  font-size: 1.3rem;
  font-weight: 600;
}
.cpo-fast-enroll-done-container>div{
  width: fit-content;
  background: #3d3d3d;
  border-radius: 8px;
  padding: 13px 20px;
  cursor: pointer;
  transition: .13s;
}
.cpo-fast-enroll-done-container>div:active{
  opacity: 0.3;
}
.cpo-fast-enroll-done-container>div>p{
  margin: 0;
  color: #fff;
  font-weight: 600;
}


.cpo-fast-enroll-input-check-container{
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  background:#f3f3f3;
  column-gap: 5px;
}
.cpo-fast-enroll-input-check-container.station{
  margin-bottom: 30px;
  display: block;
}
.cpo-fast-enroll-input-check-container>div>div:nth-child(2n-1){
  height: 40px;
  background: var(--emoovLightBlue);
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-weight: 500;
}
.cpo-fast-enroll-input-check-container>div>div:nth-child(2n){
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 1.1rem;
  background: #fff;
}
.cpo-fast-enroll-input-list-container .cpo-fast-enroll-input-check-title{
  color: var(--inputBorder2);
}
.cpo-fast-enroll-input-list-container .cpo-fast-enroll-input-check-msg{
  font-size: 1.1rem;
  margin-bottom: 50px;
  font-weight: 600;
}
.cpo-fast-enroll-input-list-container .cpo-fast-enroll-input-check-msg>svg{
  color: var(--emoovBlue);
  margin-right: 5px;
  margin-bottom: 0;
}

.fast-enroll-input-title{

}
.fast-enroll-input-title color{
  display: inline;
  color: var(--inputBorder);
  font-weight: 500;
}




.cpo-fast-enroll-sub-title{
  font-size: 1rem;
  /* font-weight: 600; */
  margin: 0;
  margin-bottom: 10px;
  font-variant: var(--inputBorder2);
}
.cpo-fast-enroll-subscription-info.empty{
  color: var(--emoovBlue10);
}
.cpo-fast-enroll-subscription-info{
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  margin-right: 10px;
  color: var(--emoovBlue);
  margin-bottom: 10px;
}
.cpo-fast-enroll-charger-limit-info-container{
  display: flex;
  align-items: center;
}
.cpo-fast-enroll-charger-limit-info-container>p{
  margin: 0;
  margin-bottom: 20px;
  margin-right: 5px;
  font-size: 1.2rem;
  font-weight: 600;
}
.cpo-fast-enroll-charger-limit-info-container>p.limited{
  color: var(--textRed1);
}


.cpo-fast-enroll-charger-model-list-container{
  /* display: grid;
  grid-template-rows: 50px auto; */
  width: 100%;
  border-top: 2px solid var(--inputBorder2);
  margin-top: 15px;
  position: relative;
}

.cpo-fast-enroll-charger-model-list-column{
  display: grid;
  grid-template-columns: 0.2fr 0.6fr 1.4fr 0.3fr 0.5fr;
  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  font-size: .9rem;
  color: var(--inputBorder2);
}
.cpo-fast-enroll-charger-model-list-item-container{
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  scrollbar-gutter: stable;
  /* margin-bottom: -50px; */
}
.cpo-fast-enroll-charger-model-list-item-container.selected{
  height: 250px;
}



.cpo-fast-enroll-charger-model-list-content-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 0.2fr 0.6fr 1.4fr 0.3fr 0.5fr 1rem;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
  cursor: pointer;
}
.cpo-fast-enroll-charger-model-list-content-item.checkMode{
  grid-template-columns: 0.2fr 0.6fr 1.4fr 0.3fr 0.5fr 1rem;

}
.cpo-fast-enroll-charger-model-list-content-item.checked{
  background: var(--emoovLightBlue2);
}

.cpo-fast-enroll-charger-model-list-content-item p{
  margin: 0;
}

.cpo-fast-enroll-charger-model-list-content-item>div{
  display: flex;align-items: center;
}
.cpo-fast-enroll-charger-model-list-content-item>div>svg{
  margin-left: 5px;
  transition: .13s;
}

.cpo-fast-enroll-charger-model-list-content-item-content{
  background: #F7F7F7;
  visibility: hidden;
  max-height: 0;
  /* padding: 0 20px; */
}
.cpo-fast-enroll-charger-model-list-content-item-content>div{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
}
.cpo-fast-enroll-charger-model-list-content-item-content>div:last-child{
  margin-bottom: 15px;
}
.cpo-fast-enroll-charger-model-list-content-item-content>div>p{
  margin: 0;
  margin-right: 10px;
  color: var(--emoovBlue);
  font-weight: 500;
}

.cpo-fast-enroll-charger-model-list-content-item-container.open .cpo-fast-enroll-charger-model-list-content-item-content{
  visibility: visible;
  max-height: none;
  border-bottom: 1px solid var(--inputBorder3);
  padding-top: 15px;
}
.cpo-fast-enroll-charger-model-list-content-item-container.open .cpo-fast-enroll-charger-model-list-content-item>div>svg{
  transform: rotate(-180deg);
}

.cpo-charger-model-connector-badge{
  display: flex;align-items: center;justify-content: center;
  width: fit-content;
  height: 30px;
  border: 2px solid var(--emoovBlue);
  border-radius: 100vh;
  font-weight: 500;
  padding: 0 5px;
  margin-right: 5px;
  background: #fff;
}
.cpo-charger-model-connector-badge>p{
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}


.cpo-fast-enroll-charger-model-list-content-item-content>div.cpo-fast-enroll-charger-model-list-connector-list-container{
  display: block;
  padding: 0;
  width: 100%;
}

.cpo-fast-enroll-charger-model-list-connector-list-container{
  width: 100%;
}
.cpo-fast-enroll-charger-model-list-connector-list-item-column{
  width: calc(100% - 30px);
  height: 50px;
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  margin: 0 15px;
  column-gap: 10px;
  font-size: .9rem;
}
.cpo-fast-enroll-charger-model-list-connector-list-item-column>div:last-child{
  text-align: right;
}
.cpo-fast-enroll-charger-model-list-connector-list-item-container{
  width: 100%;
  overflow-y: scroll;
  scrollbar-gutter: stable;
}

.cpo-fast-enroll-charger-model-list-connector-list-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;

}
.cpo-fast-enroll-charger-model-list-connector-list-item>div{
  display: flex;
  align-items: center;
}


.cpo-fast-enroll-selected-charger-model-container{
  border: 3px solid var(--emoovBlue);
  border-radius: 8px;
  margin-top: 20px;
  overflow: hidden;
}