.cpo-charger-management-grid-view-charger-list-header{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;  
}
/* Header(search items) */
.charger-management-grid-view-search-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  position: relative;
  width: 100%;
  margin-top: 30px;
}
/* keyword search */
.charger-management-grid-view-search-input-container{
  display: grid;
  grid-template-columns: 1fr 20px;
  column-gap: 5px;
  align-items: center;
  height: 45px;
  width: 500px;
  border-bottom: 2px solid var(--inputBorder2);
  position: relative;
  /* margin-left: 30px; */
  padding: 0 5px 5px 5px;
  transition: .3s;
}
.charger-management-grid-view-search-input-container:has(input:focus){
  border-bottom: 2px solid var(--emoovBlue);
  
}
.charger-management-grid-view-search-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.charger-management-grid-view-search-input-container>svg{
  color: var(--inputBorder2);
  cursor: pointer;
  transition: .13s;
}
.charger-management-grid-view-search-input-container>svg:active{
  opacity: 0.3;
}


/* Station List */
.charger-management-grid-view-station-list-container.open{
  margin-top: 760px;
}
.charger-management-grid-view-station-list-container{
  width: 100%;
  height: fit-content;
  padding: 0 5px;
  margin-top: 30px;
  transition: .3s;
}
.charger-management-grid-view-station-list-button-container.open{
  opacity: 0;
}
.charger-management-grid-view-station-list-button-container{
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 10px;
  transition: .3s;
}
.charger-management-grid-view-station-list-button-container>div:active{
  opacity: 0.3;
}
.charger-management-grid-view-station-list-button-container>div{
  height: 45px;
  padding: 0 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;  justify-content: center;
  background: var(--emoovBlue);
  cursor: pointer;
  transition: .3s;
  margin-left: 10px;
}
.charger-management-grid-view-station-list-button-container>div svg{
  transform: translate(-5px, 1px);
  color: #fff;
}
.charger-management-grid-view-station-list-button-container>div p{
  color: #fff;
  margin: 0;
  font-size: .9rem;
  font-weight: bold;
}
.charger-management-grid-view-station-list-button-container>div.delete{
  /* background: #feeded; */
  background: #fff;
  border: 1px solid var(--buttonBorderGray2);
}
.charger-management-grid-view-station-list-button-container>div.delete.none{
  pointer-events: none;
  opacity: 0.3;
}
.charger-management-grid-view-station-list-button-container>div.delete p{
  /* color: #ff8d8d; */
  color: var(--textGray2);
}
.charger-management-grid-view-station-list-button-container>div.excel{
  background: #ddf8de;
}
.charger-management-grid-view-station-list-button-container>div.excel svg{
  color: #42ab48;
}
.charger-management-grid-view-station-list-button-container>div.excel p{
  color: #42ab48;
  margin: 0;
  font-size: .85rem;
  font-weight: bold;
}
.charger-management-grid-view-station-list{
  width: 100%;
  min-width: 850px;
  /* height: 200px; */
  /* border: 1px solid #000; */
  
}
.charger-management-grid-view-station-list-column{
  border-top: 2px solid var(--inputBorder);
  border-bottom: 1px solid var(--inputBorder3);
  height: 50px;
  position: relative;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 2fr 2fr 0.8fr 0.8fr;
}
.charger-management-grid-view-station-list-column-item{
  height: 100%;
  width: 100%;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: start; 
}
/* .charger-management-grid-view-station-list-column-item:nth-child(1){
  width: 45px;
}
.charger-management-grid-view-station-list-column-item:nth-child(2){
  width: 120px;
}
.charger-management-grid-view-station-list-column-item:nth-child(3){
  width: 120px;
}
.charger-management-grid-view-station-list-column-item:nth-child(4){
  width: 270px;
}
*/
.charger-management-grid-view-station-list-column-item:nth-child(5){
  justify-content: center;
}
.charger-management-grid-view-station-list-column-item:nth-child(6){
  justify-content: center;
} 
.charger-management-grid-view-station-list-column-item.checkbox{
  position: absolute;
  top: -50px;
  left: 20px;
  width: fit-content;
}
.charger-management-grid-view-station-list-column-item.checkbox p{
  color: var(--inputBorder2);
  font-weight: 500;
  font-size: .9rem;
  margin-left: 10px;
}

.charger-management-grid-view-station-list-column p{
  margin: 0;
  font-size: .8rem;
  color: var(--inputBorder2);
  font-weight: 400;
}
.charger-management-grid-view-list-content{
  /* padding: 0 35px; */
  width: 100%;
  height: fit-content;
  min-height: 270px;
  max-height: 500px;
  /* background: #f2f9fa; */
  /* box-shadow: 1px 2px 5px rgba(4, 41, 55, 0.2); */
  overflow: scroll;
  scrollbar-width: none;
}


.charger-management-grid-view-list-content-item.clicked{
  /* border: 3px solid var(--emoovBlue); */
  background: var(--itemActiveBlue);
}
.charger-management-grid-view-list-content-item{
  width: 100%;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid var(--inputBorder3);
  cursor: pointer;
  transition: .13s;
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 2fr 2fr 0.8fr 0.8fr;
  position: relative;
}
.charger-management-grid-view-list-content-item>div.handle{
  position: absolute;
  right: 0;
  top: 0;
}
.charger-management-grid-view-list-content-item>div{
  height: 100%;
  padding: 0 10px;
  display: flex;align-items: center;
}
.charger-management-grid-view-list-content-item>div p{
  user-select:text;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow:ellipsis;
  word-break: break-all;
  margin: 0;
  font-size: .9rem;
  font-weight: 400;
  color: #555;
}

.charger-management-grid-view-list-content-item>div:nth-child(1) label{
  display: flex;
  align-items: center;
  justify-content: center;
}

.charger-management-grid-view-list-content-item>div:nth-child(5){
  justify-content: center;
}
.charger-management-grid-view-list-content-item>div:nth-child(6){
  justify-content: center;
}


.charger-management-grid-view-list-content-item-function-button-container{
  height: 40px;
  width: fit-content;
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: .3s;
}
.charger-management-grid-view-list-content-item-function-button-container>svg{
  cursor: pointer;
}
.charger-management-grid-view-list-content-item-function-button-container{
  font-size: 1.5rem;
}
.charger-management-grid-view-list-content-item-function-button-container:hover{
  /* background: #e4e8e9; */
}
.charger-management-grid-view-list-content-item-function-button-container:has(.charger-management-grid-view-list-content-item-function-button-list.open){
  box-shadow: 0px 0px 4px 0px #00000040;
  
}
.charger-management-grid-view-list-content-item-function-button-list{
  display: flex;
  align-items: center;
  justify-content: center;
  visibility:hidden;
  max-width: 0;
  background: #fff;
}
.charger-management-grid-view-list-content-item-function-button-list.open{
  max-width: 150px;
  visibility: visible;
}
.charger-management-grid-view-list-content-item-function-button-list>div:first-child::after, .charger-management-grid-view-list-content-item-function-button-list>div:nth-child(2)::after{
  content: "";
  display: block;
  position: absolute;
  right: 0px;
  width: 1.2px;
  height: 50%;
  background: #E2E2E2;
}
.charger-management-grid-view-list-content-item-function-button-list>div{
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.charger-management-grid-view-list-content-item-function-button{
  width: max-content;
  height: 85%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* transition: .1s; */
}
.charger-management-grid-view-list-content-item-function-button:active{
  opacity: 0.5;
}
.charger-management-grid-view-list-content-item-function-button>div{
  width: max-content;
  
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6E6E6E;
  transition: background .3s;
  font-size: 1.1rem;
  border: 1.5px solid var(--emoovBlue);
  padding: 10px;
  border-radius: 8px;
  margin: 0 10px;
  color: var(--emoovBlue);
  font-weight: 600;
}





/* GridView */
.cpo-charger-management-grid-view-charger-list-wrapper{
  width: 100%;
  height: fit-content;
  margin-top: 30px;
}

.cpo-charger-management-grid-view-charger-list-wrapper-header{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
}
.cpo-charger-management-grid-view-charger-list-header.open{
}
.cpo-charger-management-grid-view-charger-list-header>p{
  margin: 0;
}

.cpo-charger-management-grid-view-before-select{
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--inputBorder);
  background: #efefef;
  border-radius: 8px;
}
.cpo-charger-management-grid-view-before-select svg{
  font-size: 4.5rem;
  margin-bottom: 15px;
}
.cpo-charger-management-grid-view-before-select>div>svg:first-child{
  color: var(--inputBorder3);
  transform: translateX(50%);
}
.cpo-charger-management-grid-view-before-select>div>svg:last-child{
  color: var(--emoovBrightBlue);
  font-size: 4.5rem;
  transform: translate(-25px,-10px);
}




/* ChargerList */
.cpo-charger-management-grid-view-charger-list-container.open{
  padding-top: 750px;
}
.cpo-charger-management-grid-view-charger-list-container{
  width: 100%;
  height: fit-content;
  padding: 0 5px;
  margin-top: 50px;
  transition: .3s;
}
.cpo-charger-management-grid-view-charger-list-header{
  display: flex;
  align-items: center;
  justify-content: space-between;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  height: 60px;
  margin-bottom: 10px;
  column-gap: 100px;
}

.cpo-charger-management-grid-view-charger-list-button-container.open{
  opacity: 0;
}
.cpo-charger-management-grid-view-charger-list-button-container{
  height: 50px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: end;
  transition: .3s;
}
.cpo-charger-management-grid-view-charger-list-button-container>div:active{
  opacity: 0.3;
}
.cpo-charger-management-grid-view-charger-list-button-container>div{
  height: 45px;
  padding: 0 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;  justify-content: center;
  background: var(--emoovBlue);
  cursor: pointer;
  transition: .3s;
  margin-left: 10px;
}
.cpo-charger-management-grid-view-charger-list-button-container>div svg{
  transform: translate(-5px, 1px);
  color: #fff;
}
.cpo-charger-management-grid-view-charger-list-button-container>div p{
  color: #fff;
  margin: 0;
  font-size: .9rem;
  font-weight: bold;
}
.cpo-charger-management-grid-view-charger-list-button-container>div.delete{
  /* background: #feeded; */
  background: #fff;
  border: 1px solid var(--buttonBorderGray2);
}
.cpo-charger-management-grid-view-charger-list-button-container>div.disabled{
  pointer-events: none;
  opacity: 0.3;
}
.cpo-charger-management-grid-view-charger-list-button-container>div.delete p{
  /* color: #ff8d8d; */
  color: var(--textGray2);

}
.cpo-charger-management-grid-view-charger-list-button-container>div.excel{
  background: #ddf8de;
}
.cpo-charger-management-grid-view-charger-list-button-container>div.excel svg{
  color: #42ab48;
}
.cpo-charger-management-grid-view-charger-list-button-container>div.excel p{
  color: #42ab48;
  margin: 0;
  font-size: .85rem;
  font-weight: bold;
}




.cpo-charger-management-grid-view-charger-list{
  width: 100%;
  min-width: 850px;
}

.cpo-charger-management-grid-view-charger-list-column{
  border-top: 2px solid var(--inputBorder);
  border-bottom: 1px solid var(--inputBorder3);
  height: 50px;
  position: relative;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 1.5fr 0.5fr 2fr 2fr 0.7fr 0.5fr;

}
.cpo-charger-management-grid-view-charger-list-column-item{
  height: 100%;
  width: max-content;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-charger-management-grid-view-charger-list-column p{
  margin: 0;
  font-size: .8rem;
  color: var(--inputBorder2);
  font-weight: 400;
}
.cpo-charger-management-grid-view-list-content{
  /* padding: 0 15px; */
  width: 100%;
  height: fit-content;
  min-height: 270px;
  max-height: 500px;
  background: #fff;
  overflow: scroll;
  scrollbar-width: none;
}


.cpo-charger-management-grid-view-list-content-item.clicked{
  background: var(--itemActiveBlue);
}
.cpo-charger-management-grid-view-list-content-item{
  width: 100%;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 1.5fr 0.5fr 2fr 2fr 0.7fr 0.5fr;
  cursor: pointer;
  transition: .13s;

}
.cpo-charger-management-grid-view-list-content-item>div{
  /* border-right: 1.5px solid var(--emoovBlue30); */
  height: 100%;
  padding: 0 10px;
  display: flex;align-items: center;
}
.cpo-charger-management-grid-view-list-content-item>div p{
  user-select:text;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow:ellipsis;
  word-break: break-all;
  margin: 0;
  font-size: .9rem;
  font-weight: 400;
  color: #555;
}
.cpo-charger-management-grid-view-status-item.loading{
  border-color: #eee;
  background: #eee;
  height: 30px;
}
.cpo-charger-management-grid-view-status-item{
  border: 2px solid var(--emoovBlue);
  border-radius: 100vh;
  width: 55px;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.cpo-charger-management-grid-view-list-content-item .cpo-charger-management-grid-view-status-item p{
  color: var(--emoovBlue);
  font-weight: bold;
}
.cpo-charger-management-grid-view-list-content-item .cpo-charger-management-grid-view-status-item.none{
  border-color: var(--textRed1);
}
.cpo-charger-management-grid-view-list-content-item .cpo-charger-management-grid-view-status-item.none p{
  color: var(--textRed1);
}

.cpo-charger-management-grid-view-connector-badge{
  display: flex;align-items: center;justify-content: center;
  width: fit-content;
  height: 30px;
  border: 2px solid var(--emoovBlue);
  border-radius: 100vh;
  font-weight: 500;
  padding: 0 10px;
  margin-right: 5px;
  background: #fff;
}
.cpo-charger-management-grid-view-list-content-item .cpo-charger-management-grid-view-connector-badge>p{
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}
