.cpo-pay-report-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpo-pay-report-inner{
  width: 100%;
  max-width: 1150px;
  min-width: 1150px;
  height: 100%;
  position: relative;
}


.cpo-pay-report-content-container{
  width: 100%;
  padding-top: 20px;
}
.cpo-pay-report-content-container.grid{
  display: grid;
  grid-template-columns: 1fr 500px;
}

.cpo-pay-report-content-search-container{
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.cpo-pay-report-back-button{
  position: absolute;
  display: flex;
  align-items: center;
  top: -10px;
  cursor: pointer;
  transition: .13s;
}
.cpo-pay-report-back-button:active{
  opacity: 0.3;
}
.cpo-pay-report-content-search-filter-button{
  width: fit-content;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
  font-weight: 500;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
}

.cpo-pay-report-content-search-filter-button:active{
  opacity: 0.3;
}
.cpo-pay-report-content-search-filter-button>svg{
  margin-left: 10px;
  font-size: 1.2rem;
}

.cpo-pay-report-content-search-input-container{
  display: grid;
  grid-template-columns: 1fr 20px;
  column-gap: 5px;
  align-items: center;
  height: 30px;
  width: 500px;
  border-bottom: 2px solid var(--inputBorder2);
  position: relative;
  margin-left: 30px;
  padding: 0 5px 5px 5px;
  transition: .3s;
}
.cpo-pay-report-content-search-input-container:has(input:focus){
  border-bottom: 2px solid var(--emoovBlue);
}
.cpo-pay-report-content-search-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-pay-report-content-search-input-container>svg{
  color: var(--inputBorder2);
  cursor: pointer;
  transition: .13s;
}
.cpo-pay-report-content-search-input-container>svg:active{
  opacity: 0.13;
}

.cpo-pay-report-item-list-grid-container.grid{
  display: grid;
  grid-template-columns: 1fr 600px;
}


.cpo-pay-report-content-item-list-container{
  width: 100%;
  min-height: 300px;
  border-top: 2px solid var(--inputBorder2);
}
.cpo-pay-report-content-item-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.8fr 1fr 1.3fr 1fr 0.5fr;
  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;

  font-size: .9rem;
  color: var(--inputBorder2);
}
.cpo-pay-report-content-item-column.pay-tab0{
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
}
.cpo-pay-report-content-item-column.pay-tab0.readOnly{
  border-top: 2px solid var(--inputBorder2);
  border-bottom: none;
}
.cpo-pay-report-content-item-column.pay-tab1{
  grid-template-columns: 0.5fr 1fr 1fr;
}

.cpo-pay-report-content-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.8fr 1fr 1.3fr 1fr 0.5fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
  cursor: pointer;
}
.cpo-pay-report-content-item:active{
  background: var(--emoovLightBlue2);
}
.cpo-pay-report-content-item.pay-tab0.readOnly{
  border-top: 1px solid var(--inputBorder3);
  border-bottom: none;
  pointer-events: none;
}


.cpo-pay-report-content-item.pay-tab0{
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr;

}
.cpo-pay-report-content-item.pay-tab0>div:last-child{
  display: flex;align-items: center;
}
.cpo-pay-report-content-item.pay-tab0>div:last-child>svg{
  margin-left: 5px;
  color: var(--inputBorder);
  cursor: pointer;
  font-size: 1.1rem;
}

.cpo-pay-report-content-item.pay-tab1{
  grid-template-columns: 0.5fr 1fr 1fr;
}
.cpo-pay-report-content-item.pay-tab1.clicked{
  background: var(--emoovLightBlue2);
}




.cpo-pay-report-content-item p{
  margin: 0;
}

.cpo-pay-report-content-item-payment{
  width: fit-content;
  display: flex;
  align-items: center;  justify-content: center;
  padding: 5px 15px;
  border-radius: 100vh;
  border: 2px solid;
  font-size: .8rem;
  font-weight: 600;
  background: #fff;
}

.cpo-pay-report-content-item-payment.success{
  border-color: var(--emoovBlue);
  color: var(--emoovBlue);
}
.cpo-pay-report-content-item-payment.failure{
  border-color: var(--textRed);
  color: var(--textRed);
}
.cpo-pay-report-content-item>div:last-child{
  position: relative;
}
.cpo-pay-report-content-item-payment-modal{
  width: 100px;
  height: 80px;
  border-radius: 8px;
  /* background: #000; */
  position: absolute;
  right: -100%;
}










.cpo-pay-report-detail-container{
  background: #384349;
  min-height: 600px;
  padding: 15px 30px;
}

.cpo-pay-report-detail-container>p{
  color: #fff;
}

.cpo-pay-report-detail-info{
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;align-items: center;justify-content: center;
}
.cpo-pay-report-occp-action-header{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.cpo-pay-report-occp-action-header>div:last-child{
  color: #fff;
  font-weight: 500;
}

.cpo-pay-report-occp-action-result-badge{
  border-radius: 100vh;
  width: fit-content;
  height: 30px;
  padding: 0 15px;
  display: flex;align-items: center;justify-content: center;
  font-weight: 600;
  border: 2px solid var(--emoovBlue);
  color: var(--emoovBlue);
  font-size: 0.9rem;
  margin-right: 10px;
}
.cpo-pay-report-occp-action-result-badge.request{
  border: 2px solid var(--emoovBlue);
  color: var(--emoovBlue);
}
.cpo-pay-report-occp-action-result-badge.confirm{
  border: 2px solid var(--textGreen);
  color: var(--textGreen);
}
.cpo-pay-report-occp-action-result-badge.error{
  border: 2px solid var(--emoovBlue);
  color: var(--emoovBlue);
}

.cpo-pay-report-json-container{
  border-radius: 8px;
  height: 250px;
  overflow: scroll;
  margin-bottom: 20px;
  font-size: 0.9rem;
  padding-top: 10px;
}





.cpo-pay-report-page-selector-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  /* margin-top: 30px; */
  border-top: 2px solid var(--inputBorder2);
}
.cpo-pay-report-page-selector-container>svg{
  cursor: pointer;
  transition: .13s;
}
.cpo-pay-report-page-selector-container>svg:active{
  opacity: 0.3;
}
.cpo-pay-report-page-selector{
  width: 30px;
  height: 30px;
  border-radius: 100vh;
  /* border: 2px solid #000; */
  font-size: 1.1em;
  font-weight: 500;
  display: flex;align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
  margin: 0 10px;
}
.cpo-pay-report-page-selector:active{
  opacity: 0.3;
}
.cpo-pay-report-page-selector.clicked{
  color: var(--emoovBlue);
}




/* CpoPayReportSearchModal */
.cpo-pay-report-search-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-pay-report-search-modal-container{
  position: relative;
  max-width: 1100px;
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 30px 30px;
  z-index: 1000;
}
.cpo-pay-report-search-modal-header{
  width: 100%;
  margin-bottom: 30px;
  display: flex; align-items: center;
}
.cpo-pay-report-search-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-pay-report-search-modal-header p{
  margin: 0;
  color: var(--inputBorder2);
}
.cpo-pay-report-search-modal-close-button{
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: .13s;
}
.cpo-pay-report-search-modal-close-button:active{
  opacity: 0.3;
}

.cpo-pay-report-search-modal-content-section{
  width: 800px;
}

.cpo-pay-report-search-modal-button-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 45px;
}
.cpo-pay-report-search-modal-button{
  width: fit-content;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  border-radius: 8px;
  background: var(--buttonBlack);
  cursor: pointer;
  transition: .13s;
}
.cpo-pay-report-search-modal-button.reset{
  border: 1px solid var(--inputBorder2);
  color: var(--inputBorder2);
  background: #fff;
  padding: 0 10px;
  margin-right: 15px;
}
.cpo-pay-report-search-modal-button:active{
  opacity: 0.3;
}
