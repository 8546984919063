.cpo-charger-control-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cpo-charger-control-wrapper{
  max-width: 1080px;
  width: 100%;
  /* overflow: hidden; */
}
.cpo-charger-control-header{
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cpo-charger-control-header p{
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
}
.carousel-container:hover{
  background: var(--hoverGray2);
}
.carousel-container {
  overflow: scroll;
  background: var(--hoverGray2);

  position: absolute;
  max-height: 1080px;
  /* min-height: ; */
  padding-bottom: 10px;
  width: 220px;
  border-radius: 7px;
  /* border: 1px solid #000; */
  overflow-y: auto;
  overflow-x: hidden;
  transform-origin: right top;
  transform: rotate(-90deg) translateY(-220px);
  margin-top: 20px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  transition: .3s;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1) ;
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.carousel-container > div {
  
}

.carousel-container > div:last-child {
  /* margin-bottom: calc(100% - 280px); */
  /* margin-bottom: 10px; */
}

.cpo-charger-control-device-container{
  height: 300px;
  margin-bottom: 30px;
}
.carousel-container .carousel-item{
  height: 150px;
  width: 300px;
  margin-bottom: 160px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  transform: rotate(90deg) translateX(310px) translateY(115px);
  transform-origin: right top;
  border-radius: 8px;
  cursor: pointer;
  transition: .3s;
  background: #fff;
  padding: 10px;
  /* border: 3px solid var(--emoovBlue10); */
  scroll-margin:16px;
}
.carousel-container .carousel-item:hover{
  transform: rotate(90deg) translateX(310px) translateY(100px);
}
.carousel-container .carousel-item.selected{
  box-shadow: var(--emoovBlue) 0px 0px 0 3px;
}

.station-item-header{
  display: flex;
  align-items: center;
}
.station-icon-container{
  height: 40px;
  width: 40px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  background: var(--emoovBlue);
  color: #fff;
}
.station-icon{
  transform: translate(1px,-1px);
}
.station-name{
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: 600;
}
.station-id{
  position: absolute;
  top: 2px;
  right: 5px;
  font-size: .75rem;
  font-weight: 600;
  color: var(--textGray1);
}
.station-item-line{
  width: 100%;
  height: 1.5px;
  background: var(--navBgGray);
  margin: 5px 5px;
}
.station-pin-icon{
  color: var(--emoovBlue);
  font-size: 1.25rem;
  margin-right: 5px;
}
.station-address-title-container{
  display: flex;
  align-items: center;
}
.station-address-title-container p{
  margin: 0;
  font-size: .95rem;
  font-weight: 600;
  color: var(--emoovBlue);
}
.station-address-container{
  display: flex;
  align-items: center;
  font-size: .9rem;
}
.station-address-container p{
  margin: 5px 0;
  user-select: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow:ellipsis;
  word-break: keep-all;
}
.station-address-container p{
  margin-right: 4px;
  font-size: .8rem;
}







.cpo-charger-card-container{
  position: relative;
}

.cpo-charger-control-header.second{
  margin-top: 260px;
}
.cpo-charger-control-header.second>div.flex-wrapper{
  display: flex;
  align-items: center;
}
.cpo-charger-control-header.second>div.flex-wrapper>p{
  margin: 0px;
}
.cpo-charger-control-header.second>div.flex-wrapper>svg{
  margin-left: 10px;
  cursor: pointer;
  transition: .13s;
  font-size: 1.4rem;
}
.cpo-charger-control-header.second>div.flex-wrapper>svg:active{
  opacity: 0.3;
  transform: rotate(90deg);
}
.charger-refresh-msg{
  margin-left: 10px;
  font-size: .9rem;
  color: var(--textGray1);
  font-weight: 600;
}
.cpo-charger-control-device-container{
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 250px 250px 250px 250px;
  grid-gap: 20px;
  height: max-content;
  margin-top: 20px;
}
.plz-select-msg{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  grid-column: -1/1;
  font-size: 1.3rem;
  color: var(--textGray1);
  font-weight: 600;
}


.cpo-charger-card-idx-control-container{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cpo-charger-card-idx-control-container div{
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background: var(--inputBorder3);
  margin: 0 2px;
  cursor: pointer;
  transition: .13s;
}
.cpo-charger-card-idx-control-container div:hover{
  width: 20px;
}
.cpo-charger-card-idx-control-container div.selected:hover{
  width: 7px;
}
.cpo-charger-card-idx-control-container div.selected{
  background: #000;
}

.cpo-charger-item-container{
  width: 100%;
  height: 460px;
  /* border: 2px solid var(--emoovBlue70); */
  border-radius: 8px;
  padding: 15px;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px; */
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  background: #fff;
  position: relative;
}
.cpo-charger-id{
  font-size: .9rem;
  margin: 0;
  /* margin-left: 10px; */
  font-weight: bold;
  color: var(--textGray2);
}
.cpo-charger-status-container{
  width: 100%;
  height: 40px;
  padding-bottom: 10px;
  border-radius: 5px 5px 0 0;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  margin-bottom: 20px;
}
.cpo-charger-status{
  display: block;
  margin: 0;
  position: absolute;
  right: 0px;
  height: 30px;
  padding: 0 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background: #f0f6f8;
  color: #a2b3ba;
}
.cpo-charger-status.ready{
background :var(--readyBg);
color: var(--readyText);
}
.cpo-charger-status.connected{
background :var(--connectedBg);
color: var(--connectedText);
}
.cpo-charger-status.available{
  background :var(--availableBg);
  color: var(--availableText);
}
.cpo-charger-status.preparing{
background :var(--preparingBg);
color: var(--preparingText);
}
.cpo-charger-status.charging{
background :var(--chargingBg);
color: var(--chargingText);
}
.cpo-charger-status.pause{
background :var(--pauseBg);
color: var(--pauseText);
}
.cpo-charger-status.finishing{
background :var(--finishingBg);
color: var(--finishingText);
}
.cpo-charger-status.reserved{
background :var(--reservedBg);
color: var(--reservedText);
}
.cpo-charger-status.unavailable{
background :var(--unavailableBg);
color: var(--unavailableText);
}
.cpo-charger-status.faulted{
background :var(--faultedBg);
color: var(--faultedText);
}
















.cpo-charger-status p{
  margin: 0;
  font-size: .85rem;
  font-weight: 600;
}
.cpo-charger-front-container{
  width: 100%;
  margin-bottom: 10px;
}
.cpo-charger-front-title{
  display: flex;
  align-items: center;
  font-size: .8rem;
  font-weight: 600;
}
.cpo-charger-front-title p{
  margin: 0;
  color: var(--textGray3);
}
.cpo-charger-front{
  margin: 0;
  margin-top: 5px;
  font-size: .9rem;
  color: var(--emoovBlue);
}
.cpo-charger-front.alert{
  color: var(--textRed1);
}

.cpo-charger-connector-status-container{
  display: grid;
  margin-bottom: 20px;
}
.cpo-charger-connector-status-container.one{
  grid-template-columns: 1fr;
}
.cpo-charger-connector-status-container.two{
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}

.cpo-charger-connector-status{
  border-radius: 8px;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  background: #f0f6f8;
  color: #a2b3ba;
}
.cpo-charger-connector-status.back{
  height: 35px;
}
.cpo-charger-connector-status>p{
  margin: 0;
  font-weight: 500;
}
.cpo-charger-connector-status>p:first-child{
  font-size: .9rem;
  font-weight: 600;
  margin-bottom: 5px;
}
.cpo-charger-connector-status.back>p{
  margin-bottom: 0;
  font-size: .95rem;
}
.cpo-charger-connector-status>svg{
  font-size: 1.5rem;
  margin-bottom: 5px;
}


.cpo-charger-connector-status.ready{
  background :var(--readyBg);
  color: var(--readyText);
}
.cpo-charger-connector-status.connected{
  background :var(--connectedBg);
  color: var(--connectedText);
}
.cpo-charger-connector-status.available{
  background :var(--availableBg);
  color: var(--availableText);
}
.cpo-charger-connector-status.preparing{
  background :var(--preparingBg);
  color: var(--preparingText);
}
.cpo-charger-connector-status.charging{
  background :var(--chargingBg);
  color: var(--chargingText);
}
.cpo-charger-connector-status.pause{
  background :var(--pauseBg);
  color: var(--pauseText);
}
.cpo-charger-connector-status.finishing{
  background :var(--finishingBg);
  color: var(--finishingText);
}
.cpo-charger-connector-status.reserved{
  background :var(--reservedBg);
  color: var(--reservedText);
}
.cpo-charger-connector-status.unavailable{
  background :var(--unavailableBg);
  color: var(--unavailableText);
}
.cpo-charger-connector-status.faulted{
  background :var(--faultedBg);
  color: var(--faultedText);
}

.cpo-charger-connector-status .tooltip-content{
  font-weight: 500;
  display: flex;
  margin: 0;
}
.cpo-charger-connector-status .tooltip-content>span{
  font-size: .85rem;
  padding: 2px 4px;
  display: flex;align-items: center;justify-content: center;
  border-radius: 100vh;
  box-shadow: 0 0 0 2px var(--emoovBlue);
  margin-right: 10px;
}
.cpo-charger-connector-status .tooltip-content>span:last-child{
  margin-right: 0;
}








.cpo-charger-connector-type-container{
  width: 100%;
  margin-top: 10px;
}
.cpo-charger-connector-type-title{
  display: flex;
  align-items: center;
  font-size: .8rem;
  font-weight: 600;
}
.cpo-charger-connector-type-title p{
  margin: 0;
  color: var(--textGray3);
}
.cpo-charger-connector-type{
  margin: 0;
  margin-top: 5px;
  font-size: .9rem;
}
.cpo-charger-btn-title-container{
  width: 100%;
  height: 20px;
  display: flex;

}
.cpo-charger-btn-list-container{
  position: absolute;
  bottom: 30px;
  padding: 0 10px;
  left: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cpo-charger-btn-list-container>div{
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cpo-charger-onOff-btn{
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100vh;
  /* background-image: linear-gradient(to right, var(--emoovBlue10) 0%, var(--emoovBlue10)  21%, #4c92e6  100%); */
  /* background-size: 200% auto; */
  background: #d7f2fa;
  transition: .17s;
}
.cpo-charger-onOff-btn-container:has(.cpo-charger-onOff-btn.disabled){
  cursor: default;
}
.cpo-charger-onOff-btn.disabled{
  cursor: default;
  opacity: .3;
  user-select: none;
}
.cpo-charger-onOff-btn.disabled:active{
  opacity: .3;
}
.cpo-charger-onOff-btn.used{
  background: var(--faultedBg);
}
.cpo-charger-onOff-btn.used .cpo-charger-onOff-btn-icon{
  color: var(--textRed1);
}
.cpo-charger-onOff-btn.used p{
  color: var(--textRed1);
}
.cpo-charger-onOff-btn:active{
  opacity: .5;
}
.cpo-charger-onOff-btn-icon{
  /* width: 50px;
  height: 50px; */
  display: flex;
  align-items: center;  justify-content: center;
  border-radius: 100vh;
  color: var(--emoovBlue);
  /* background: #8fe1fa;
  color: #fff; */
}

.cpo-charger-btn-list-container p{
  /* display: block;
  margin: 0;
  width: max-content;
  position: absolute;
  transform: translate(-50%, -50%);
  top: -20%;
  left: 50%; */
  font-size: .75rem;
  color: var(--textGray1);
  font-weight: 600;
  margin: 0;
  margin-top: 5px;
  color: var(--emoovBlue);
}
.cpo-charger-detail-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  width: 100%;
  height: 170px;
  padding: 10px;
}
.cpo-charger-detail-container>div{
  width: 100%;
  height: 100%;
}
.cpo-charger-detail-container>div>p{
  margin: 0;
  margin-bottom: 10px;
  font-size: .7rem;
  font-weight: 600;
  color: var(--textBlue);
}
.cpo-charger-detail-container>div>p:last-child{
  font-size: .8rem;
  text-align: center;
  color: #000;
  margin-top: 2px;
}
.cpo-charger-four-btn-container{
  /* position: absolute;
  bottom: 0px;
  padding: 10px 0; */
  border-radius: 0 0 5px 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr; */
  grid-row-gap: 5px;
  width: 100%;
  /* background: #ecf5fc; */
  row-gap: 10px;
  column-gap: 10px;
}
.cpo-charger-four-btn-container>div{
  height: 64px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cardBtnBlue);
  transition: opacity .05s;
  position: relative;
  color: var(--emoovBlue);
}
.cpo-charger-four-btn-container>div.disabled{
  cursor: default;
  background: #f6fcfe;
  color: #c6e8f3;
}
.cpo-charger-four-btn-container>div.disabled p{
  color: #c6e8f3;
}
.cpo-charger-four-btn-container>div.delete{
  background-color: var(--cardBtnRed);
  color: var(--textRed1);
}
.cpo-charger-four-btn-container>div:active{
  opacity: .3;
}
.cpo-charger-four-btn-container>div.disabled:active{
  opacity: 1;
}
.cpo-charger-four-btn-container>div svg{
  width: 18px;
  height: 18px;
}
.cpo-charger-four-btn-container>div p{
  margin: 0;
  margin-top: 10px;
  font-size: .7rem;
  font-weight: bold;
  color: var(--emoovBlue);
}
.cpo-charger-four-btn-container>div.delete p{
  color: var(--textRed1);
}

.cpo-charger-four-btn-container>div .tooltip-content{
  font-weight: 500;
}
.cpo-charger-four-btn-container>div .tooltip-content span{
  line-height: 1.2rem;
}
.tooltip-content .tooltip-highlight-content{
  font-weight: 600;
  color: var(--emoovBlue);
}






.nav-input-container{
  display: grid;
  grid-template-columns: 1fr 20px;
  column-gap: 5px;
  align-items: center;
  height: 30px;
  width: 500px;
  border-bottom: 2px solid var(--inputBorder2);
  position: relative;
  margin-left: 30px;
  padding: 0 5px 5px 5px;
  transition: .3s;
}
.nav-input-container:has(input:focus){
  border-bottom: 2px solid var(--emoovBlue);
  
}
.nav-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.nav-input-container>svg{
  color: var(--inputBorder2);
  cursor: pointer;
  transition: .13s;
}
.nav-input-container>svg:active{
  opacity: 0.13;
}





/* ChasSettingModal */
.cpo-charger-chas-setting-open-button{
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background: var(--emoovBlue);
  display: flex;align-items: center;justify-content: center;
  margin-bottom: 20px;
  transition: .17s;
  cursor: pointer;
}
.cpo-charger-chas-setting-open-button p{
  font-size: .9rem;
  color: #fff;
  margin: 0;
}
.cpo-charger-chas-setting-open-button:active{
  opacity: 0.3;
}
.cpo-charger-chas-setting-open-button.none{
  pointer-events: none;
  opacity: 0.3;
}
.cpo-chas-setting-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-chas-setting-modal-container{
  position: relative;
  width: 900px;
  height: 600px;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
}
.cpo-chas-setting-modal-header{
  width: 100%;
  height: 50px;
  /* border: 1px solid #000; */
  display: flex;
  align-items: end;
}
.cpo-chas-setting-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-chas-setting-modal-header p{
  margin: 0;
  color: #aaa;
  font-weight: bold;
}
.cpo-chas-setting-content-container{
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  width: 100%;
  height: calc(100% - 50px);
}

.cpo-chas-setting-content-container>div{
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
}
.cpo-chas-setting-content-top{
  justify-content: space-between;
}
.cpo-chas-setting-content-top>div:first-child{
  display: flex;
  align-items: center;
  margin-left: 38px;
  margin-top: 20px;
}
.cpo-chas-setting-content-top>div:first-child p{
  margin-left: 10px;
}
.cpo-chas-setting-content-top>div:last-child{
  display: flex;align-items: center;
}
.cpo-chas-setting-content-selector-container{
  width: 200px;
  margin-left: 10px;
  margin-right: 35px;
}
.cpo-chas-setting-content-container .cpo-chas-setting-content-middle{
  overflow: scroll;
  display: block;
  position: relative;
  padding: 0 20px;
  background: var(--emoovBlue1);
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1) ;

}

.cpo-charger-chas-list-content-item.clicked{
  border: 3px solid var(--emoovBlue);
}
.cpo-charger-chas-list-content-item{
  border: 3px solid #fff;
  width: 100%;
  height: 55px;
  /* border: 1px solid #000; */
  margin: 10px 0;
  background: #fff;
  border-radius: 15px;
  box-shadow: 1px 1px 5px rgba(4, 41, 55, 0.2);
  padding: 10px 0px;
  display: flex;
  cursor: pointer;
  transition: .17s;
  justify-content: space-between;
}
.cpo-charger-chas-list-content-item>div{
  /* border-right: 1.5px solid var(--emoovBlue30); */
  height: 100%;
  padding: 0 10px;
  display: flex;align-items: center;
}
.cpo-charger-chas-list-content-item>div p{
  user-select:text;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow:ellipsis;
  word-break: break-all;
  margin: 0;
  font-size: .8rem;
  font-weight: 600;
  color: #555;
}
.cpo-charger-chas-list-content-item>div:first-child>div:first-child{
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
} 
.cpo-charger-chas-list-content-item>div:first-child>div:first-child label{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.cpo-charger-chas-list-content-item>div:last-child{
  /* width: 200px; */
}
.cpo-charger-chas-list-content-item>div:last-child p:first-child{
  margin-right: 10px;
}

.cpo-charger-chas-list-content-item>div:last-child .actionset-selector-box{
  width: 400px;
}
.cpo-charger-chas-list-content-item>div:last-child .actionset-box{
  width: 400px;
  height: 40px;
  border-radius: 10px;
  /* border: 1.5px solid var(--oxfordBlue1); */
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.cpo-charger-chas-list-content-item>div:last-child .actionset-box>p{
  font-weight: 600;
  color: var(--emoovBlue);
}
/* .cpo-charger-chas-list-content-item>div:nth-child(2){
  width: 120px;
} */









.cpo-chas-setting-content-bottom{
  justify-content: space-between;
}
.cpo-chas-setting-content-button{
  width: 80px;
  height: 40px;
  background: var(--emoovBlue);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  cursor: pointer;
  transition: .17s;
}
.cpo-chas-setting-content-button:active{
  opacity: 0.3;
}
.cpo-chas-setting-content-button p{
  margin: 0;
  font-weight: 500;
  font-size: .9rem;
}
.cpo-chas-setting-content-button.none{
  opacity: .3;
  pointer-events: none;
}



















/*  */

.charger-detail-tab.tab2.chargerCardEventTab0{
  width: 1000px;
  padding: 0;
}

.cpo-install-management-container.chargerCardEventTab1{
  width: 800px;
  min-height: 500px;
  max-height: 800px;
  overflow-y: scroll;
  padding: 0;
}


.chargerCardEventTab2{
  width: 500px;
}
.chargerCardEventTab3{
  width: 800px;
}

.chargerCardEventTab3>div{
  margin-bottom: 20px;
}




.firmware-update-add-button{
  height: 45px;
  border-radius: 8px;
  background: var(--buttonBlack);
  color: #fff;
  padding: 0 20px;
  display: flex;align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.firmware-update-add-button:active{
  opacity: 0.3;
}
.firmware-update-title{
  font-weight: 500;
  margin-top: 30px;
}
.firmware-update-selector-list-container{
  display: grid;
  grid-template-columns: 500px 100px;
  column-gap: 20px;
}
.firmware-update-apply-button{
  height: 45px;
  border-radius: 8px;
  background: var(--emoovBlue);
  color: #fff;
  display: flex;align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.firmware-update-apply-button:active{
  opacity: 0.3;
}
.firmware-update-update-list-container{
  width: 100%;
  border-top: 2px solid var(--inputBorder);
  display: grid;
  grid-template-rows: 45px auto;
}
.firmware-update-update-list-column{
  display: grid;
  padding: 0 20px;
  align-items: center;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
}
.firmware-update-update-list{
  width: 100%;
  height: 400px;
  overflow-y: scroll;
}
.firmware-update-update-list-item{
  display: grid;
  padding: 0 20px;
  align-items: center;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid var(--inputBorder3);
}



/* CpoAppendFirmWareFileModal */

.cpo-append-firmware-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-append-firmware-modal-container{
  position: relative;
  width: 600px;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 30px 30px;
  z-index: 1000;
}
.cpo-append-firmware-modal-header{
  width: 100%;
  margin-bottom: 30px;
  display: flex; align-items: center;
}
.cpo-append-firmware-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-append-firmware-modal-header p{
  margin: 0;
  color: var(--inputBorder2);
}
.cpo-append-firmware-modal-close-button{
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: .13s;
}
.cpo-payment-function-modal-close-button:active{
  opacity: 0.3;
}

.cpo-append-firmware-modal-upload-box-container{
  height: 100px;
}


.cpo-append-firmware-modal-input-container{
  border: 1px solid var(--inputBorder);
  border-radius: 8px;
  height: 45px;
  width: 100%;
  padding: 0 10px;
}
.cpo-append-firmware-modal-input-container input{
  all: unset;
  width: 100%;height: 100%;
}

.cpo-append-firmware-button-list-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-top: 20px;
}
.cpo-append-firmware-button{
  width: 100%;
  height: 45px;
  display: flex;align-items: center;justify-content: center;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: .13s;
}
.cpo-append-firmware-button:active{
  opacity: 0.3;
}
.cpo-append-firmware-button.cancel{
  border: 1px solid var(--inputBorder2);
}
.cpo-append-firmware-button.apply{
  background: var(--emoovBlue);
  color: #fff;
}



.chargerCardEventTab4{

}









.cpo-payment-method-alert-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-payment-method-alert-container{
  position: relative;
  width: 500px;
  height: 220px;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px;
  z-index: 1000;
}
.cpo-payment-method-alert-header{
  width: 100%;
  margin-bottom: 30px;
}
.cpo-payment-method-alert-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-payment-method-alert-alert-msg{
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: var(--textRed1);
}
.cpo-payment-method-alert-button-container{
  display: flex;
  justify-content: end;
}
.cpo-payment-method-alert-button{
  width: 	fit-content;
  height: 40px;
  padding: 0 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  cursor: pointer;
  transition: .13s;
  background: var(--buttonBlack);
  margin-top: 20px;
}

.cpo-payment-method-alert-button:active{
  opacity: 0.3;
}
.cpo-payment-method-alert-button p{
  margin: 0;
  font-weight: 500;
  font-size: .9rem;
}
.cpo-payment-method-alert-button.none{
  opacity: .3;
  pointer-events: none;
}









/* ChargerCardEventTab3 - FirmwareUpdate */
.cpo-firmware-update-top-container{
  display: flex;
  align-content: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.cpo-firmware-update-tab-container{
  margin-bottom: 40px;
  width: 370px;
  height: 50px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 2px solid var(--tabBorder);
}

.firmware-update-tab-button{
  border-radius: 7px 7px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}
.firmware-update-tab-bar{
  position: absolute;
  width: 50%;
  height: 3px;
  background: var(--emoovBlue);
  top: 47.5px;
  transition: .3s;
  transition: left 0.3s cubic-bezier(0.175, 0.35, 0.5, 1.13);
  z-index: 2;
}
.firmware-update-tab-button.selected p{
  color: var(--emoovBlue);
}
.firmware-update-tab-button p{
  margin: 0;
  color: var(--oxfordBlue1);
  font-weight: bold;
}
.firmware-update-tab-bar.tab0{
  left: 0;
}
.firmware-update-tab-bar.tab1{
  left: 50%;
}






.cpo-firmware-update-with-url-msg{
  position: absolute;
  bottom: 5px;
  left: 20px;
  cursor: pointer;
  color: var(--inputBorder);
  font-weight: 600;
  transition: .13s;
  border-bottom: 2px solid transparent;
}
.cpo-firmware-update-with-url-msg:hover{
  color: var(--emoovBlue);
  transition: .3s;
}
.cpo-firmware-update-with-url-msg:active{
  opacity: 0.3;
}

.cpo-firmware-update-item-list-container{
  width: 100%;
  min-height: 300px;
  border-top: 2px solid var(--inputBorder2);
  margin-top: 10px;
}
.cpo-firmware-update-item-list-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 3fr 80px;
  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;

  font-size: .9rem;
  color: var(--inputBorder2);
}
.cpo-firmware-update-list-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 3fr 80px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
}

.cpo-firmware-update-detail-button{
  cursor: pointer;
  color: var(--inputBorder);
  width:fit-content;
  border-bottom: 1px solid var(--inputBorder);
  
}
.cpo-firmware-update-list-item-apply-button{
  cursor: pointer;
  color: #fff;
  width:fit-content;
  height: 35px;
  border-radius: 8px;
  background: var(--emoovBlue);
  display: flex;
  align-items: center;justify-content: center;
  font-weight: 600;
  padding: 0 15px;
  transition: .13s;
}
.cpo-firmware-update-list-item-apply-button:active{
  opacity: 0.3;
}
.cpo-firmware-update-list-item-apply-button.disabled{
  opacity: 0.3;
  pointer-events: none;
}

.cpo-firmware-update-sub-title{
  font-weight: 600;
  margin-top: 0;
}
.cpo-firmware-update-sub-title>span{
  font-weight: 600;
  font-size: .9rem;
  color: var(--inputBorder);
  margin-left: 15px;
}
.cpo-firmware-update-info-row{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.cpo-firmware-update-info-row>p{
  margin: 0;
}
.cpo-firmware-update-info-row>p:first-child{
  color: var(--emoovBlue);
  font-weight: 600;
  margin-right: 15px;
}

.chargerCardEventTab3>div.cpo-firmware-update-step1-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 0;
}

.cpo-firmware-update-url-container{
  grid-column: -1/1;
  
}

.cpo-firmware-update-step1-button-container{
  grid-column: -1/1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.cpo-firmware-update-request-button{
  width: 100px;
  height: 45px;
  padding: 0 20px;
  border-radius: 8px;
  background: var(--emoovBlue);
  display: flex;
  align-items: center;justify-content: center;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: .13s;
}
.cpo-firmware-update-request-button.disabled{
  opacity: 0.3;
  pointer-events: none;
}
.cpo-firmware-update-request-button.cancel{
  background: var(--buttonBlack);
}

.cpo-firmware-update-request-button:active{
  opacity: 0.3;
}
.cpo-firmware-update-err-msg{
  position: absolute;
  right: 120px;
  text-align: right;
  margin: 0;
  color: var(--textRed1);
  font-weight: 600;
}


.cpo-firmware-update-step2-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cpo-firmware-update-step2-title{
  font-size: 1.5rem;
  font-weight: bold;
}
.cpo-firmware-update-step2-content{
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}
.cpo-firmware-update-step2-content>p:first-child{
  color: var(--emoovBlue);
  font-weight: 600;
  /* margin-right: 5px; */
}







/* ****************************************** */
/* *ChargerCardEventTab6 OCPP 16 인증 */
/* ****************************************** */
.ocpp16-auth-container{
  min-width: 800px;
}
.ocpp16-auth-title{
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 15px;
}
.ocpp16-auth-search-container{
  width: 300px;
  margin-bottom: 15px;
}
.ocpp16-auth-content-container{
  width: 100%;
}
.ocpp16-auth-message-select-alert-msg{
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--inputBorder);
  margin: 0;
}
.ocpp16-auth-message-block{
  display: grid;
  grid-template-rows: 50px auto;
}

.ocpp16-auth-message-block-header{
  display: flex;
  align-items: center;
}
.ocpp16-auth-message-block-header>p{
  margin: 0;
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--emoovBlue);
}
.ocpp16-auth-message-block-content{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;
}
.ocpp16-auto-message-block-input-wrapper{
  display: grid;
  grid-template-columns: 120px 1fr;
  column-gap: 15px;
  align-items: center;
}

.ocpp16-auto-message-block-input-wrapper.one-row{
  grid-column: -1/1;
  grid-template-columns: auto 1fr;
}
.ocpp16-auto-message-block-input-wrapper.textarea{
  grid-column: -1/1;
  grid-template-columns: 1fr;
}
.ocpp16-auto-message-block-input-wrapper.three-col{
  grid-column: -1/1;
  grid-template-columns: auto 1.2fr auto 1.2fr auto 1.2fr auto auto;
}
.ocpp16-auto-message-block-input-wrapper>p{
  margin: 0;
  font-size: .9rem;
  font-weight: 600;
  color: var(--inputBorder2);
}


.ocpp16-auth-message-block-footer{
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 20px;
}
.ocpp16-auth-message-block-button{
  width: fit-content;
  padding: 0 20px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: .13s;
}
.ocpp16-auth-message-block-button:active{
  opacity: 0.3;
}
.ocpp16-auth-message-block-button.disabled{
  opacity: 0.3;
  pointer-events: none;
}

.ocpp16-auto-message-block-input-container{
  width: 100%;
  height: 45px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  display: flex;align-items: center;
  position: relative;
}
.ocpp16-auto-message-block-input-container.text:has(input[type="text"]:focus){
  box-shadow: 0 0 0 1px var(--emoovBlue);
  border: 1px solid var(--emoovBlue);
}
.ocpp16-auto-message-block-input-container textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1rem;
  font-size: .85rem;
}
.ocpp16-auto-message-block-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.ocpp16-auto-message-block-input-container input::placeholder{
  color: #8b8b8b;
  font-weight: 600;
  font-size: 0.9rem;
}
.ocpp16-auto-message-block-input-container.textarea{
  height: 180px;
  padding: 10px;
  width: 100%;
}
.ocpp16-auto-message-block-input-container.selector{
  padding: 0;
  border: 1px solid transparent;
}

.ocpp16-charging-profile-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 30px;
}
.ocpp16-charging-profile-title{
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  color: var(--inputBorder2);
}
.ocpp16-auto-message-block-input-wrapper>.ocpp16-charging-profile-sub-title{
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
  color: var(--inputBorder2);
}
.ocpp16-auto-message-block-schedule-append-button{
  width: fit-content;
  height: 45px;
  padding: 0 20px;
  display: flex;
  align-items: center;justify-content: center;
  border-radius: 8px;
  background: var(--buttonBlack);
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: .13s;
}
.ocpp16-auto-message-block-schedule-append-button:active{
  opacity: 0.3;
}
.ocpp16-auto-message-block-schedule-append-button.disabled{
  opacity: 0.3;
  pointer-events: none;
}


.ocpp16-auth-append-profile-button{
  width: fit-content;
  height: 45px;
  padding: 0 20px;
  display: flex;
  align-items: center;justify-content: center;
  border-radius: 8px;
  background: var(--buttonBlack);
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: .13s;
  user-select: none;
}
.ocpp16-auth-append-profile-button:active{
  opacity: 0.3;
}
.ocpp16-auth-append-profile-button-container{
  display: flex;
  align-items: center;
  justify-content: end;
  /* margin-top: 20px; */
}
.ocpp16-auth-append-profile-button.delete{
  background: #fff;
  border: 1px solid var(--buttonBlack);
  color: var(--buttonBlack);
  margin-left: 10px;
}
.ocpp16-auth-append-profile-button.disabled{
  pointer-events: none;
  opacity: 0.3;
}
.ocpp16-auth-charging-profile-append-title-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.ocpp16-auth-message-block-msg-response{
  margin: 0;
  margin-right: 10px;
  font-size: 0.95rem;
  font-weight: 600;
}
.ocpp16-auth-message-block-msg-response.success{
  color: var(--textGreen);
}
.ocpp16-auth-message-block-msg-response.failure{
  color: var(--textRed1);
}