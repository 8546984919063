
/* ************************************************************************ */
/* *CustomChas */
/* ************************************************************************ */

.cpo-custom-chas-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpo-custom-chas-inner{
  width: 100%;
  max-width: 1150px;
  min-width: 1150px;
  height: 100%;
  position: relative;

}

.cpo-custom-chas-content-container{
  width: 100%;

}

.cpo-custom-chas-content-item-list-container{
  width: 100%;
  min-height: 300px;
  border-top: 2px solid var(--inputBorder2);
}
.cpo-custom-chas-content-item-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 80px;
  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;

  font-size: .9rem;
  color: var(--inputBorder2);
}
.cpo-custom-chas-content-item-column.edit{
  grid-template-columns: 50px 1.5fr 1fr 1fr 80px;

}

.cpo-custom-chas-content-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 80px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
}
.cpo-custom-chas-content-item.edit{
  grid-template-columns: 50px 1.5fr 1fr 1fr 80px;
  cursor: pointer;
}
.cpo-custom-chas-content-item.edit.selected{
  background: var(--emoovLightBlue2);
}

.cpo-custom-chas-content-toggle{
  width: 100%;
  height: 35px;
  border-radius: 100vh;
  display: flex;align-items: center;justify-content: center;
  border: 1px solid #F0F0F0;
  background: #F0F0F0;
  font-size: .9rem;
  color: var(--inputBorder);
}
.cpo-custom-chas-content-toggle.failure{
  border-color: var(--textRed);
  color: var(--textRed);
  background: #fff;
}
.cpo-custom-chas-content-toggle.success{
  border-color: var(--emoovBlue);
  color: var(--emoovBlue);
  background: #fff;
}


.cpo-custom-chas-function-container{
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 20px;
}
.cpo-custom-chas-function-button{
  width: fit-content;
  height: 45px;
  padding: 0 20px;
  font-weight: 500;
  border-radius: 8px;
  margin-left: 10px;
  display: flex;align-items: center;justify-content: center;
  background: #f0f0f0;
  cursor: pointer;
  transition: .13s;
  background: var(--emoovBlue);
  color: #fff;
}
.cpo-custom-chas-function-button:active{
  opacity: 0.3;
}
.cpo-custom-chas-function-button.copy{
  background: var(--buttonBlack);
}
.cpo-custom-chas-function-button.edit{
  border: 1px solid var(--emoovBlue);
  background: #fff;
  color: var(--emoovBlue);
}
.cpo-custom-chas-function-button.delete{
  border: 1px solid var(--textRed1);
  background: #fff;
  color: var(--textRed1);
}
.cpo-custom-chas-function-button.cancel{
  border: 1px solid var(--inputBorder2);
  background: #fff;
  color: var(--inputBorder2);
}
.cpo-custom-chas-function-button.disabled{
  pointer-events: none;
  opacity: 0.3;
}



/* ************************************************************************ */

.cpo-custom-chas-append-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-custom-chas-append-modal-container{
  position: relative;
  /* width: 950px; */
  /* height: 330px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
}
.cpo-custom-append-content-container{
  padding-bottom: 50px;
  min-width: 600px;
  /* min-height: 300px; */
}

.cpo-custom-append-content.tab-0{
  width: 600px;
}
.cpo-custom-append-content.tab-1.list-container{
  width: 800px;
}

.cpo-custom-append-modal-button{
  width: max-content;
  height: max-content;
  height: 38px;
  padding: 10px 10px 10px 15px;
  border-radius: 5px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  transition: .13s;
  cursor: pointer;
  display: flex;
  align-items: center;  justify-content: center;
  background: #3d3d3d;
  color: #fff; 
}
.cpo-custom-append-modal-button.left{
  right: 140px;
  padding: 10px 15px 10px 10px;
}
.cpo-custom-append-modal-msg{
  right: 300px;
  position: absolute;
  bottom: 35px;
  color: var(--textRed);
}
.cpo-custom-append-modal-button p{
  font-weight: bold;
  margin: 0;
}
.cpo-custom-append-modal-button:hover{
}
.cpo-custom-append-modal-button:active{
  opacity: 0.2;
}
.cpo-custom-append-modal-button.none{
  pointer-events: none;
  opacity: 0.2;
}
.cpo-system-chas-content-item-column.modal{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 50px 1.5fr 1fr 1fr;
  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;

  font-size: .9rem;
  color: var(--inputBorder2);
}

.cpo-system-chas-content-item.modal{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 50px 1.5fr 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
  cursor: pointer;
  transition: .13s;
}
.cpo-system-chas-content-item.modal.selected{
  background: var(--emoovLightBlue2);
}


.cpo-custom-chas-edit-content-container{
  background: #fafafa;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-top: 50px;
}
.cpo-custom-chas-edit-content-container>div{
  background: #fff;
  width: 100%;
  height: 100%;
  padding-top: 70px;
  position: relative;
  min-height: 500px;
  /* max-height: 1100px; */
}
.cpo-custom-chas-edit-left-content{
}
.cpo-custom-chas-edit-right-content{
  
}
.cpo-custom-chas-edit-content-header{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  height: 70px;
  width: 100%;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px; */
  background: var(--emoovBlue);
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.cpo-custom-chas-edit-left-content .cpo-custom-chas-edit-content-card-list{
  /* height: 870px; */
}
.cpo-custom-chas-edit-right-content .cpo-custom-chas-edit-content-card-list{
  /* height: 800px; */
  padding-bottom: 30px;
}
.cpo-custom-chas-edit-content-card-list{
  width: 100%;
  height: calc(100%);
  padding: 0 20px;
  padding-top: 10px;
  overflow-y: overlay;
  
}
.cpo-custom-chas-edit-message-card-container{
  width: 100%;
  height: 100px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  margin: 15px 0;
  padding-left: 40px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: .3s;
}
.cpo-custom-chas-edit-message-card-container.selected{
  transform: translateX(-15px);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px, rgba(0, 0, 0, 0.16) 0px 1px 10px;
}
.cpo-custom-chas-edit-message-card-status{
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  
  display: flex; flex-direction: column;
  align-items: center;
}
.cpo-custom-chas-edit-message-card-status>div{
  width: 30px;
  height: 30px;
  border-radius: 100vh;
  margin-top: 5px;
  background: #fff;
  display: flex;
  align-items: center;justify-content: center;
}
.cpo-custom-chas-edit-message-card-status.valid{
  background: var(--emoovLightBlue);
  color: var(--emoovBlue);
}
.cpo-custom-chas-edit-message-card-status.invalid{
  background: var(--textRed1);
  color: var(--textRed);
}
.cpo-custom-chas-edit-message-card-content{
  padding-left: 10px;
  display: grid;
  grid-template-rows: 40px 1fr;
  height: 100%;
  position: relative;
}
.cpo-custom-chas-dataTransfer-msg-delete-button{
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  transition: .13s;
}
.cpo-custom-chas-dataTransfer-msg-delete-button:hover{
  color: var(--textRed1);
}
.cpo-custom-chas-dataTransfer-msg-delete-button:active{
  opacity: 0.3;
}
.cpo-custom-chas-edit-message-card-badge{
  position: absolute;
  top: 8px;
  right: 8px;
  background: #fff;
  display: flex;align-items: center;justify-content: center;
  width: fit-content;
  padding: 2px 7px;
  border-radius: 100vh;
  font-size: .9rem;
  font-weight: 600;
  border: 3px solid var(--inputBorder3);
  color: var(--inputBorder3);
}
.cpo-custom-chas-edit-message-card-badge.mandatory{
  border: 3px solid var(--emoovBlue);
  color: var(--emoovBlue);
}

.cpo-custom-chas-edit-message-card-title{
  font-size: 1.2rem;
  font-weight: 600;
  display: flex; align-items: center;
}

.cpo-custom-chas-edit-content-action-function{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.cpo-custom-chas-edit-content-action-index-container{
  display: flex;align-items: end;
}
.cpo-custom-chas-edit-content-action-index-container>div{
  padding-left: 30px;
}
.cpo-custom-chas-edit-content-action-index-container>div:first-child{
  width: 60px;
}
.cpo-custom0chas-edit-content-modal-button{
  width: fit-content;
  height: 45px;
  padding: 0 20px;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  background: var(--emoovBlue);
  display: flex;align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.cpo-custom0chas-edit-content-modal-button:active{
  opacity: 0.3;
}

.cpo-custom-chas-edit-action-card-container{
  width: 100%;
  display: grid;
  grid-template-columns: 50px 1fr;
  column-gap: 15px;
}
.cpo-custom-chas-edit-action-order{
  display: flex; flex-direction: column; align-items: center;
  position: relative;
}
.cpo-custom-chas-edit-action-order>div:first-child{
  background: var(--emoovBlue);
  border-radius: 100vh;
  display: flex;align-items: center;justify-content: center;
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  z-index: 2;
}
.cpo-custom-chas-edit-action-flow-bar{
  position: absolute;
  height: 90%;
  width: 15px;
  bottom: -10px;
  background: var(--emoovBlue);
}
.cpo-custom-chas-edit-action-card{
  border-radius: 8px;
  background: #fff;

  width: 100%;
  min-height: 100px;
  height: fit-content;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;

  padding: 10px;
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 10px;
}

.cpo-custom-chas-edit-action-card-title{
  font-size: 1.2rem;
  font-weight: 600;
  display: flex; align-items: center;
}

.cpo-custom-chas-edit-content-payload-container{
  padding: 10px;
}
.cpo-custom-chas-edit-content-payload{
  width: 100%;
  height: 300px;
  background: #3d3d3d;
  border-radius: 8px;
  display: flex;align-items: center;justify-content: center;
  font-weight: 600;
  color: #fff;
  font-size: 1.2rem;
}




/* ************************************************************************ */
.cpo-custom-chas-edit-back-button{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: .13s;
}
.cpo-custom-chas-edit-back-button:active{
  opacity: 0.3;
}
.cpo-custom-chas-edit-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpo-custom-chas-edit-inner{
  width: 100%;
  max-width: 1150px;
  min-width: 1150px;
  height: 100%;
  position: relative;
}
.cpo-chas-description{
  margin-top: 15px;

}
.cpo-custom-chas-edit-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cpo-custom-chas-edit-title-container{
  display: flex;
  align-items: center;
}
.cpo-custom-chas-edit-title-input-container{
  width: 100%;
  height: 45px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder);
}
.cpo-chas-duplication-modal-input-container textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1.5rem;
  font-size: 1rem;
}
.cpo-chas-duplication-modal-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-custom-chas-edit-save-button{

}

.cpo-custom-chas-tab-container{
  margin-top: 20px;
  width: 350px;
  height: 50px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 2px solid var(--tabBorder);
}

.custom-chas-tab-button{
  border-radius: 7px 7px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}
.custom-chas-tab-bar{
  position: absolute;
  width: 50%;
  height: 3px;
  background: var(--emoovBlue);
  top: 47.5px;
  transition: .3s;
  transition: left 0.3s cubic-bezier(0.175, 0.35, 0.5, 1.13);
  z-index: 2;
}
.custom-chas-tab-button.selected p{
  color: var(--emoovBlue);
}
.custom-chas-tab-button p{
  margin: 0;
  color: var(--oxfordBlue1);
  font-weight: bold;
}
.custom-chas-tab-bar.tab0{
  left: 0;
}
.custom-chas-tab-bar.tab1{
  left: 50%;
}
.cpo-custom-chas-edit-message-append-button-container{
  display: flex; align-items: center; justify-content: end;
  width: 100%;
  margin: 10px 0;
}
.cpo-custom-chas-edit-message-append-button{
  height: 45px;
  width: fit-content;
  padding: 0 20px;
  margin-right: 10px;
  border-radius: 8px;
  background: var(--emoovBlue);
  display: flex;align-items: center;justify-content: center;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: .13s;
}
.cpo-custom-chas-edit-message-append-button:active{
  opacity: 0.3;
}


.cpo-custom-chas-append-modal-content-container{
  margin-bottom: 20px;
}
.cpo-custom-chas-append-modal-content-container>p{
  font-weight: bold;
  margin-bottom: 15px;
}
.cpo-custom-chas-append-modal-grid-wrapper{
  display: grid;
  grid-template-columns: 1fr 100px;
  column-gap: 10px;
  margin-bottom: 40px;
  position: relative;
}
.cpo-custom-chas-append-modal-input-container{
  width: 100%;
  height: 45px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder);
}
.cpo-custom-chas-append-modal-input-container textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1.5rem;
  font-size: 1rem;
}
.cpo-custom-chas-append-modal-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-custom-chas-append-check-button{
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .13s;
  cursor: pointer;
}
.cpo-custom-chas-append-check-button:active{
  opacity: 0.3;
}
.cpo-custom-chas-append-check-info{
  position: absolute;
  bottom: -40px;
  left: 10px;
  font-size: 0.9rem;
  color: var(--textRed);
}
.cpo-custom-chas-append-check-info.valid{
  color: var(--emoovBlue);
}
.cpo-custom-chas-append-modal-input-container.textarea{
  height: 90px;
  padding: 10px;
}

.cpo-custom-append-modal-button.disabled{
  pointer-events: none;
  opacity: 0.3;
}

.cpo-custom-chas-msg{
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 0.9rem;
  color: var(--textRed);
}






/* ************************************************************************ */
/* *CustomChas Action Modal */
/* ************************************************************************ */
.cpo-custom-chas-action-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-custom-chas-action-modal-container{
  position: relative;
  /* width: 950px; */
  /* height: 330px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
  max-width: 1100px;
  height: 100%;
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
}
.cpo-custom-chas-action-modal-content{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 45px calc(100% - 45px)
}
.cpo-custom-chas-action-modal-content-header{
  display: flex;align-items: center;justify-content: space-between;
}
.cpo-custom-chas-action-modal-content-header>p{
  position: absolute;
  bottom: -20px;
  right: 0;
  color: var(--textRed1);
}
.cpo-custom-chas-action-modal-info{
  display: flex;align-items: center;
}
.cpo-custom-chas-action-modal-info>div:first-child{
  font-size: 1.5rem;
  font-weight: 600;
}
.cpo-custom-chas-action-modal-info>div:nth-child(2){
  margin-left: 10px;

}
.cpo-custom-chas-action-modal-save-button{
  height: 45px;
  width: fit-content;
  padding: 0 20px;
  border-radius: 8px;
  background: var(--emoovBlue);
  display: flex;align-items: center;justify-content: center;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: .13s;
}
.cpo-custom-chas-action-modal-save-button:active{
  opacity: 0.3;
}
.cpo-custom-chas-action-modal-save-button.disabled{
  pointer-events: none;
  opacity: 0.3;
}
.cpo-custom-chas-action-modal-card{
  border-radius: 8px;
  background: #fff;

  width: 100%;
  height: 100px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;

  padding: 0 10px;
  display: grid;
  grid-template-rows: 40px 1fr;
  cursor: pointer;
}
.cpo-custom-chas-action-modal-card.selected{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px, 0 0 0 3px var(--emoovBlue);
  background: var(--emoovLightBlue2);
}
.cpo-custom-chas-action-modal-card.disable{
  cursor: default;
  padding-right: 1.2rem;
}
.cpo-custom-chas-action-modal-card>svg{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.2rem;
}
.cpo-custom-chas-action-modal-card-title{
  font-size: 1.2rem;
  font-weight: 600;
  display: flex; align-items: center;
}





/* ************************************************************************ */
.datatransfer-function-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.datatransfer-function-modal-container{
  position: relative;
  /* width: 950px; */
  /* height: 330px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
  max-width: 1100px;
  height: fit-content;
  max-height: 100%;
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
}
.datatransfer-function-modal-content{
  width: 100%;
  min-width: 500px;
  padding-bottom: 50px;
}
.datatransfer-function-modal-button{
  position: absolute;
  right: 20px;
  bottom: 20px;
  border-radius: 8px;
  background: var(--buttonBlack);
  height: 45px;
  width: fit-content;
  padding: 0 20px;
  display: flex;align-items: center;justify-content: center;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: .13s;
}
.datatransfer-function-modal-button:active{
  opacity: 0.3;
}
.datatransfer-function-modal-button.disabled{
  pointer-events: none;
  opacity: 0.3;
}
.datatransfer-function-modal-error-msg{
  position: absolute;
  left: 20px;
  bottom: 20px;
  color: var(--textRed1);
}



/************************************************************/
/** SystemChas  */
/************************************************************/
.cpo-system-chas-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: max(calc(100vh - 190px), 900px) ;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpo-system-chas-inner{
  width: 100%;
  max-width: 1150px;
  min-width: 1150px;
  height: 100%;
  position: relative;

}

.cpo-system-chas-content-container{
  width: 100%;

}

.cpo-system-chas-content-item-list-container{
  width: 100%;
  min-height: 300px;
  border-top: 2px solid var(--inputBorder2);
  margin-top: 50px;
}
.cpo-system-chas-content-item-column{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 100px 400px 60px;

  column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;

  font-size: .9rem;
  color: var(--inputBorder2);
}

.cpo-system-chas-content-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 80px;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
}

.cpo-system-chas-detail-button{
  cursor: pointer;
  color: var(--inputBorder);
  width:fit-content;
  border-bottom: 1px solid var(--inputBorder);
}

/************************************************************/
/** CpoActionSetProfileListItem  */
/************************************************************/

.cpo-action-set-profile-list-item-container{
  width: 100%;
  /* min-height: 300px; */
}


.cpo-action-set-profile-list-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 100px 400px 60px;

  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;
  cursor: pointer;
}
.cpo-action-set-profile-list-item>div:last-child{
  display: flex;
  align-items: center;
  justify-content: end;
}
.cpo-action-set-profile-list-item>div:last-child>svg{
  margin-left: 5px;
}
.cpo-action-set-profile-list-item-content{
  background: #F7F7F7;
  visibility: hidden;
  max-height: 0;
}
.cpo-action-set-profile-list-item>div>p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.cpo-action-set-profile-list-item-container.open .cpo-action-set-profile-list-item-content{
  visibility: visible;
  max-height: none;
  border-bottom: 1px solid var(--inputBorder3);
}
.cpo-action-set-profile-list-item-container.open .cpo-action-set-profile-list-item>div>svg{
  transform: rotate(-180deg);
}

.cpo-action-set-profile-version-list-container{
  width: 100%;
}
.cpo-action-set-profile-version-list-item-column{
  width: calc(100% - 30px);
  height: 50px;
  display: grid;
  grid-template-columns: 30px 1fr 4fr auto;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  margin: 0 15px;
  column-gap: 10px;
  font-size: .9rem;
}
.cpo-action-set-profile-version-list-item-column>div:last-child{
  text-align: right;
}
.cpo-action-set-profile-version-list-item-container{
  width: 100%;
  height: 200px;
  overflow-y: overlay;
  scrollbar-gutter: stable;
  padding-left: 15px;
}

.cpo-action-set-profile-version-list-item{
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 30px 1fr 4fr auto;
  align-items: center;
  border-bottom: 1px solid var(--inputBorder3);
  padding: 0 20px;
  column-gap: 10px;
  font-size: .9rem;

}
.cpo-action-set-profile-version-list-item>div{
  display: flex;
  align-items: center;
}





/************************************************************/
/** ChasPreviewModal  */
/************************************************************/
.cpo-chas-preview-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-chas-preview-modal-container{
  position: relative;
  /* width: 950px; */
  /* height: 330px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
  max-width: 1100px;
  min-width: 1100px;
  /* height: 100%; */
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
}
.cpo-chas-preview-modal-content{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 45px calc(100% - 45px)
}
.cpo-chas-preview-modal-action-set-content-container{
  width: 100%;
  height: 100%;
}



/************************************************************/
/** ChasCopyModal  */
/************************************************************/
.cpo-chas-copy-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-chas-copy-modal-container{
  position: relative;
  /* width: 950px; */
  /* height: 330px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
  /* max-width: 1100px; */
  min-width: 500px;
  /* height: 100%; */
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
}
.cpo-chas-copy-modal-content{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 45px calc(100% - 45px)
}

.cpo-chas-copy-modal-content-container{
  margin-bottom: 20px;
}
.cpo-chas-copy-modal-content-container>p{
  font-weight: bold;
  margin-bottom: 15px;
}


.cpo-chas-copy-modal-copy-button-container{
  display: flex;
  align-items: center;
  justify-content: end;
}
.cpo-chas-copy-modal-copy-button{
  width: fit-content;
  height: 45px;
  padding: 0 20px;
  font-weight: 500;
  border-radius: 8px;
  margin-left: 10px;
  display: flex;align-items: center;justify-content: center;
  background: #f0f0f0;
  cursor: pointer;
  transition: .13s;
  background: var(--emoovBlue);
  color: #fff;
}

.cpo-chas-copy-modal-grid-wrapper{
  display: grid;
  grid-template-columns: 1fr 100px;
  column-gap: 10px;
  margin-bottom: 40px;
  position: relative;
}
.cpo-chas-copy-modal-input-container{
  width: 100%;
  height: 45px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder);
}
.cpo-chas-copy-modal-input-container textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1.5rem;
  font-size: 1rem;
}
.cpo-chas-copy-modal-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.cpo-chas-name-check-button{
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .13s;
  cursor: pointer;
}
.cpo-chas-name-check-button:active{
  opacity: 0.3;
}
.cpo-chas-name-check-info{
  position: absolute;
  bottom: -40px;
  left: 10px;
  font-size: 0.9rem;
  color: var(--textRed);
}
.cpo-chas-name-check-info.valid{
  color: var(--emoovBlue);
}
.cpo-chas-copy-modal-input-container.textarea{
  height: 90px;
  padding: 10px;
}

.cpo-chas-copy-modal-copy-button.disabled{
  pointer-events: none;
  opacity: 0.3;
}

.cpo-chas-name-check-msg{
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 0.9rem;
  color: var(--textRed);
}



/* ************************************************************ */
/* *ChasInfoEditModal */
/* ************************************************************ */

.chas-info-edit-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.chas-info-edit-modal-container{
  position: relative;
  /* width: 950px; */
  /* height: 330px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  overflow: hidden;
  padding: 20px;
  /* max-width: 1100px; */
  min-width: 500px;
  /* height: 100%; */
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
}
.chas-info-edit-modal-content{
  width: 100%;
  height: 100%;
  /* display: grid;
  grid-template-rows: 45px calc(100% - 45px) */
}


.chas-info-edit-modal-content-container{
  margin-bottom: 20px;
}
.chas-info-edit-modal-content-container>p{
  font-weight: bold;
  margin-bottom: 15px;
}
.chas-info-edit-modal-grid-wrapper{
  display: grid;
  grid-template-columns: 1fr 100px;
  column-gap: 10px;
  margin-bottom: 40px;
  position: relative;
}
.chas-info-edit-modal-input-container{
  width: 100%;
  height: 45px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder);
}
.chas-info-edit-modal-input-container textarea{
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1.5rem;
  font-size: 1rem;
}
.chas-info-edit-modal-input-container input{
  all: unset;
  width: 100%;
  height: 100%;
}
.chas-info-edit-check-button{
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .13s;
  cursor: pointer;
}
.chas-info-edit-check-button:active{
  opacity: 0.3;
}
.chas-info-edit-check-button.disabled{
  pointer-events: none;
  opacity: 0.3;
}
.chas-info-edit-check-info{
  position: absolute;
  bottom: -40px;
  left: 10px;
  font-size: 0.9rem;
  color: var(--textRed);
}
.chas-info-edit-check-info.valid{
  color: var(--emoovBlue);
}
.chas-info-edit-modal-input-container.textarea{
  height: 90px;
  padding: 10px;
}

.chas-info-edit-modal-button.disabled{
  pointer-events: none;
  opacity: 0.3;
}

.cpo-custom-chas-msg{
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 0.9rem;
  color: var(--textRed);
}
.chas-info-edit-modal-request-button-container{
  display: flex;
  align-items: center;
  justify-content: end;
}
.chas-info-edit-modal-request-button{
  width: fit-content;
  height: 45px;
  padding: 0 20px;
  border-radius: 8px;
  background: var(--emoovBlue);
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .13s;
  cursor: pointer;
}
.chas-info-edit-modal-request-button:active{
  opacity: 0.3;
}
.chas-info-edit-modal-request-button.disabled{
  pointer-events: none;
  opacity: 0.3;
}





/* ************************************************************ */
/* CustomChasDeleteModal */
/* ************************************************************ */

.custom-chas-delete-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.custom-chas-delete-modal-container{
  position: relative;
  min-width: 500px;
  min-height: 330px;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px;
  z-index: 1000;
  display: grid;
  grid-template-rows: auto 1fr 50px;
}
.custom-chas-delete-modal-header{
  width: 100%;
  margin-bottom: 30px;
}
.custom-chas-delete-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.custom-chas-delete-modal-list-wrapper{
  max-height: 300px;
  overflow-y: scroll;
}
.custom-chas-delete-modal-msg{
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}
.custom-chas-delete-modal-item{
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 15px 0;
}

.custom-chas-delete-modal-container>div:last-child{
  display: flex;align-items: center;
  justify-content: space-between;
  
}
.custom-chas-delete-modal-delete-button{
  width: 	100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  transition: .13s;
  background: var(--cardBtnRed);
  cursor: pointer;
}
.custom-chas-delete-modal-delete-button:active{
  opacity: 0.3;
}
.custom-chas-delete-modal-delete-button p{
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
  color: var(--textRed1);
}

.custom-chas-delete-modal-delete-button.navigate{
  background: var(--buttonBlack);
  color: #fff;
}
.custom-chas-delete-modal-delete-button.navigate>p{
  color: #fff;
}