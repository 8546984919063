/* InstallManagement */
.cpo-install-management-container{
  width: 100%;
  /* max-width: 1500px; */
  height: fit-content;
  min-height: calc(100vh - 190px);
  min-height: 800px;
  /* border: 1px solid #000; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
}
.cpo-install-management-inner{
  width: 100%;
  height: 100%;
  max-width: 1200px;
  position: relative;
}


.cpo-install-function-button-container{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.cpo-install-function-button{
  width: fit-content;
  height: 45px;
  padding: 0 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
  font-weight: 500;
}
.cpo-install-function-button.enroll{
  background: var(--emoovBlue);
  color: #fff;
  margin-right: 10px;
  position: relative;
}
.cpo-install-function-button.delete{
  border: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
  transition: .3s;
}
.cpo-install-function-button.cancel{
  border: 1px solid var(--inputBorder3);
  color: var(--inputBorder2);
  margin: 0 10px;
}
.cpo-install-function-button.delete.activate{
  border: 1px solid var(--emoovBlue);
  color: var(--emoovBlue);
}
.cpo-install-function-button:active{
  opacity: 0.3;
}

.cpo-install-function-button-container>p{
  color: var(--inputBorder2);
  margin: 0;
}



.cpo-install-list-container{
  width: 100%;
  border-top: 2px solid var(--inputBorder3);
}



/* CpoInstallListItem */
.cpo-install-list-item-container{
  width: 100%;
  height: fit-content;

}
.cpo-install-list-item-header{
  width: 100%;
  height: 60px;
  background: var(--emoovLightBlue2);
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 120px 50px;
  cursor: pointer;
}
.cpo-install-list-item-header>div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.cpo-install-list-item-header>div:nth-child(1){
  justify-content: start;
}
.cpo-install-list-item-title{
  padding-left: 20px;
}
.cpo-install-list-item-title>div{
  width: 0;
  visibility: hidden;
}
.cpo-install-list-item-title>div.visible{
  margin-right: 20px;
  width: fit-content;
  visibility:visible;
}
.cpo-install-list-item-company-name{
  font-weight: 600;
  margin-right: 10px;
}
.cpo-install-list-item-company-category{
  font-size: .9rem;
  color: var(--textGray3);
}

.cpo-install-list-item-admin-enroll-button{
  display: flex;align-items: center;justify-content: center;
  font-size: .9rem;
  color: var(--emoovBlue);
  cursor: pointer;
  transition: .13s;
}
.cpo-install-list-item-admin-enroll-button p{
  margin-left: 5px;
  font-weight: 500;
}

.cpo-install-list-item-chevron-container{
  font-size: 0.8em;
  transition: .3s;
}
.cpo-install-list-item-container.open .cpo-install-list-item-chevron-container{
  transform: rotate(-180deg);
}


.cpo-install-list-item-content{
  max-height: 0;
  opacity: 0;
  visibility:hidden;
}
.cpo-install-list-item-content p{
  margin: 0;
}
.cpo-install-list-item-container.open .cpo-install-list-item-content{
  max-height: none;
  opacity: 1;
  visibility:visible;
  padding-bottom: 20px;
}
.cpo-install-list-item-admin-item-column{
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1.2fr 1fr 0.5fr;
  padding: 0 30px;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid var(--inputBorder3);
  font-size: .8rem;
  color: var(--inputBorder2);
}
.cpo-install-list-item-admin-item{
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1.2fr 1fr 0.5fr;
  padding: 0 30px;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid var(--inputBorder3);
  position: relative;
}


.cpo-install-list-item-function-button-container{
  height: 40px;
  width: fit-content;
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: .3s;
}
.cpo-install-list-item-function-button-container{
  font-size: 1.5rem;
}
.cpo-install-list-item-function-button-container>svg{
  cursor: pointer;
}
.cpo-install-list-item-function-button-container:hover{
  /* background: #e4e8e9; */
}
.cpo-install-list-item-function-button-container:has(.cpo-install-list-item-function-button-list.open){
  box-shadow: 0px 0px 4px 0px #00000040;
  
}
.cpo-install-list-item-function-button-list{
  display: flex;
  align-items: center;
  justify-content: center;
  visibility:hidden;
  max-width: 0;
  
}
.cpo-install-list-item-function-button-list.open{
  max-width: 150px;
  visibility: visible;
}
.cpo-install-list-item-function-button-list>div:first-child::after{
  content: "";
  display: block;
  position: absolute;
  right: 0px;
  width: 1.2px;
  height: 50%;
  background: #E2E2E2;
}
.cpo-install-list-item-function-button-list>div{
  height: 40px;
  width: 50px;
  /* margin-left: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cpo-install-list-item-function-button{
  width: 85%;
  height: 85%;
  /* border-radius: 100vh; */
  /* background: #f6fbfc; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* transition: .1s; */
}
.cpo-install-list-item-function-button:active{
  opacity: 0.5;
}
.cpo-install-list-item-function-button>div{
  width: 85%;
  height: 85%;
  /* border-radius: 100vh; */
  /* background: var(--buttonBlueOuter); */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6E6E6E;
  transition: background .3s;
  font-size: 1.2rem;
}
.cpo-install-list-item-function-button>div:hover{
  /* background: #b4eafa; */
}
.cpo-install-list-item-function-button.delete>div{
  /* background: var(--buttonRed); */
  color: #FF295D;
  transition:background .3s;
  font-size: 1.1rem;
}
.cpo-install-list-item-function-button.delete>div:hover{
  /* background: #ffa4a4; */
}
.cpo-install-list-item-function-button>div p{
  margin: 0;
  font-weight: bold;
}







/* InstallEnrollModal */
.cpo-install-enroll-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  justify-content: center;
  background: #b4b4b44c;
  z-index: 110;
  padding: 50px 0;
}
.cpo-install-enroll-modal-container{
  position: relative;
  max-width: 1100px;
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* overflow: hidden; */
  padding: 20px 30px;
  z-index: 1000;
}
.cpo-install-enroll-modal-header{
  width: 100%;
  margin-bottom: 30px;
  display: flex; align-items: center;
}
.cpo-install-enroll-modal-header title{
  display: inline;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}
.cpo-install-enroll-modal-close-button{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: .13s;
}
.cpo-payment-function-modal-close-button:active{
  opacity: 0.3;
}


.cpo-install-enroll-modal-content{
  width: 500px;
}
.cpo-install-enroll-modal-content>div{
  margin: 20px 0;
}
.cpo-install-enroll-modal-content>div:nth-child(2){
  margin-top: 40px;
}
.cpo-install-enroll-modal-content-header{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.cpo-install-enroll-modal-content-title{
  color: var(--emoovBlue);
  font-weight: 600;
  margin: 0;
  font-size: 1.2rem;
}
.cpo-install-enroll-modal-content-desc{
  font-size: .9rem;
  margin-left: 5px;
  color: var(--textGray3);
}

.cpo-install-enroll-modal-content-wrapper{
  width: 100%;
  margin-bottom: 15px;
}
.cpo-install-enroll-modal-content-wrapper.divide{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}
.cpo-install-enroll-modal-content-wrapper>p{
  font-weight: 500;
  margin: 0;
  margin-bottom: 15px;
}
.cpo-install-enroll-modal-content-input-container{
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--inputBorder3);
  padding: 0 10px;
}
.cpo-install-enroll-modal-content-input-container input{
  all: unset;
  height: 100%;
  width: 100%;
}
.cpo-install-enroll-modal-content-selector-container{
  width: 100%;
  border-radius: 8px;
  height: 45px;
}
.cpo-install-enroll-modal-content-selector-container .selector{

  border: 1px solid var(--inputBorder3);
  
}







.cpo-install-enroll-modal-button-container{
  display: flex;
  align-items: center;
  justify-content: end;
}
.cpo-install-enroll-modal-button{
  height: 45px;
  padding: 0 15px;
  border-radius: 8px;
  background: #3d3d3d;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.cpo-install-enroll-modal-button:active{
  opacity: 0.3;
}