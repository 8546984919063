.main-introduce-container{
  width: 1200px;
  padding-bottom: 300px;
  background: #fff;
}
.main-introduce-header{
  position: relative;
  width: 100%;
  height: 580px;
}
.main-introduce-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.main-introduce-bg>img{
  width: 100%;
  object-fit: scale-down;
  z-index: 1;
}

.main-introduce-title-container{
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  color: #fff;;
}
.main-introduce-title-container h1{
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  text-shadow: 0px 0px 14px #00000014;

}

.main-introduce-title-container p{
  font-size: 1.1em;
  font-weight: 500;
  text-shadow: 0px 0px 14px #00000014;
  text-align: center;
  line-height: 2rem;
}

.main-introduce-subscription-button{
  width: fit-content;
  height: 55px;
  padding: 0 30px;
  border-radius: 100vh;
  display: flex;align-items: center;justify-content: center;
  background: #fff;
  margin-top: 50px;
  box-shadow: 0px 0px 14px 0px #00000014;
  cursor: pointer;
  transition: .13s;
}
.main-introduce-subscription-button:active{
  opacity: 0.3;
}
.main-introduce-subscription-button p{
  margin: 0;
  background : linear-gradient(90deg, #6360B8 0%, #299FBF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
}

.main-introduce-content-section{
  width: 100%;
}


.main-introduce-text-title-block-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 100px 0;
}
.main-introduce-text-title-block-container>p{
  color: #5A5A5A;
  font-weight: 600;
  margin-bottom: 30px;
}
.main-introduce-text-title-block-container>h2{
  color: #111111;
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  margin: 0;
  line-height: 3.5rem;
}
.main-introduce-text-title-block-container>h2>color{
  display: inline;
  color: var(--emoovBlue);
}

.main-introduce-text-block-container{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-introduce-text-block-container>h3{
  font-size: 1.5rem;
  text-align: center;
  line-height: 3rem;
  font-weight: 600;
  margin: 0;
}
.main-introduce-text-block-container>h3>color{
  display: inline;
  color: var(--emoovBlue);
}

.main-introduce-image-block-grid{
  width: 100%;
  padding: 0 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 20px 0;
}
.main-introduce-image-block-container{
  width: 250px;
  height: 250px;
  display: flex;align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: #F9F9F9;
  border-radius: 8px;
  padding: 15px 0;
}
.main-introduce-image-block-container>p{
  font-weight: 600;
  text-align: center;
  line-height: 2rem;
  margin: 0;
}
.main-introduce-image-block-container>p>color{
  display: inline;
  color: var(--emoovBlue);
}
.main-introduce-image-block-container.oneline{
  padding: 30px 0;
}

.main-introduce-spacer{
  width: 100%;
  height: 100px;
}
.main-introduce-dis-spacer{
  width: 100%;
  height: 0;
  margin: -200px;
}


.main-introduce-service-block-grid{
  width: 100%;
  padding: 0 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 20px 0;
  column-gap: 20px;
  row-gap: 20px;
}
.main-introduce-service-block-container{
  height: 230px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px #0000001A;
}
.main-introduce-service-block-image-header{
  width: 100%;
}
.main-introduce-service-block-image-header>img{
  width: 100%;
}
.main-introduce-service-block-content-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.main-introduce-service-block-content-container>p{
  font-weight: 600;
}
.main-introduce-service-block-content-container>pre{
  font-size: 0.9rem;
}


.main-introduce-bg-content-container{
  width: 100%;
  height: 1000px;
  position: relative;
  margin-top: 200px;
  margin-bottom: 200px;
  padding-top: 10px;
  z-index: 10;
}
.main-introduce-bg-content-container>.main-introduce-bg{
  height: 100%;
}

.main-introduce-image-slider-container{
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 100px;
}
.main-introduce-image-slider{
  position: relative;
  height: 100%;
  width: 900px;
  border: 6px solid #92AEB680;
  border-radius: 32px;
  background: #fff;
  z-index: 10;
  overflow: hidden;
}
.main-introduce-image-slider>img{
  width: 100%;
  height: 100%;
  user-select: none;
}
.main-introduce-image-slider-button{
  z-index: 10;
}
.main-introduce-image-slider-button>svg{
  font-size: 5rem;
  cursor: pointer;
  transition: .13s;
  color: var(--inputBorder);
}
.main-introduce-image-slider-button>svg:active{
  transform: scale(80%);
}

.main-introduce-image-slider-indicator-container{
  position: absolute;
  bottom: -40px;
  display: flex;
  align-items: center;
  z-index: 10;
}
.main-introduce-image-slider-indicator{
  width: 13px;
  height: 13px;
  border-radius: 100vh;
  background: #E2E2E2;
  margin: 0 5px;
  cursor: pointer;
}
.main-introduce-image-slider-indicator.selected{
  background: var(--inputBorder);
}

.main-introduce-three-text-block-container{
  width: 100%;
  height: 250px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 40px;
  padding: 0 50px;
}

.main-introduce-three-text-block-item{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.main-introduce-three-text-block-item>h4{
  font-weight: 600;
  margin: 0;
  font-size: 1.2rem;
  color: var(--emoovBlue);
}
.main-introduce-three-text-block-item>pre{
  font-size: 1rem;
  text-align: center;
  line-height: 1.8rem;
  color: #282828;
}


.main-introduce-flex-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 1000px;
  position: relative;
  align-items: center;
  padding: 0 50px;
}

.main-introduce-flex-wrapper>.main-introduce-divide{
  position: absolute;
  width: 100%;
  height: 300px;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(90deg, #D4F1FC 0%, #F4FDFF 100%);
}




.main-introduce-phone-image-slider-container{
  width: 100%;
  height: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.main-introduce-phone-image-slider{
  position: relative;
  height: 100%;
  width: 350px;
  border: 8px solid #000;
  border-radius: 32px;
  background: #fff;
  z-index: 10;
  overflow: hidden;
}
.main-introduce-phone-image-slider>img{
  width: 100%;
  height: 100%;
  user-select: none;
}
.main-introduce-phone-image-slider-button{
  z-index: 10;
}
.main-introduce-phone-image-slider-button>svg{
  font-size: 5rem;
  cursor: pointer;
  transition: .13s;
  color: var(--inputBorder);
}
.main-introduce-phone-image-slider-button>svg:active{
  transform: scale(80%);
}

.main-introduce-phone-image-slider-indicator-container{
  position: absolute;
  bottom: -40px;
  display: flex;
  align-items: center;
  z-index: 10;
}
.main-introduce-phone-image-slider-indicator{
  width: 13px;
  height: 13px;
  border-radius: 100vh;
  background: #E2E2E2;
  margin: 0 5px;
  cursor: pointer;
}
.main-introduce-phone-image-slider-indicator.selected{
  background: var(--inputBorder);
}


.main-introduce-application-info-container{
  display: grid;
  grid-template-rows: 0.8fr repeat(3,1fr);
  z-index: 11;
  height: 600px;
  padding-left: 100px;
  position: relative;
}
.main-introduce-application-info-title{
  width: 100%;height: 100%;
}
.main-introduce-application-info-title p{
  font-size: 0.9rem;
  font-weight: 700;
}
.main-introduce-application-info-title h2{
  position: relative;
  margin: 0;
}
.main-introduce-application-info-title h2>color{
  display: inline;
  color: var(--emoovBlue);
}
.main-introduce-application-info-content{
  width: 100%;height: 100%;background: #fff;
  border-radius: 30px;
  box-shadow: 0px 0px 14px 0px #0000001A;
  padding: 30px;
}
.main-introduce-application-info-content>p{
  font-weight: 600;
  margin: 0;
  margin-bottom: 15px;
  font-size: 1.2rem;
}
.main-introduce-application-info-content>pre{
  margin: 0;
  font-size: 1rem;
  line-height: 1.6rem;
}

.main-introduce-application-info-content:nth-child(2){transform: rotate(5.95deg);}
.main-introduce-application-info-content:nth-child(3){transform: rotate(-4.6deg);}
.main-introduce-application-info-content:nth-child(4){transform: rotate(3.62deg);}
.main-introduce-application-info-button{
  width: fit-content;
  height: 45px;
  padding: 0 25px;
  background: #000;
  border-radius: 100vh;
  display: flex;align-items: center;justify-content: center;
  color: #fff;
  font-weight: 500;
  position: absolute;
  bottom: -25px;
  right: 20px;
  cursor: pointer;
  transition: .13s;
}
.main-introduce-application-info-button:active{
  opacity: 0.3;
}


.main-introduce-diagram-container{
  position: relative;
  width: 100%;
  padding: 0 300px;
}
.main-introduce-diagram-container>img{
  width: 100%;
}

.main-introduce-company-auto-slider{
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}
.main-introduce-slide-track{
  display: flex;
  width: max-content;
}
.main-introduce-slide-track.front{
  animation: scroll 10s linear infinite;
}
.main-introduce-slide-track.back{
  animation: scroll2 10s linear infinite;
}
.main-introduce-company-auto-slider-item{
  width: 200px;
  height: 80px;
  border-radius: 8px;
  background: #f7f7f7;
  margin-right: 50px;
  box-shadow: 0px 0px 14px 0px #00000025;
  overflow: hidden;
}
.main-introduce-company-auto-slider-item>img{
  width: 100%;
  height: 100%;
  user-select: none;
}
@keyframes scroll {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-100%);
  }
}
@keyframes scroll2 {
  0% {
      transform: translateX(0%);
  }
  100% {
      transform: translateX(-100%);
  }
}
.main-introduce-go-to-subscription-container{
  width: 80%;
  height: 200px;
  margin: 0 auto;
  border-radius: 8px;
  background: linear-gradient(90deg, #2FB4D9 0%, #1C3EFC 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}
.main-introduce-go-to-subscription-container>pre{
  margin: 0;
  font-size: 1.8rem;
  line-height: 4rem;
  font-weight: 700;
  color: #fff;
}

.main-introduce-go-to-subscription-button{
  width: fit-content;
  height: 50px;
  padding: 0 30px;
  background: #fff;
  border-radius: 8px;
  font-weight: 600;
  color: #1C3EFC;
  display: flex;align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .13s;
}
.main-introduce-go-to-subscription-button:active{
  opacity: 0.3;
}






/* ocpp */
.main-introduce-ocpp-flow-container{
  width: 100%;
  padding: 0 100px;
  position: relative;
}
.main-introduce-ocpp-flow-container>img{
  width: 100%;
}
.main-introduce-ocpp-info-wrapper{
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: -250px;
  transform: translateX(-50%);
  margin-bottom: 200px;
}
.main-introduce-ocpp-info-container{
  width: 530px;
  height: 150px;
  border-radius: 20px;
  box-shadow: 0px 0px 14px 0px #0000001A;
  margin-top: -10px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.main-introduce-ocpp-info-container:nth-child(2n){
  transform: translateX(calc(180px));
}
.main-introduce-ocpp-info-container:nth-child(2n-1){
  transform: translateX(calc(-180px));
}

.main-introduce-ocpp-info-container>img{
  width: 50px;
  margin-right: 20px;
}
.main-introduce-ocpp-info-container>div p{
  font-size: 1.2rem;
  font-weight: 600;
}
.main-introduce-ocpp-info-container>div p>color{
  display: inline;
  color: var(--emoovBlue);
}
.main-introduce-ocpp-info-container>div pre{
  font-size: .85rem;
  line-height: 1.5rem;
}


.main-introduce-gray-bg-wrapper{
  width: 100%;
  height: fit-content;
  background: #f9ffff;
  padding: 10px 0 100px;
  margin-bottom: -300px;
}
.main-introduce-ocpp-service-usage-container{
  width: 100%;
  padding: 0 50px;
}
.main-introduce-ocpp-service-usage-container>img{
  width: 100%;
}


/* Pricing */
.main-introduce-header.pricing{
  padding-bottom: 150px;
}
.main-introduce-pricing-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  padding: 0 80px;
  width: 100%;
  height: 750px;
  margin-top: -250px;
}

.main-introduce-pricing-item{
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #00000026;
  padding: 30px;
  display: grid;
  grid-template-rows: 1.2fr 1fr 3fr 60px;
}

.main-introduce-pricing-item-header{
  border-bottom: 1px solid #3A3A3A;
}
.main-introduce-pricing-item-header>p{
  margin: 0;
  font-size: 1.3rem;
  font-weight: 700;
  color: #0F91B6;

}
.main-introduce-pricing-item-header>pre{
  display: inline-block;
  line-height: 2rem;
  width: 100%;
}

.main-introduce-pricing-item-price-info>p.empty{
  margin: 0 auto;
  font-size: 1.5rem;
  color: var(--inputBorder);
}
.main-introduce-pricing-item-price-info{
  font-size: 2.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.main-introduce-pricing-item-price-info>p:nth-child(2){
  margin-top: 30px;
  margin-left: 10px;
  font-size: 1.5rem;
  font-weight: 500;
}
.main-introduce-pricing-item-option{
  display: flex;
  align-items: start;
  margin-bottom: 15px;
}
.main-introduce-pricing-item-option>img{
  content: var(--checkBlue);
  width: .8rem;
  margin-top: 5px;
  color: var(--emoovBlue);
}

.main-introduce-pricing-item-option>pre{
  font-size: .85rem;
  line-height: 1.5rem;
  position: relative;
  margin: 0;
  margin-left: 5px;
}
.main-introduce-pricing-item-option>pre>color{
  display: inline-block;
  color: #299FBF;
  font-size: .8rem;
  transform: translateY(-5px);
}
.main-introduce-pricing-item-button{
  width: 90%;
  margin: 0 auto;
  height: 100%;
  border-radius: 8px;
  display: flex;align-items: center;justify-content: center;
  border: 1px solid var(--emoovBlue);
  background: #fff;
  color: var(--emoovBlue);
  font-weight: 500;
  font-size: 1.2rem;
  cursor: pointer;
  position: relative;
  text-align: center;
}
.main-introduce-pricing-item-button:active{
  opacity: 0.3;
}
.main-introduce-pricing-item-button.plain{
  pointer-events: none;
}
.main-introduce-pricing-quotes-container{
  width: fit-content;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 120px;
}
.main-introduce-pricing-quotes-container>p{
  font-size: .8rem;
  color: var(--inputBorder);
}




.invitation-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 600px;
}

.invitation-wrapper title{
  display: block;
  background: var(--emoovBlue);
  width: 100%;
  height: 150px;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
.invitation-wrapper pre{
  width: 100%;
  margin-top: 100px;
  padding: 0 100px;
  line-height: 2.5rem;
  font-size: 1.2rem;
}
.invitation-logo{
  position: absolute;
  top: 0;
  width: 250px;
  height: 250px;
  transform: translateY(-50px);
}
.invitation-logo>img{
  width: 100%;
  object-fit: scale-down;
  z-index: 1;
}
.invitation-bg{
  display: flex;
  justify-content: center;
}
.invitation-bg>img{
  width: 100%;
  object-fit: scale-down;
  z-index: 1;
}